import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from '@agconnections/grow-ui';
import FormattedNumber from 'components/FormattedNumber';
import FieldIcon from 'screens/Property/components/FieldIcon';
import { mappingShape } from 'screens/Property/helpers/propertyDataHelpers';
import PropertiesModal from '../../../components/PropertiesModal';

const PropertyTableRow = ({ property }) => {
  const [propertiesModalOpen, setPropertiesModalOpen] = useState(false);

  const ft = property?.features?.[0]
    ? [mappingShape(property.features[0])]
    : undefined;
  return (
    <>
      <PropertiesModal
        open={propertiesModalOpen}
        onClose={() => {
          setPropertiesModalOpen(false);
        }}
        property={{
          cropZoneName: property?.cropZoneName,
          cropName: property?.cropName,
          fieldName: property?.fieldName,
          areaValue: property?.areaValue,
          areaUnit: property?.areaUnit,
          boundaryArea:
            Math.round((property?.boundaryArea + Number.EPSILON) * 100) / 100,
          reportedArea:
            Math.round((property?.reportedArea + Number.EPSILON) * 100) / 100,
          coveragePercent: Math.round(
            (property?.coveragePercent + Number.EPSILON) * 100
          )
        }}
        data-testid="properties-modal"
      />
      <Table.Row key={property?.cropZoneId}>
        <Table.Cell>
          <FieldIcon id="view-field-card-icon" features={ft} />
        </Table.Cell>
        <Table.Cell width="20rem">
          <div className="flex flex-col justify-start leading-5 text-left">
            <span className="font-bold text-neutral-1000">
              {property?.fieldName}
            </span>
            <span style={{ color: '#707374' }}>{property?.cropZoneName}</span>
          </div>
        </Table.Cell>
        <Table.Cell>{property?.cropName}</Table.Cell>
        <Table.Cell>
          <FormattedNumber roundedTo={2}>{property?.areaValue}</FormattedNumber>
          {' ac'}
        </Table.Cell>
        <Table.Cell width="20rem">
          <div className="mr-6">
            <FormattedNumber roundedTo={2}>
              {property?.coveragePercent * 100}
            </FormattedNumber>
          </div>
          <div
            data-testid="property-table-view-button"
            className="mr-8 absolute right-0 flex-none w-10"
          >
            <Button
              type="link-default"
              onClick={() => setPropertiesModalOpen(true)}
              data-html2canvas-ignore
            >
              View
            </Button>
          </div>
        </Table.Cell>
      </Table.Row>
    </>
  );
};

PropertyTableRow.propTypes = {
  property: PropTypes.shape({
    cropZoneId: PropTypes.string,
    cropZoneName: PropTypes.string,
    cropName: PropTypes.string,
    areaValue: PropTypes.number,
    areaUnit: PropTypes.string,
    fieldName: PropTypes.string,
    boundaryArea: PropTypes.number,
    coveragePercent: PropTypes.number,
    reportedArea: PropTypes.number,
    features: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
};

export default PropertyTableRow;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import PortalModal from 'components/Modals/PortalModal';
import ErrorOutline from 'components/Icons/ErrorOutline';
import { Button } from '@agconnections/grow-ui';

import { SubmissionSummaryContext } from '../../context/SubmissionSummaryProvider';

const RemoveTaskModal = ({ onRemove }) => {
  const {
    removeTaskFromCropzone,
    setOpenRemoveTaskModal,
    state: { openRemoveTaskModal }
  } = useContext(SubmissionSummaryContext);

  const handleClose = () => {
    setOpenRemoveTaskModal(false);
  };

  const handleRemoveTask = () => {
    setOpenRemoveTaskModal(false);
    removeTaskFromCropzone();
    onRemove();
  };

  return (
    <PortalModal
      open={openRemoveTaskModal}
      type="buttonless"
      style={{ paddingLeft: '2rem' }}
    >
      <div
        className="flex flex-line w-full mt-2 justify-between"
        data-testid="remove-task-modal"
      >
        <div className="flex mr-4">
          <ErrorOutline color="red-700" />
        </div>
        <div className="flex flex-col pr-10" style={{ flexGrow: 1 }}>
          <div className="flex flex-row justify-items-center pb-4">
            <h3 className="font-bold text-lg leading-6 text-gray-900 w-full">
              Are you sure you want to remove?
            </h3>
          </div>
          <div className="flex flex-col mb-4">
            This pesticide usage report will be removed from this submission.
            You can include it in a future submission.
          </div>
        </div>
      </div>
      <div className="pr-10">
        <div className="mt-5 flex flex-row items-center justify-between w-full">
          <div className="mr-5">
            <Button type="outline" id="cancel-button" onClick={handleClose}>
              Cancel
            </Button>
          </div>
          <div>
            <Button
              type="primary-danger"
              id="submit-button"
              onClick={handleRemoveTask}
            >
              Remove
            </Button>
          </div>
        </div>
      </div>
    </PortalModal>
  );
};

RemoveTaskModal.defaultProps = {
  onRemove: () => {}
};

RemoveTaskModal.propTypes = {
  onRemove: PropTypes.func
};

export default RemoveTaskModal;

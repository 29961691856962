import React from 'react';
import PropTypes from 'prop-types';
import { TextArea, Button } from '@agconnections/grow-ui';

import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { FieldArray, useFormikContext } from 'formik';
import { Applicators, TaskInfo } from './Sections';

const Details = ({
  people,
  peopleLoading,
  companies,
  companiesLoaded,
  memberRole,
  memberFinancialAccess
}) => {
  const { values, setFieldTouched } = useFormikContext();

  const classes = {
    sectionHeader: 'flex justify-between mb-6 ',
    sectionHeaderText: 'text-2xl font-semibold mb-6',
    sectionContent: 'grid items-end grid-cols-2 gap-24 mb-12 gap-y-12',
    sectionSplitContent: 'grid items-end grid-cols-2 gap-24 mb-12 gap-y-12'
  };

  const handleAddRow = (section, { ...helpers }, fields) => {
    helpers.push({
      ...fields
      // id: values[section].length
    });
  };
  const handleRemoveRow = (section, { ...helpers }, index) => {
    helpers.remove(index);
    if (values[section].length === 0) {
      handleAddRow(
        section,
        helpers,
        section === 'applicators'
          ? {
              id: '',
              company: ''
            }
          : { id: '' }
      );
    }
  };

  const getDisabled = () => {
    if (
      memberRole === 'Full control' &&
      (memberFinancialAccess === 'none' || memberFinancialAccess === 'READ')
    )
      return true;

    return false;
  };

  return (
    <div className="-mx-6">
      <div className={classes.sectionHeaderText} id="task-info">
        Task Info
      </div>
      <div
        className={classes.sectionSplitContent}
        data-testid="task-info-content"
      >
        <TaskInfo
          people={people}
          peopleLoading={peopleLoading}
          memberRole={memberRole}
          memberFinancialAccess={memberFinancialAccess}
        />
      </div>
      <FieldArray
        name="applicators"
        render={arrayHelpers => (
          <>
            <div className={classes.sectionHeader} id="applicators">
              <div className={classes.sectionHeaderText}>Applicator(s)</div>
              <div>
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    setFieldTouched('Add Applicator');
                    handleAddRow('applicators', arrayHelpers, {
                      id: '',
                      company: ''
                    });
                  }}
                  disabled={getDisabled()}
                >
                  Add Applicator
                </Button>
              </div>
            </div>
            <div
              className={classes.sectionContent}
              data-testid="applicators-content"
            >
              <Applicators
                people={people}
                peopleLoading={peopleLoading}
                companies={companies}
                companiesLoaded={companiesLoaded}
                handleRemove={index => {
                  setFieldTouched('Remove Applicator');
                  handleRemoveRow('applicators', arrayHelpers, index);
                }}
                memberRole={memberRole}
                memberFinancialAccess={memberFinancialAccess}
              />
            </div>
          </>
        )}
      />
      {/* // assignees */}
      {/* Hidding this section till we need it
      <FieldArray
        name="assigneeIds"
        render={arrayHelpers => (
          <>
            <div className={classes.sectionHeader} id="assigneeIds">
              <div className={classes.sectionHeaderText}>Assignee(s)</div>
              <div>
                <Button
                  type="primary"
                  ghost
                  onClick={() =>
                    handleAddRow('assigneeIds', arrayHelpers, {
                      id: ''
                    })
                  }
                >
                  Add Assignee
                </Button>
              </div>
            </div>

            <div
              className={classes.sectionContent}
              data-testid="assignees-content"
            >
              <Assignees
                people={people}
                peopleLoading={peopleLoading}
                handleRemove={index =>
                  handleRemoveRow('assigneeIds', arrayHelpers, index)
                }
              />
            </div>
          </>
        )}
              /> */}
      <div className={classes.sectionHeaderText} id="notes">
        Notes
      </div>

      <GrowUIFormField control={TextArea} name="notes" />
    </div>
  );
};

Details.defaultProps = {
  people: [],
  companies: [],
  memberRole: '',
  memberFinancialAccess: ''
};

Details.propTypes = {
  people: PropTypes.arrayOf(PropTypes.object),
  peopleLoading: PropTypes.bool.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object),
  companiesLoaded: PropTypes.bool.isRequired,
  memberRole: PropTypes.string,
  memberFinancialAccess: PropTypes.string
};

export default Details;

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withLDConsumer, useFlags } from 'launchdarkly-react-client-sdk';
import { Navbar, NavItem } from '@agconnections/grow-ui';
import { useLocation, NavLink } from 'react-router-dom';
import { startCase } from 'lodash';
import { Context } from 'components/Store';
import brandIcon from 'assets/cropwise-financials-icon.svg';
import brandText from 'assets/cropwise-financials-text.svg';
import brandFooter from 'assets/syngenta-digital.svg';
import { paths } from 'routes/paths';
import featureFlagRenderItem from 'helpers/featureFlagRenderItem';
import { SET_SIDEBAR_STATUS } from 'reducers/reducer';
import { AmplitudeContext } from '../../utilities/amplitude/useAmplitude';
import CollapsibleNavItem from './components/CollapsibleNavItem';

const Navigation = ({ routes }) => {
  const {
    feReportsModuleEnableModule,
    feYieldModuleEnableModule,
    beJohnDeereEnabledForAll,
    beJohnDeereEnabledPerOrg,
    feInventoryModuleEnableModule,
    beProductPriceListEnabled,
    beProductPriceListEnableByOrgId,
    feProductPriceList,
    feYieldV2ModuleEnableModule,
    beYieldEnableByOrgId
  } = useFlags();
  const location = useLocation();
  const amplitude = useContext(AmplitudeContext);
  const [leftNavFilterRoutes, setLeftNavFilterRoutes] = useState(routes);
  const [
    { organization, loggedInUserOrgPermission, loadingOrg },
    dispatch
  ] = useContext(Context);
  const [isOpened, setIsOpened] = useState(true);

  const isProductsNavVisible =
    feProductPriceList &&
    (beProductPriceListEnabled ||
      beProductPriceListEnableByOrgId?.includes(organization?.id));

  const isYieldNavVisible = featureFlagRenderItem(
    feYieldV2ModuleEnableModule,
    beYieldEnableByOrgId,
    organization?.id
  );

  useEffect(() => {
    setLeftNavFilterRoutes(
      routes.filter(route => {
        if (route.nav !== 'leftNav') {
          return false;
        }
        if (route.id === 'inventory') {
          return feInventoryModuleEnableModule;
        }
        if (route.id === 'products') {
          return isProductsNavVisible;
        }
        if (route.id === 'reports') {
          return feReportsModuleEnableModule;
        }
        if (route.id === 'yield' && route.key === 'yield') {
          return feYieldModuleEnableModule;
        }
        if (route.id === 'yield' && route.key === 'yieldV2') {
          return isYieldNavVisible;
        }
        return (
          loggedInUserOrgPermission?.role !== 'View Only' ||
          route.id !== 'organization'
        );
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    beJohnDeereEnabledForAll,
    beJohnDeereEnabledPerOrg,
    organization,
    feYieldModuleEnableModule,
    loggedInUserOrgPermission,
    feReportsModuleEnableModule,
    feInventoryModuleEnableModule,
    isProductsNavVisible,
    feYieldV2ModuleEnableModule,
    isOpened
  ]);

  const getStatus = () => !loadingOrg;

  const handleSidebarToggle = toggle => {
    dispatch({
      type: SET_SIDEBAR_STATUS,
      payload: !toggle
    });
  };

  return (
    <div data-testid="nav-lg ">
      <Navbar
        pathToBrandIcon={brandIcon}
        pathToBrandText={brandText}
        brandFooter={brandFooter}
        setIsOpened={state => setIsOpened(!state)}
        onClick={handleSidebarToggle}
      >
        {getStatus() &&
          leftNavFilterRoutes.map(item =>
            item?.subRoutes ? (
              <CollapsibleNavItem
                key={item.key}
                item={item}
                isOpened={isOpened}
              />
            ) : (
              <NavItem
                key={item.key}
                disabled={item.disabled}
                icon={item.icon}
                active={
                  location.pathname.includes(item.path) ||
                  (location.pathname.includes(paths.organizations) &&
                    item.id === 'organization')
                }
                as={
                  <NavLink
                    key={item.key}
                    onClick={() =>
                      amplitude.sendEventToAmplitude(
                        amplitude.filterNavItems(item.key)
                      )
                    }
                    to={
                      item.key === 'organizations'
                        ? `${paths.organizations}/${organization.id}`
                        : item.path
                    }
                    exact
                  />
                }
              >
                <>{startCase(item.id)}</>
              </NavItem>
            )
          )}
      </Navbar>
    </div>
  );
};

Navigation.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withLDConsumer()(Navigation);

/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import './task.css';

import { Draggable } from 'react-beautiful-dnd';
import { taskFormat } from 'helpers/dateFormat';

import FormattedNumber from 'components/FormattedNumber';
import { TaskContext } from 'screens/Tasks/components/TaskContext';
import {
  TagsCloudDisplay,
  extraTagsCalculation,
  TagsPopUpMessage
} from 'components/TagsButton';
import { STORAGE_KEYS } from 'utilities/localStorage';
import ListWithPopup from 'components/ListWithPopup';
import EllipseMenu from 'components/EllipseMenu';
import {
  TASKS_COMPLETE_TASKLIST_MENU,
  TASKS_READY_TASKLIST_MENU
} from 'utilities/menus';
import { paths } from 'routes/paths';

const DetailContent = ({ title, value, fullWidth, isCurrencyValue }) => {
  return (
    <div className={`${fullWidth ? 'w-11/12' : 'w-48'}`}>
      <div className="text-xs text-neutral-300">{title}</div>
      <div className="text-lg truncate text-neutral-300">
        {isCurrencyValue && value !== null ? (
          <FormattedNumber unit="usCurrency" roundedTo={2}>
            {String(value)}
          </FormattedNumber>
        ) : (
          value
        )}
      </div>
    </div>
  );
};

const InnerTaskContent = ({
  task,
  onContextMenuAction,
  flags,
  memberRole,
  memberFinancialAccess
}) => {
  const [displayTagsObject, setDisplayTagsObject] = useState({
    extraTagsCount: 0,
    extraTagsNames: [],
    tagsToDisplay: []
  });

  useEffect(() => {
    if (flags.length > 0) setDisplayTagsObject(extraTagsCalculation(flags, 30));
  }, [flags]);

  const { updatingTask, updatingWeather } = useContext(TaskContext);

  const getTotCostValue = cost => {
    if (memberRole === 'Full control' && memberFinancialAccess === 'none') {
      return '';
    }
    if (
      memberRole === 'Admin' ||
      memberRole === 'View Only' ||
      (memberRole === 'Full control' && memberFinancialAccess === 'WRITE') ||
      (memberRole === 'Full control' && memberFinancialAccess === 'READ')
    ) {
      return cost;
    }
    if (memberRole === 'Full control' && memberFinancialAccess === 'READ') {
      return '';
    }
    return '';
  };

  const showSpinner = () => {
    return (
      (updatingTask.updating && updatingTask.taskId === task?.id) ||
      (updatingWeather && updatingTask.taskId === task?.id)
    );
  };

  return (
    <div className="flex flex-col justify-center h-64 mx-2">
      <div className="flex justify-between">
        <div className="text-lg font-bold truncate text-syngenta-navy">
          <Link to={`${paths.tasks}/${task?.id}/view`}>{task?.name}</Link>
          {showSpinner() && (
            <div className="async-loader">
              <Spinner size="xs" />
            </div>
          )}
        </div>
        <div className="flex self-center">
          <EllipseMenu
            onAction={onContextMenuAction}
            options={
              task.status === 'ready'
                ? TASKS_READY_TASKLIST_MENU
                : TASKS_COMPLETE_TASKLIST_MENU
            }
          />
        </div>
      </div>

      <div className="text-sm text-neutral-300 mb-2">
        {taskFormat(task?.startDate)}
      </div>

      {displayTagsObject.extraTagsNames.length > 0 ? (
        <ListWithPopup
          position="bottom"
          ariaLabel="Task Tags"
          top="1.5rem"
          msg={
            <TagsPopUpMessage
              extraTagsNames={displayTagsObject.extraTagsNames}
            />
          }
          component={
            <TagsCloudDisplay
              displayTagsObject={displayTagsObject}
              isLandingPage
              viewKey={STORAGE_KEYS.TASKS_VIEW}
            />
          }
        />
      ) : (
        <TagsCloudDisplay
          displayTagsObject={displayTagsObject}
          isLandingPage
          viewKey={STORAGE_KEYS.TASKS_VIEW}
        />
      )}

      <div className="mt-3">
        <div className="flex justify-between w-64">
          <DetailContent title="Field Count" value={task?.fieldCount} />
          <DetailContent
            title="Total Acres"
            value={Math.round(task?.totalAreaValue * 100) / 100}
          />
        </div>

        <div className="flex justify-between w-64 mt-2">
          <DetailContent title="Product Count" value={task?.productCount} />
          {memberRole === 'View Only' && memberFinancialAccess === undefined ? (
            ''
          ) : (
            <DetailContent
              title="Total Task Cost"
              value={getTotCostValue(task?.totalTaskCost)}
              isCurrencyValue
            />
          )}
        </div>

        <div className="mt-2">
          <DetailContent
            title="Crop Season"
            value={task?.cropSeasons?.cropSeasons?.map(
              (cz, i) =>
                `${cz.name}${
                  i === task?.cropSeasons?.cropSeasons.length - 1 ? '' : ', '
                }`
            )}
            fullWidth
          />
        </div>
      </div>
    </div>
  );
};

const Task = ({ index, task, onAction, memberRole, memberFinancialAccess }) => {
  const handleContextMenuAction = action => onAction(action, task);

  // https://github.com/atlassian/react-beautiful-dnd/issues/374#issuecomment-569817782
  const getStyle = (style, snapshot) => {
    if (!snapshot.isDragging) return {};
    if (!snapshot.isDropAnimating) {
      return style;
    }

    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`
    };
  };

  return (
    <Draggable draggableId={task?.id} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }, snapshot) => {
        const conditionalClasses = snapshot.isDragging ? '' : ''; // If we want to customize transitional styling

        return (
          <div
            id={`task-${task?.id}`}
            className={`mx-3 my-3 px-3 py-4 border border-neutral-200 rounded bg-white ${conditionalClasses}`}
            {...draggableProps}
            {...dragHandleProps}
            ref={innerRef}
            style={getStyle(draggableProps.style, snapshot)}
          >
            <InnerTaskContent
              task={task}
              onContextMenuAction={handleContextMenuAction}
              flags={
                task?.flags?.flags !== undefined ||
                task?.flags?.flags?.length > 0
                  ? task?.flags?.flags
                  : []
              }
              memberRole={memberRole}
              memberFinancialAccess={memberFinancialAccess}
            />
          </div>
        );
      }}
    </Draggable>
  );
};

DetailContent.defaultProps = {
  value: '',
  fullWidth: false,
  isCurrencyValue: false
};

DetailContent.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  isCurrencyValue: PropTypes.bool
};

InnerTaskContent.propTypes = {
  task: PropTypes.object.isRequired,
  onContextMenuAction: PropTypes.func.isRequired,
  flags: PropTypes.arrayOf(PropTypes.object).isRequired,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired
};

Task.propTypes = {
  index: PropTypes.number.isRequired,
  task: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired
};

export default Task;

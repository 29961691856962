import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import DrawModeButtons from './components/DrawModeButtons';
import EditButtons from './components/EditButtons';
import ControlButtons from './components/ControlButtons';
import TopLeftControlButtons from './components/TopLeftControlButtons';

const DrawTools = ({
  mode,
  modeSelected,
  onDelete,
  onSubtract,
  onSplit,
  setZoom,
  overlay,
  setOverlay
}) => {
  const location = useLocation();

  const pathMatch = () =>
    location.pathname.includes('/property/create') ||
    location.pathname.includes('/property/edit');

  return (
    <div data-testid="draw-tools">
      {pathMatch() && (
        <>
          <div className="absolute z-1 top-0 left-0 m-3">
            <TopLeftControlButtons overlay={overlay} setOverlay={setOverlay} />
          </div>

          <div className="absolute z-1 bottom-0 top-0 right-0">
            <DrawModeButtons mode={mode} modeSelected={modeSelected} />
            <EditButtons
              onDelete={onDelete}
              onSubtract={onSubtract}
              onSplit={onSplit}
            />
          </div>
        </>
      )}

      <ControlButtons setZoom={setZoom} />
    </div>
  );
};

DrawTools.defaultProps = {
  setZoom: () => {},
  overlay: null
};

DrawTools.propTypes = {
  mode: PropTypes.string.isRequired,
  modeSelected: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubtract: PropTypes.func.isRequired,
  setZoom: PropTypes.func,
  onSplit: PropTypes.func.isRequired,
  overlay: PropTypes.string,
  setOverlay: PropTypes.func.isRequired
};

export default DrawTools;

import * as Yup from 'yup';

const validationSchemaSaleLocation = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().nullable(),
  addressLineOne: Yup.string().nullable(),
  city: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  company: Yup.string().nullable(),
  addressLineTwo: Yup.string().nullable(),
  state: Yup.string().nullable(),
  zipCode: Yup.string().nullable(),
  notes: Yup.string().nullable()
});

export default validationSchemaSaleLocation;

import React, { useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { feature as createFeature } from '@turf/turf';
import { Button } from '@agconnections/grow-ui';
import { v4 as uuidv4 } from 'uuid';

import PortalModal from 'components/Modals/PortalModal';
import FileInput from 'components/FileInput';
import { Context } from 'components/Store';
import { parseClientError } from 'helpers/errorHelpers';
import { processInputShapeFiles } from 'utilities/fileHelpers';
import PropertySelector from './PropertySelector';

import SquareButton from '../SquareButton';
import LandContext from '../../PropertyMapContext';
import { MAP_HISTORY_FEATURE_TYPES } from '../../../../helpers/constants';
import {
  getFeatureFarmName,
  getFeatureFieldName,
  getFeatureCropZoneName
} from '../../../../helpers/fieldDataHelpers';

const ImportIcon = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 0.625H3.75V5.125H0.75L6 10.375L11.25 5.125H8.25V0.625ZM6.74997 2.125V6.625H7.62747L5.99997 8.2525L4.37247 6.625H5.24997V2.125H6.74997ZM0.75 13.375V11.875H11.25V13.375H0.75Z"
        fill="#696F88"
      />
    </svg>
  );
};

const ImportTrigger = ({ onClick }) => {
  return (
    <SquareButton
      buttonClassName="h-6 px-2 text-xs font-medium"
      onClick={onClick}
    >
      <div className="px-1" style={{ margin: '0.125rem' }}>
        Import
      </div>

      <ImportIcon />
    </SquareButton>
  );
};

ImportTrigger.propTypes = {
  onClick: PropTypes.func.isRequired
};

// eslint-disable-next-line react/prop-types
const UploadPage = ({ onSelect }) => (
  <div className="h-64 mb-24">
    <FileInput
      title="Click or drag file to this area to upload"
      subtitle="Upload a .zip that contains .shp, .shx, and .dbf"
      fileTypes={['.zip']}
      onSelect={onSelect}
    />
  </div>
);

const CurrentPage = ({
  page,
  geoJson,
  fileName,
  onFileSelect,
  onClose,
  onPropertySelect,
  onBack
}) => (
  <>
    {
      [
        <UploadPage onSelect={onFileSelect} />,
        <PropertySelector
          geoJson={geoJson}
          fileName={fileName}
          onClose={onClose}
          onSelect={onPropertySelect}
          onCancel={onBack}
        />
      ][page]
    }
  </>
);

CurrentPage.propTypes = {
  page: PropTypes.number.isRequired,
  geoJson: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  fileName: PropTypes.string,
  onFileSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onPropertySelect: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

CurrentPage.defaultProps = {
  fileName: ''
};

const ImportModal = () => {
  const [geoJson, setGeoJson] = useState();
  const [page, setPage] = useState(0);
  const [selectedFeatures, setSelectedFeatures] = useState();
  const [, dispatch] = useContext(Context);
  const [isOpen, setIsOpen] = useState(false);
  const { addNewMapFeatures } = useContext(LandContext);

  const handleFileSelect = (files, event) => {
    event.preventDefault();
    if (files && files.length > 0) {
      processInputShapeFiles(files)
        .then(data => {
          setGeoJson(data[0]);
          setPage(page + 1);
        })
        .catch(() => {
          parseClientError(dispatch)('The file you selected is invalid.');
        });
    }
  };

  const handleClear = () => {
    setGeoJson(null);
  };

  const onTriggerClick = () => {
    handleClear();
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
    setPage(0);
  };

  const handlePropertySelect = useCallback(selectedItems => {
    setSelectedFeatures(selectedItems);
  }, []);

  const handleImport = () => {
    handleModalClose();
    addNewMapFeatures(
      MAP_HISTORY_FEATURE_TYPES.IMPORT,
      selectedFeatures.map(importFeature => {
        const feature = createFeature(importFeature.geometry, {
          farmName: getFeatureFarmName(importFeature),
          fieldName: getFeatureFieldName(importFeature),
          cropZoneName: getFeatureCropZoneName(importFeature)
        });
        feature.id = feature.id || uuidv4();
        return {
          feature
        };
      })
    );
  };

  const handleBack = () => setPage(0);

  const fileName = Array.isArray(geoJson)
    ? geoJson[0].fileName
    : geoJson?.fileName;

  return (
    <>
      <ImportTrigger onClick={onTriggerClick} />
      <PortalModal
        title="Import and Select a Boundary"
        open={isOpen}
        type="buttonless"
        close={handleModalClose}
      >
        <div className="mr-12">
          <CurrentPage
            onClose={handleModalClose}
            onFileSelect={handleFileSelect}
            onPropertySelect={handlePropertySelect}
            geoJson={geoJson}
            fileName={fileName}
            page={page}
            onBack={handleBack}
          />
          <div className="flex justify-end">
            <div className="flex justify-between">
              <div className="px-1">
                <Button
                  id="import-property-cancel-button"
                  type="outline"
                  onClick={handleModalClose}
                >
                  Cancel
                </Button>
              </div>
              <div className="px-1">
                <Button
                  id="import-property-button"
                  type="primary"
                  disabled={!selectedFeatures?.length}
                  onClick={handleImport}
                >
                  Import
                </Button>
              </div>
            </div>
          </div>
        </div>
      </PortalModal>
    </>
  );
};

export default ImportModal;

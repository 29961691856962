import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Icon, Popup } from '@agconnections/grow-ui';
import { generate } from 'shortid';

const DropDownButton = ({ title, onPress, options }) => {
  const [expanded, setExpanded] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    if (expanded) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [expanded]);

  const Trigger = (
    <button
      data-testid="dropdown-button"
      type="button"
      className={
        `h-full border-l border-neutral-30 px-3 py-2 flex items-center ` +
        `justify-center focus:outline-none`
      }
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (!expanded) {
          setExpanded(true);
        }
      }}
    >
      <Icon size={14} icon="chevron-down" color="#696F88" />
    </button>
  );

  return (
    <button
      type="button"
      className="bg-white rounded-md border outline-none border-neutral-30 items-center flex flex-row"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onPress();
        setExpanded(false);
      }}
    >
      <div className="relative py-1 rounded-l-md w-full h-full flex items-center justify-center bg-white hover:bg-neutral-20">
        <span className="px-3 text-sm">{title}</span>
      </div>

      <div
        className={`relative rounded-r-md w-full h-full flex items-center justify-center ${
          expanded ? 'bg-neutral-20' : 'bg-white hover:bg-neutral-20'
        }`}
      >
        <Popup
          trigger={Trigger}
          position="top"
          align="right"
          open={expanded}
          onClose={() => setExpanded(false)}
        >
          <div
            ref={dropdownRef}
            className="absolute right-0 bottom-0 py-2 border rounded-md bg-white shadow flex justify-center items-center"
          >
            {options.map(option => (
              <button
                type="button"
                key={generate()}
                className="px-1 w-28 items-center justify-center rounded-md cursor-pointer"
                onClick={e => {
                  e.stopPropagation();
                  option.onClick();
                  setExpanded(false);
                }}
              >
                <p
                  className={`text-base hover:opacity-80 w-full line-clamp-1 ${option.danger &&
                    'text-remove'}`}
                >
                  {option.label}
                </p>
              </button>
            ))}
          </div>
        </Popup>
      </div>
    </button>
  );
};

DropDownButton.propTypes = {
  title: PropTypes.string,
  onPress: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.objectOf({
      label: PropTypes.string,
      danger: PropTypes.bool,
      onClick: PropTypes.func
    })
  )
};

DropDownButton.defaultProps = {
  title: '',
  onPress: () => {},
  options: []
};

export default DropDownButton;

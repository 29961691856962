import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapContext } from 'react-mapbox-gl';

import { UPDATE_TYPES, KEY_CODES } from '../../../helpers/constants';

const MapKeyPressTool = ({ onChange }) => {
  const map = useContext(MapContext);

  useEffect(() => {
    const keyHandlerMap = {
      [KEY_CODES.BACKSPACE]: event => onChange(event, UPDATE_TYPES.DELETE),
      [KEY_CODES.DELETE]: event => onChange(event, UPDATE_TYPES.DELETE)
    };

    const keyDownListener = event => {
      const handler = keyHandlerMap[event.which];
      if (handler) {
        handler(event);
      }
    };

    if (map && map.getCanvas()) {
      map.getCanvas().addEventListener('keydown', keyDownListener);
    }

    return () => {
      if (map && map.getCanvas()) {
        map.getCanvas().removeEventListener('keydown', keyDownListener);
      }
    };
  }, [map, onChange]);

  return null;
};

MapKeyPressTool.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default MapKeyPressTool;

import { useState, useEffect, useContext } from 'react';

import { Context } from 'components/Store';
import usePropertyData from 'hooks/usePropertyData';

import propertyMapping from '../../helpers/propertyMapping';

const usePropertyMapping = () => {
  const [{ organization }] = useContext(Context);
  const { fields, loading } = usePropertyData();
  const [landItems, setLandItems] = useState([]);

  useEffect(() => {
    if (!loading) {
      setLandItems(propertyMapping(fields, organization));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, organization]);

  return landItems;
};

export default usePropertyMapping;

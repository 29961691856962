import React from 'react';
import { Marker } from 'react-mapbox-gl';
import shortId from 'shortid';
import PropTypes from 'prop-types';

const getCentroidFromArrLatsLongs = data => {
  if (!(data?.length > 0)) {
    return false;
  }
  const numCoords = data.length;
  let X = 0.0;
  let Y = 0.0;
  /* eslint no-unused-expressions: "off", curly: "error" */
  data?.forEach(item => {
    const lat = item[0];
    const lon = item[1];
    X += lat;
    Y += lon;
  });
  X /= numCoords;
  Y /= numCoords;
  const resultOutput = [];
  if (!isNaN(X) && !isNaN(Y)) {
    resultOutput.push(X, Y);
    return resultOutput;
  }
  return 'NONE';
};

const isOdd = value => {
  if (value % 2 === 0) {
    return false;
  }
  return true;
};

const getArrayOfLabels = geoCollection => {
  const fieldPoints = [];
  const cropZonePoints = [];
  const features = geoCollection?.features;
  features.forEach(item => {
    const coordinateArr = item?.geometry?.coordinates[0];
    if (item?.properties?.crop === undefined) {
      const fieldCoords = getCentroidFromArrLatsLongs(coordinateArr[0]);
      if (fieldCoords !== 'NONE') {
        fieldPoints.push(
          fieldCoords,
          item?.properties?.name || 'Untitled-Field'
        );
      }
    } else {
      const cropCoords = getCentroidFromArrLatsLongs(coordinateArr[0]);
      if (cropCoords !== 'NONE') {
        cropZonePoints.push(
          cropCoords,
          item?.properties?.name || 'Untitled-Cropzone'
        );
      }
    }
  });
  const results = { fieldPoints, cropZonePoints };
  return results;
};

const ObjectMarkerLabel = ({
  propertyLandingPageData,
  geoCollection,
  activeObjectType
}) => {
  const labelsArr = getArrayOfLabels(geoCollection);
  let markerPoints = [];
  if (activeObjectType === 'field' || activeObjectType === 'cropzone') {
    markerPoints = labelsArr.cropZonePoints;
  } else if (activeObjectType === 'farm') {
    markerPoints = labelsArr.fieldPoints;
  } else {
    // markerPoints = getFarmPoints(); // In future if needed to show the farm labels for All Properties
    markerPoints = labelsArr.fieldPoints;
  }

  if (propertyLandingPageData?.length > 0) {
    if (markerPoints?.[0]?.length === 2) {
      return markerPoints.map((item, index) => {
        if (!isOdd(index)) {
          return (
            <Marker
              key={shortId.generate()}
              coordinates={markerPoints[index]}
              anchor="bottom"
              style={{
                pointerEvents: 'none'
              }}
            >
              <div className="text-white font-bold text-lg">
                {markerPoints[index + 1]}
              </div>
            </Marker>
          );
        }
        return null;
      });
    }
  } else {
    return markerPoints.map((item, index) => {
      if (!isOdd(index)) {
        return (
          <Marker
            key={shortId.generate()}
            coordinates={markerPoints[index]}
            anchor="bottom"
            style={{
              pointerEvents: 'none'
            }}
          >
            <div className="text-white font-bold text-lg">
              {markerPoints[index + 1]}
            </div>
          </Marker>
        );
      }
      return null;
    });
  }
  return null;
};

ObjectMarkerLabel.propTypes = {
  propertyLandingPageData: PropTypes.arrayOf(PropTypes.object),
  geoCollection: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.object)
  }),
  activeObjectType: PropTypes.string
};
ObjectMarkerLabel.defaultProps = {
  propertyLandingPageData: [],
  geoCollection: [],
  activeObjectType: ''
};

export default ObjectMarkerLabel;

import React from 'react';
import PropTypes from 'prop-types';

import ToggleButton from 'components/ToggleButton';

import SquareButton from '../../../../SquareButton';

const CLUButton = ({ value, onToggle }) => {
  return (
    <SquareButton
      buttonClassName="h-6 px-2 text-xs font-medium"
      onClick={() => {}}
    >
      <div className="mr-2">CLU</div>
      <ToggleButton
        id="map-clu-toggle"
        defaultChecked={value}
        onChange={onToggle}
        color="#0071CD"
      />
    </SquareButton>
  );
};

CLUButton.propTypes = {
  value: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

export default CLUButton;

/* eslint-disable react/prop-types */
import React, { useEffect, useContext, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import useFarmData from 'screens/Property/hooks/useFarmData';
import useFieldData from 'screens/Property/hooks/useFieldData';
import useCropZoneData from 'screens/Property/hooks/useCropZoneData';
import { Spinner } from '@agconnections/grow-ui';
import * as Yup from 'yup';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import PropertyDetailsToast from 'screens/Property/components/PropertyDetailsToast';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropertyFarmDetail from './PropertyFarmDetail';
import PropertyFieldDetail from './PropertyFieldDetail';
import PropertyCropZoneDetail from './PropertyCropZoneDetail';

import NewEditFarm from '../NewEditFarm';
import NewEditField from '../NewEditField';
import NewEditCropZone from '../NewEditCropZone';
import CardWrapper from '../../../../components/CardWrapper';
import PropertyCropZoneProducts from './PropertyCropZoneProducts';
import PropertyCropHistory from './PropertyCropHistory';
import PropertyAppliedProducts from './PropertyAppliedProducts';
import PropertyYield from './PropertyYield';

const getFieldBody = values => {
  if (values.id === undefined) return {};
  const body = {
    ...values,
    geometry: values?.geometry ?? {},
    reportedArea: values?.reportedArea ?? 0,
    slope: values?.slope ?? 0,
    slopeLength: values?.slopeLength ?? 0
  };
  delete body.dateEpoch;
  return body;
};

const getCropZoneBody = values => {
  if (values.id === undefined) return {};
  const body = {
    ...values,
    cropSeasonId: JSON.parse(localStorage.getItem('selectedCropSeasons'))[0],
    geometry: values?.geometry ?? {},
    reportedArea: values?.reportedArea ?? 0
  };
  delete body.dateEpoch;
  return body;
};

const schemaFarm = Yup.object().shape({});
const schemaCropZone = Yup.object().shape({
  fsaArea: Yup.number()
    .min(0, 'FSA Area must be greater or equal to 0')
    .typeError('A positive number is required'),
  pumpLift: Yup.number()
    .min(0, 'Pump Lift must be greater or equal to 0')
    .typeError('A positive number is required')
});
const schemaField = Yup.object().shape({
  fsaArea: Yup.number()
    .min(0, 'FSA Area must be greater or equal to 0')
    .typeError('A positive number is required'),
  ownerPercentage: Yup.number()
    .min(0, 'Land Owner Percentage must be greater or equal to 0')
    .typeError('A positive number is required'),
  tenantPercentage: Yup.number()
    .min(0, 'Tenant Percentage must be greater or equal to 0')
    .typeError('A positive number is required'),
  slope: Yup.number()
    .typeError('A number is required')
    .min(0, 'Slope Percentage must be greater or equal to 0')
    .max(100, 'Slope Percentage must be less or equal to 100'),
  slopeLength: Yup.number()
    .typeError('A number is required')
    .min(0, 'Slope Length must be greater or equal to 0'),
  pumpPressure: Yup.number()
    .min(0, 'Pump Pressure must be greater or equal to 0')
    .typeError('A positive number is required'),
  pumpLift: Yup.number()
    .min(0, 'Pump Lift must be greater or equal to 0')
    .typeError('A positive number is required')
});

const PropertyTable = ({
  enableEdit,
  setEnableEdit,
  edited,
  setEdited,
  setCancelEditModalVisible
}) => {
  const [{ selectedProperty, organization }, dispatch] = useContext(Context);
  const [toastInfo, setToastInfo] = useState({
    editSessionNumber: null,
    id: null
  });

  const {
    releaseFePropertyDetailsYieldCard,
    releaseFePropertyDetailsYieldCardEnableByOrg
  } = useFlags();

  const farmId =
    selectedProperty.type === 'Farm'
      ? selectedProperty.id
      : selectedProperty.farmId;
  const fieldId =
    selectedProperty.type === 'Field'
      ? selectedProperty.id
      : selectedProperty.fieldId;
  const cropZoneId =
    selectedProperty.type === 'Crop Zone' ? selectedProperty.id : undefined;
  const farmData = useFarmData(farmId);
  const fieldData = useFieldData(fieldId);
  const cropZoneData = useCropZoneData(cropZoneId);
  const propertyData = {
    Farm: farmData,
    Field: fieldData,
    'Crop Zone': cropZoneData
  }[selectedProperty.type];
  const schema = {
    Farm: schemaFarm,
    Field: schemaField,
    'Crop Zone': schemaCropZone
  }[selectedProperty.type];

  const [showEditToast, setShowEditToast] = useState(false);
  const [editSessionNumber, setEditSessionNumber] = useState(0);

  useEffect(() => {
    dispatch({
      type: 'IS_ENABLE_INFO_TAB_EDIT',
      payload: enableEdit
    });
  }, [enableEdit, dispatch]);

  const submit = async values => {
    if (selectedProperty.type === 'Farm') {
      const farmValues = { ...values };
      delete farmValues.dateEpoch;
      try {
        await propertyData.editFarm(farmValues);
        // eslint-disable-next-line
      } catch (_ex) {}
      setToastInfo({ editSessionNumber, id: selectedProperty.id });
      setShowEditToast(true);
    }
    if (selectedProperty.type === 'Field') {
      const valuesToSave = getFieldBody(values);
      try {
        await propertyData.editField(valuesToSave);
        // eslint-disable-next-line
      } catch (_ex) {}
      setToastInfo({ editSessionNumber, id: selectedProperty.id });
      setShowEditToast(true);
    }
    if (selectedProperty.type === 'Crop Zone') {
      const valuesToSave = getCropZoneBody(values);
      try {
        await propertyData.editCropZone(valuesToSave);
        // eslint-disable-next-line
      } catch (_ex) {}
      setToastInfo({ editSessionNumber, id: selectedProperty.id });
      setShowEditToast(true);
    }
    setEnableEdit(prev => !prev);
  };

  const handleEnableEdit = x => {
    setEnableEdit(x);
    setEditSessionNumber(editSessionNumber + 1);
  };

  useEffect(() => {
    if (toastInfo.id && toastInfo.id !== selectedProperty.id) {
      setShowEditToast(false);
    }
  }, [toastInfo, selectedProperty, setShowEditToast]);

  const footer = (handleSubmit, values) => {
    return enableEdit ? (
      <div className="bottom-0 left-0 flex flex-row w-full p-4 bg-white border border-solid border-neutral-100 justify-between">
        <Button
          type={ButtonType.outline}
          onClick={() => {
            if (edited) {
              setCancelEditModalVisible(prev => !prev);
            } else {
              setEnableEdit(false);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!edited}
          type={ButtonType.primary}
          onClick={() => {
            handleSubmit(values, selectedProperty.type);
          }}
        >
          {propertyData.saving ? <Spinner size="xs" /> : 'Save'}
        </Button>
      </div>
    ) : null;
  };

  const details = () => {
    const isYieldCardEnabled =
      releaseFePropertyDetailsYieldCard ||
      releaseFePropertyDetailsYieldCardEnableByOrg?.includes(organization.id);

    switch (selectedProperty?.type) {
      case 'Farm':
        return !enableEdit ? (
          <CardWrapper>
            <PropertyFarmDetail
              id={selectedProperty.id}
              setEnableEdit={handleEnableEdit}
            />
          </CardWrapper>
        ) : (
          <CardWrapper>
            <NewEditFarm setEdited={setEdited} farm={propertyData.farm} />
          </CardWrapper>
        );

      case 'Field':
        return !enableEdit ? (
          <>
            <CardWrapper>
              <PropertyFieldDetail
                id={selectedProperty.id}
                setEnableEdit={handleEnableEdit}
              />
            </CardWrapper>
            <CardWrapper>
              <PropertyAppliedProducts id={selectedProperty.id} />
            </CardWrapper>
            {isYieldCardEnabled && (
              <CardWrapper>
                <PropertyYield selectedProperty={selectedProperty} />
              </CardWrapper>
            )}
            <CardWrapper>
              <PropertyCropHistory id={selectedProperty.id} level="field" />
            </CardWrapper>
          </>
        ) : (
          <CardWrapper>
            <NewEditField
              setEdited={setEdited}
              field={fieldData.field}
              farm={farmData.farm}
            />
          </CardWrapper>
        );
      case 'Crop Zone':
        return !enableEdit ? (
          <>
            <CardWrapper>
              <PropertyCropZoneDetail
                id={selectedProperty.id}
                farmId={selectedProperty.farmId}
                fieldId={selectedProperty.fieldId}
                setEnableEdit={handleEnableEdit}
              />
            </CardWrapper>
            <CardWrapper>
              <PropertyCropZoneProducts id={selectedProperty.id} />
            </CardWrapper>
            {isYieldCardEnabled && (
              <CardWrapper>
                <PropertyYield selectedProperty={selectedProperty} />
              </CardWrapper>
            )}
          </>
        ) : (
          <CardWrapper>
            <NewEditCropZone
              setEdited={setEdited}
              field={fieldData.field}
              farm={farmData.farm}
              cropZone={cropZoneData.cropZone}
            />
          </CardWrapper>
        );
      default:
        return null;
    }
  };

  const isToastVisible =
    toastInfo.editSessionNumber === editSessionNumber &&
    toastInfo.id === selectedProperty.id;

  return (
    <div
      className="w-full h-full flex flex-col flex-1 overflow-y-auto"
      data-testid="property-table"
    >
      <Formik
        validationSchema={schema}
        key={`edit-${editSessionNumber}-${selectedProperty.id}`}
        onSubmit={submit}
        initialValues={{}}
      >
        {({ handleSubmit, values }) => {
          return (
            <>
              <div
                className={`${
                  enableEdit ? 'bg-neutral-100' : 'bg-white'
                } w-full flex-1 flex flex-col gap-y-4 overflow-y-auto p-2 bg-neutral-100`}
              >
                {details()}
              </div>
              {footer(handleSubmit, values)}
            </>
          );
        }}
      </Formik>
      {isToastVisible && (
        <PropertyDetailsToast
          state={propertyData?.state}
          selectedProperty={selectedProperty}
          showEditToast={showEditToast}
          setShowEditToast={setShowEditToast}
        />
      )}
    </div>
  );
};
PropertyTable.defaultProps = {};

PropertyTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  enableEdit: PropTypes.object.isRequired,
  setEnableEdit: PropTypes.func.isRequired,
  edited: PropTypes.bool.isRequired,
  setEdited: PropTypes.func.isRequired,
  setCancelEditModalVisible: PropTypes.func.isRequired
};
export default PropertyTable;

import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Spinner } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import useProduct from 'hooks/useProduct';
import {
  SET_SHOW_TOAST_REMOVE_PRODUCT,
  SET_TOAST_MESSAGE,
  SET_TOAST_TYPE
} from 'reducers/reducer';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import Toast from 'components/Toast';
import EditProductDrawer from 'screens/ProductList/components/EditProductDrawer';
import useInventoryProduct from 'screens/ProductList/hooks/useInventoryProduct';
import ProductDetailInfo from './components/ProductDetailInfo';
import { emptyValue } from '../helpers/tableConstants';
import ProductDetailTabs from './components/ProductDetailTabs';
import {
  buildCustomProductPayload,
  buildMasterlistProductPayload
} from '../helpers/formValuesToPayload';

const ProductDetails = () => {
  const { id: productId } = useParams();
  const { getProduct, product } = useProduct();

  const {
    addUpdateListPrice,
    updateCustomProduct,
    removeProduct,
    setRemoveError
  } = useInventoryProduct();
  const [{ removeProductFailure }, dispatch] = useContext(Context);

  const [toastMessage, setToastMessage] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState(null);
  const [productToEdit, setProductToEdit] = useState(null);
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [editProductHeader, setEditProductHeader] = useState('');
  const [productToRemove, setProductToRemove] = useState(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const history = useHistory();

  const refreshProducts = () => {
    getProduct(productId);
  };

  const handleOnEdit = prod => {
    setProductToEdit(prod);
    setShowEditDrawer(true);
    if (product.custom) {
      setEditProductHeader('Edit Custom Product');
    } else {
      setEditProductHeader('Edit Product');
    }
  };

  const handleRemove = async () => {
    try {
      await removeProduct(productToRemove);
      refreshProducts();
    } finally {
      if (!removeProductFailure) {
        dispatch({
          type: SET_TOAST_MESSAGE,
          payload: 'Product removed'
        });
        dispatch({
          type: SET_TOAST_TYPE,
          payload: 'success'
        });
        dispatch({
          type: SET_SHOW_TOAST_REMOVE_PRODUCT,
          payload: true
        });
      } else {
        dispatch({
          type: SET_TOAST_MESSAGE,
          payload: 'Unable to remove product.'
        });
        dispatch({
          type: SET_TOAST_TYPE,
          payload: 'error'
        });
        dispatch({
          type: SET_SHOW_TOAST_REMOVE_PRODUCT,
          payload: true
        });
      }
      history.goBack();
    }
  };

  const handleEdit = async values => {
    let payload;
    let apiCall;
    if (values.custom) {
      payload = buildCustomProductPayload(values);
      apiCall = updateCustomProduct;
    } else {
      payload = buildMasterlistProductPayload(values);
      apiCall = addUpdateListPrice;
    }
    setShowEditDrawer(false);

    apiCall(payload)
      .then(() => {
        setToastType('success');
        setToastMessage('Changes saved.');
      })
      .catch(() => {
        setToastType('error');
        setToastMessage('Unable to save changes');
      })
      .finally(() => {
        setShowToast(true);
        refreshProducts();
      });
  };

  useEffect(() => {
    getProduct(productId);
  }, [getProduct, productId]);

  return (
    <div className="h-full w-full flex flex-col" data-testid="product-view">
      <Breadcrumb>
        <Breadcrumb.Item
          title="Products"
          value={product?.productName ?? emptyValue.dashLine}
          isLast
        />
      </Breadcrumb>
      {!product ? (
        <Spinner />
      ) : (
        <>
          <ProductDetailInfo
            setShowRemoveModal={setShowRemoveModal}
            setProductToRemove={setProductToRemove}
            productToRemove={productToRemove}
            showRemoveModal={showRemoveModal}
            handleRemove={handleRemove}
            refreshProducts={refreshProducts}
            setShowToast={setShowToast}
            setToastMessage={setToastMessage}
            setToastType={setToastType}
            product={product}
            onEdit={handleOnEdit}
          />
          <ProductDetailTabs product={product} />
        </>
      )}
      <EditProductDrawer
        handleEdit={handleEdit}
        product={productToEdit}
        visible={showEditDrawer}
        onClose={() => setShowEditDrawer(false)}
        editProductHeader={editProductHeader}
      />
      <Toast
        type={toastType}
        isBold
        open={showToast}
        title={toastMessage}
        timeout={5000}
        onClose={() => {
          setShowToast(false);
          setRemoveError(null);
        }}
      />
    </div>
  );
};
export default ProductDetails;

import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { Menu, Popup } from '@agconnections/grow-ui';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import { AddRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import EditOutlined from 'assets/edit_outlined.svg';
import { CropZoneIcon, FarmIcon, FieldsIcon } from 'components/Icons';
import { Context } from 'components/Store';
import { IS_MASS_ASSIGNER_ACTIVE, SELECT_PROPERTY } from 'reducers/reducer';
import { paths } from 'routes/paths';

const Actions = ({ setOpenFarmModal, openCreateModal, setView }) => {
  const history = useHistory();
  const [{ selectedProperty, selectedFieldRowId }, dispatch] = useContext(
    Context
  );

  const getFieldId = () => {
    if (selectedProperty?.type === 'Field') return selectedProperty.id;
    return selectedFieldRowId.id;
  };

  const handleMassAssigner = () => {
    if (setView) {
      setView('map');
    }
    dispatch({
      type: SELECT_PROPERTY,
      payload: {}
    });
    dispatch({
      type: IS_MASS_ASSIGNER_ACTIVE,
      payload: true
    });
  };

  return (
    <div
      className="flex flex-wrap justify-between"
      data-testid="properties-actions"
    >
      <Button
        type={ButtonType.outline}
        style={{ width: '149px', padding: '0 33px' }}
        onClick={handleMassAssigner}
        icon={<img width={18} src={EditOutlined} alt="edit" />}
      >
        Bulk Edit
      </Button>

      <Popup
        containerClasses="popup-width"
        position="top"
        trigger={
          <Button icon={<AddRounded />} type={ButtonType.success} ghost>
            New Property
          </Button>
        }
      >
        <Menu>
          <Menu.Item onClick={() => setOpenFarmModal(true)} selectable>
            <FarmIcon />
            <span className="ml-2">Farm</span>
          </Menu.Item>
          <Menu.Item onClick={() => openCreateModal(true)} selectable>
            <FieldsIcon />
            <span className="ml-2">Field</span>
          </Menu.Item>
          {(selectedProperty?.type === 'Field' ||
            selectedProperty?.type === 'Crop Zone') && (
            <Menu.Item
              onClick={() => {
                history.push(
                  `${paths.properties}/create/${getFieldId()}/cropzone`
                );
              }}
              selectable
            >
              <CropZoneIcon />
              <span className="ml-2">Crop Zone</span>
            </Menu.Item>
          )}
        </Menu>
      </Popup>
    </div>
  );
};

Actions.propTypes = {
  openCreateModal: PropTypes.func.isRequired,
  setOpenFarmModal: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired
};

export default Actions;

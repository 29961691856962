import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroup } from '@agconnections/grow-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import featureFlagRenderItem from 'helpers/featureFlagRenderItem';
import MapIcon from 'components/Icons/MapIcon';
import CostIcon from 'components/Icons/CostIcon';
import ListIcon from 'components/Icons/ListIcon';
import ClickableDiv from 'components/ClickableDiv';
import { Context } from 'components/Store';
import { PROPERTY_VIEW_MODE } from '../../helpers/constants';
import './index.css';

const PropertyToolbar = ({
  showPropertyDetails,
  view,
  onChangeView,
  loading,
  buttonText
}) => {
  const amplitude = useContext(AmplitudeContext);
  const [{ organization, selectedProperty }] = useContext(Context);
  const [hoveredButton, setHoveredButton] = useState(null);

  const {
    releaseCostAnalysisViewEnable,
    releaseCostAnalysisViewEnableByOrg
  } = useFlags();

  const costAnalysisViewEnabled = featureFlagRenderItem(
    releaseCostAnalysisViewEnable,
    releaseCostAnalysisViewEnableByOrg,
    organization.id
  );

  const shouldDisplayCostAnalysis =
    costAnalysisViewEnabled && selectedProperty?.type !== 'Farm';

  const getIconColor = mode => {
    if (view === mode) return 'white';
    if (hoveredButton === mode) return '#0092E4';
    return '#696F88';
  };

  const handleChange = mode => {
    if (mode === PROPERTY_VIEW_MODE.COST) {
      const selectedCropSeasons =
        JSON.parse(localStorage.getItem('selectedCropSeasons')) || [];
      amplitude.sendEventToAmplitude(
        amplitude.events.epic.Properties.viewCostAnalysis,
        {
          cropSeasonsCount: selectedCropSeasons.length
        }
      );
    }
    onChangeView(mode);
  };

  const renderRadioButton = ({ name, value, icon: IconComponent, label }) => (
    <RadioButtonGroup.RadioButton
      key={value}
      name={name}
      value={value}
      disabled={loading}
    >
      <ClickableDiv
        className="absolute"
        onMouseEnter={() => setHoveredButton(value)}
        onMouseLeave={() => setHoveredButton(null)}
      >
        <div className="flex justify-center">
          <IconComponent color={getIconColor(value)} />
          <span className="ml-1">{label}</span>
        </div>
      </ClickableDiv>
    </RadioButtonGroup.RadioButton>
  );

  if (!showPropertyDetails) {
    return (
      <div
        className="text-white w-full p-2 rounded"
        style={{ backgroundColor: '#0071CD' }}
      >
        <div className="flex h-auto justify-center">
          <MapIcon color="white" />
          <span className="ml-1">Map</span>
        </div>
      </div>
    );
  }

  const buttons = [
    {
      name: 'mapView',
      value: PROPERTY_VIEW_MODE.MAP,
      icon: MapIcon,
      label: 'Map'
    },
    {
      name: 'infoView',
      value: PROPERTY_VIEW_MODE.INFO,
      icon: ListIcon,
      label: buttonText
    }
  ];

  if (shouldDisplayCostAnalysis) {
    buttons.push({
      name: 'costAnalysisView',
      value: PROPERTY_VIEW_MODE.COST,
      icon: CostIcon,
      label: 'Cost Analysis'
    });
  }

  return (
    <RadioButtonGroup
      selected={view}
      onChange={handleChange}
      className="flex w-full property-view-mode-button-group"
    >
      {buttons.map(renderRadioButton)}
    </RadioButtonGroup>
  );
};

PropertyToolbar.propTypes = {
  showPropertyDetails: PropTypes.bool,
  view: PropTypes.string.isRequired,
  onChangeView: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  buttonText: PropTypes.string
};

PropertyToolbar.defaultProps = {
  buttonText: 'Details',
  showPropertyDetails: true
};

export default PropertyToolbar;

import React from 'react';
import PropTypes from 'prop-types';

import ToggleButton from 'components/ToggleButton';

import SquareButton from '../../../../SquareButton';

const SatelliteButton = ({ value, onToggle }) => (
  <SquareButton
    onClick={() => {}}
    buttonClassName="h-6 px-2 text-xs font-medium"
  >
    <div className="mr-2">SAT</div>
    <ToggleButton
      id="map-satellite-toggle"
      name="map-satellite-toggle"
      defaultChecked={value}
      onChange={onToggle}
      color="#0071CD"
    />
  </SquareButton>
);

SatelliteButton.propTypes = {
  value: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

export default SatelliteButton;

// Reduces the precision of coordinates to a specified number of decimal places
// Handles nested arrays recursively for complex geometries like polygons or linestrings
const reducePrecision = (coordinates, decimals = 4) => {
  // If the input is a simple coordinate pair (base case for Points)
  if (!Array.isArray(coordinates[0])) {
    return [
      parseFloat(coordinates[0].toFixed(decimals)),
      parseFloat(coordinates[1].toFixed(decimals))
    ];
  }

  // If the input is more complex (like Polygons), handle recursively
  return coordinates.map(coord => {
    // Check if 'coord' is a pair or another array
    if (typeof coord[0] === 'number') {
      return [
        parseFloat(coord[0].toFixed(decimals)),
        parseFloat(coord[1].toFixed(decimals))
      ];
    }
    return reducePrecision(coord, decimals);
  });
};

export default reducePrecision;

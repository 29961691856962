import React, { useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MapContext, GeoJSONLayer } from 'react-mapbox-gl';
import { bbox } from '@turf/turf';
import { featureCollection } from '@turf/helpers';
import { intersection } from 'lodash';
import { useLocation } from 'react-router-dom';
import { generate } from 'shortid';
import PropertyMapContext from '../../PropertyMapContext';

const DEFAULT_LAYER_TYPE = 'default';
const SELECTED_LAYER_TYPE = 'selected';
const FOCUSED_LAYER_TYPE = 'focused';

// return the updated bounding box if it has changed, otherwise return null
const getUpdatedBoundingBox = (boundingBox, fc, focusedFlag) => {
  if (!focusedFlag && boundingBox) {
    return null;
  }
  const newBounds = bbox(fc);
  const intersect = intersection(boundingBox, newBounds);

  return intersect.length === newBounds.length ? null : newBounds;
};

const buildCustomLayers = (geoJSONArr, stylesArr, onClickShape) => {
  return geoJSONArr.map((geoJSON, idx) => {
    const { fillColor, fillOutlineColor, fillAntialias } = stylesArr[idx] || {};
    const fc = featureCollection(geoJSON);
    return (
      <GeoJSONLayer
        key={generate()}
        data={fc}
        fillOnClick={onClickShape}
        fillPaint={{
          'fill-color': fillColor || 'rgba(255, 255, 255, 0.15)',
          'fill-outline-color': fillOutlineColor || 'rgba(255, 255, 255, 1)',
          'fill-antialias': fillAntialias || true
        }}
      />
    );
  });
};

// eslint-disable-next-line react/prop-types
const DefaultLayer = ({ geoJSON, onClickShape }) => (
  <GeoJSONLayer
    fillOnClick={onClickShape}
    data={geoJSON}
    fillPaint={{
      'fill-color': 'rgba(255, 255, 255, 0.5)',
      'fill-outline-color': 'rgba(255, 255, 32, 1)',
      'fill-antialias': true
    }}
  />
);
// eslint-disable-next-line react/prop-types
const SelectedLayer = ({ geoJSON, onClickShape }) => (
  <GeoJSONLayer
    fillOnClick={onClickShape}
    data={geoJSON}
    fillPaint={{
      'fill-color': 'rgba(115, 219, 120, 0.3)',
      'fill-outline-color': 'rgba(255, 255, 255, 1)',
      'fill-antialias': true
    }}
  />
);

const PropertyFeatureLayer = ({ geoJSON, customStyles, visible, setZoom }) => {
  const { pathname } = useLocation();
  const [boundingBox, setBoundingBox] = useState();
  const [mapIsReady, setMapIsReady] = useState(false);
  const [boxIsMoving, setBoxIsMoving] = useState(false);

  const selectedJSON = useMemo(
    () =>
      featureCollection(
        geoJSON?.features?.filter(
          ({ properties }) => properties.$layer === SELECTED_LAYER_TYPE
        ) || []
      ),
    [geoJSON]
  );
  const defaultJSON = useMemo(
    () =>
      featureCollection(
        geoJSON?.features?.filter(
          ({ properties }) =>
            (properties.$layer ?? DEFAULT_LAYER_TYPE) === DEFAULT_LAYER_TYPE
        ) || []
      ),
    [geoJSON]
  );

  const customJSONArr = useMemo(
    () =>
      Object.keys(customStyles || {}).map(key =>
        (geoJSON.features || []).filter(
          ({ properties }) => properties.$layer === key
        )
      ),
    [geoJSON, customStyles]
  );
  const map = useContext(MapContext);

  if (!mapIsReady && map && !map.isMoving() && map.isStyleLoaded()) {
    setMapIsReady(true);
  }

  // adjust the bounding box so that the current zoom level can be determined
  useEffect(() => {
    // focused features are similar to selected but the map also focuses in on them
    const focusedGeoJSONFeatures = geoJSON?.features.filter(
      feature => feature?.properties?.$layer === FOCUSED_LAYER_TYPE
    );

    const fc = focusedGeoJSONFeatures.length
      ? featureCollection(focusedGeoJSONFeatures)
      : geoJSON;

    const newBounds = getUpdatedBoundingBox(
      boundingBox,
      fc,
      focusedGeoJSONFeatures.length
    );

    if (fc && newBounds && mapIsReady && !boxIsMoving) {
      if (newBounds.find(bound => bound === Infinity || bound === -Infinity)) {
        return;
      }
      setTimeout(() => {
        map.fitBounds(newBounds, { padding: 160 });
        setBoundingBox(newBounds);
      }, 1);
      setBoxIsMoving(true);
      // map.fitBounds(newBounds, { padding: 160 });
    }
  }, [boundingBox, geoJSON, map, setZoom, boxIsMoving, mapIsReady]);

  // wait for the bounding box movement to stop then set zoom to the map's current zoom value
  useEffect(() => {
    let mounted = true;
    const moveChecker = () => {
      if (mounted) {
        setZoom(() => {
          const newZoom = map.getZoom();
          return [newZoom];
        });
        setBoxIsMoving(false);
      }
    };
    if (pathname === '/app/property') {
      if (map) {
        map.on('moveend', moveChecker);
      }
    }
    if (pathname !== '/app/property') {
      if (map && boxIsMoving) {
        map.on('moveend', moveChecker);
      }
    }

    return () => {
      mounted = false;
      if (map) {
        map.off('moveend', moveChecker);
      }
    };
  }, [boxIsMoving, map, setZoom, pathname]);

  return geoJSON && visible ? (
    <PropertyMapContext.Consumer>
      {({ onClickShape }) => (
        <>
          <DefaultLayer onClickShape={onClickShape} geoJSON={defaultJSON} />
          <SelectedLayer onClickShape={onClickShape} geoJSON={selectedJSON} />
          {customStyles &&
            buildCustomLayers(
              customJSONArr,
              Object.values(customStyles),
              onClickShape
            )}
        </>
      )}
    </PropertyMapContext.Consumer>
  ) : null;
};

PropertyFeatureLayer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  geoJSON: PropTypes.object,
  customStyles: PropTypes.objectOf(
    PropTypes.shape({
      fillColor: PropTypes.string,
      fillOutlineColor: PropTypes.string,
      fillAntialias: PropTypes.bool
    })
  ),
  visible: PropTypes.bool,
  setZoom: PropTypes.func
};

PropertyFeatureLayer.defaultProps = {
  geoJSON: null,
  customStyles: null,
  visible: false,
  setZoom: () => {}
};

export default PropertyFeatureLayer;

/* eslint-disable import/prefer-default-export */

const UNITS_LIST = {
  'acre inch': 'ac in',
  acre: 'ac',
  bushel: 'bu',
  'british ton': 'brit. ton',
  centimeter: 'cm',
  centiliter: 'cL',
  cubic: 'cu',
  'cubic centimeter': 'cu cm',
  'cubic foot': 'cu ft',
  'cubic inch': 'cu in',
  'cubic meter': 'cu m',
  'cubic yard': 'cu yd',
  'cwt (hundredweight)': 'cwt',
  day: 'd',
  dozen: 'doz',
  'fluid ounce': 'fl oz',
  foot: 'ft',
  gallon: 'gal',
  grain: 'gr',
  gram: 'g',
  gross: 'gro',
  hectare: 'ha',
  hour: 'h',
  inch: 'in',
  kilogram: 'kg',
  kiloliter: 'kl',
  kilometer: 'km',
  liter: 'L',
  'long ton': 'Brit. ton',
  meter: 'm',
  'metric ton': 'm ton',
  micrometer: 'μm',
  mile: 'mi',
  'miles per hour': 'mph',
  milligram: 'mg',
  milliliter: 'mL',
  millimeter: 'mm',
  ounce: 'dry oz',
  pint: 'pt',
  pound: 'lbs',
  lb: 'lbs',
  quart: 'qt',
  second: 's',
  square: 'sq',
  'square kilometer': 'sq km',
  'square yard': 'sq yd',
  'short ton': 'tn',
  ton: 'ton',
  yard: 'yd',
  year: 'y'
};

export const getAbbreviatedUnit = unit =>
  UNITS_LIST[unit?.toLowerCase()] ?? unit ?? '';

export const MEASUREMENT_UNITS = {
  // This is an enum used in the backend,
  // so the values should not be changed without updating the backend

  // volume
  GALLON: 'gallon',
  FLUID_OUNCE: 'fl oz',
  QUART: 'quart',
  LITER: 'liter',
  ML: 'ml',
  BUSHEL: 'bushel',
  PINT: 'pint',
  ACRE_INCH: 'acre-inch',
  CENTILITER: 'centiliter',

  // mass
  SHORT_TON: 'short ton',
  TON: 'ton',
  METRIC_TON: 'metric ton',
  POUND: 'lb',
  OUNCE: 'ounce',
  KILOGRAM: 'kg',
  MILLIGRAM: 'mg',
  GRAM: 'g',

  // area
  SQUARE_METER: 'square meter',
  ACRE: 'acre',
  HECTARE: 'hectare',

  // length
  INCH: 'inch',

  // time
  HOUR: 'hour',
  DAY: 'day',
  MINUTE: 'minute',

  // other
  BAG: 'bag',
  SEED: 'seed',
  CWT: 'cwt',
  UNIT: 'unit',
  PACKAGE: 'package',
  EACH: 'each',
  KERNEL: 'kernel'
};

const conversionFactors = {
  lb: 1, // base unit, using pound as the base for simplicity
  ounce: 1 / 16, // dry ounce to pound
  g: 1 / 453.592, // gram to pound
  cwt: 100, // hundredweight to pound
  kg: 1 / 0.453592, // kilogram to pound
  'metric ton': 2204.62, // metric ton to pound
  ton: 2000 // short ton (US) to pound
};

// Converts a value from one unit to another
export const convertUnit = (value, fromUnit, toUnit) => {
  // Convert the value to the base unit first (pounds)
  const valueInPounds = value * conversionFactors[fromUnit];

  // Convert from pounds to the target unit
  const convertedValue = valueInPounds / conversionFactors[toUnit];

  return convertedValue;
};

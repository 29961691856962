import React from 'react';

import SimplotLogo from 'assets/integrations/SimplotLogo.svg';
import SubmissionHistory from 'screens/Integrations/SubmissionHistory';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import Provider from 'screens/Integrations/SubmissionHistory/context/Provider';
import LandingPage from 'screens/Integrations/components/LandingPage';
import Instructions from 'screens/Integrations/components/Instructions';

const SimplotLandingPage = () => {
  return (
    <>
      <LandingPage
        headerText={`${INTEGRATIONS.simplot} Processor Integration`}
        breadcrumbText={INTEGRATIONS.simplot}
        ownerText={INTEGRATIONS.simplot}
        targetPath={INTEGRATIONS.simplot}
        LogoImg={SimplotLogo}
      />
      <div className="w-full border-t-1 mb-5" />
      <Provider integrationType={INTEGRATIONS.simplot}>
        <SubmissionHistory />
      </Provider>
      <Instructions />
    </>
  );
};

export default SimplotLandingPage;

const formatPhoneNumber = phoneNumber => {
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  const formattedNumber = digitsOnly.replace(
    /^(\d{3})(\d{3})(\d{4})$/,
    '($1)-$2-$3'
  );

  return formattedNumber;
};

export default formatPhoneNumber;

import reducePrecision from './reducePrecision';

// Extracts the first coordinate as a substitute geometry for invalid or unsimplifiable features
const createSubstituteGeometry = feature => {
  // Extract the first coordinate from the feature's original geometry
  const baseCoordinate = feature.geometry.coordinates[0][0];

  // Define a small offset to create a square around the base coordinate
  const offset = 0.0001;

  // Create a square polygon around the base coordinate
  const coordinates = [
    [
      [baseCoordinate[0] - offset, baseCoordinate[1] - offset], // Bottom left
      [baseCoordinate[0] - offset, baseCoordinate[1] + offset], // Top left
      [baseCoordinate[0] + offset, baseCoordinate[1] + offset], // Top right
      [baseCoordinate[0] + offset, baseCoordinate[1] - offset], // Bottom right
      [baseCoordinate[0] - offset, baseCoordinate[1] - offset] // Closing the loop to bottom left
    ]
  ];

  return {
    type: 'Feature',
    properties: feature.properties,
    geometry: {
      type: 'Polygon',
      // Apply precision reduction to the new polygon
      coordinates: coordinates.map(coord => reducePrecision(coord))
    }
  };
};

export default createSubstituteGeometry;

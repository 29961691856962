import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, Popup } from '@agconnections/grow-ui';
import { MASSIVE_CHANGE_SUMMARY } from 'reducers/reducer';
import { getCropZonesOnly } from 'screens/Property/PropertiesLanding/helpers/massAssigner';
import TagsModal from 'components/Modals/TagsModal';

const MassAssignerActions = ({
  fieldsToMassiveAssign,
  dispatch,
  setOpenAddCropZonesModal
}) => {
  const [updatedTags] = useState([]);
  const [openTags, setOpenTags] = useState(false);

  const handleAction = (action, extraData = {}) => {
    dispatch({
      type: MASSIVE_CHANGE_SUMMARY,
      payload: {
        action,
        ...extraData
      }
    });
  };

  const hasCropZones = fieldsToMassiveAssign.some(fd => fd.cropzones.length);

  const handleAddTags = flags => {
    dispatch({
      type: MASSIVE_CHANGE_SUMMARY,
      payload: {
        action: 'TagsAdded',
        flags,
        fieldsToMassiveAssign: getCropZonesOnly(fieldsToMassiveAssign)
      }
    });
  };

  const handleDeleteTags = flags => {
    dispatch({
      type: MASSIVE_CHANGE_SUMMARY,
      payload: {
        action: 'TagsDeleted',
        flags,
        fieldsToMassiveAssign: getCropZonesOnly(fieldsToMassiveAssign)
      }
    });
  };

  return (
    <div className="sticky bottom-0 w-full h-20 bg-white border-t-2 py-3 px-5 flex justify-between items-center z-4">
      <TagsModal
        open={openTags}
        close={() => {
          setOpenTags(false);
        }}
        name="Tags"
        selectedTags={updatedTags?.reduce((tagIds, tag) => {
          return `${tagIds},${tag.id}`;
        }, '')}
        returnSelectedTags={handleAddTags}
      />
      <div className="w-1/2 pr-2">
        <Popup
          position="top"
          containerClasses="popup-width-mass-assigner"
          trigger={
            <Button type="outline" size="lg" disabled style={{ width: '100%' }}>
              Edit Tags
            </Button>
          }
        >
          <Menu>
            <Menu.Item
              onClick={() => {
                setOpenTags(true);
              }}
              selectable={hasCropZones}
            >
              <span
                className={`${
                  hasCropZones ? 'hover:font-semibold' : 'pt-1'
                } w-full h-full flex items-center`}
              >
                Add Tags
              </span>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                handleDeleteTags(updatedTags);
              }}
              selectable={hasCropZones}
            >
              <span className="hover:font-semibold w-full h-full flex items-center">
                Delete Tags
              </span>
            </Menu.Item>
          </Menu>
        </Popup>
      </div>
      <div className="w-1/2 pl-2">
        <Popup
          position="top"
          containerClasses="popup-width-mass-assigner"
          trigger={
            <Button
              type="primary"
              ghost
              size="lg"
              style={{ width: '100%' }}
              disabled={!fieldsToMassiveAssign.length}
            >
              Edit Crop Zones
            </Button>
          }
        >
          <Menu>
            <Menu.Item
              onClick={() => {
                handleAction('Added');
                setOpenAddCropZonesModal(true);
              }}
              selectable
            >
              <span className="ml-2 hover:font-semibold w-full h-full flex items-center">
                Add Crop Zones
              </span>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                handleAction('Changed');
                setOpenAddCropZonesModal(true);
              }}
              selectable={hasCropZones}
              disabled={!hasCropZones}
            >
              <span
                className={`ml-2 ${
                  hasCropZones ? 'hover:font-semibold' : 'pt-1'
                } w-full h-full flex items-center`}
              >
                Change Crop Zones
              </span>
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                handleAction('Deleted', {
                  fieldsToMassiveAssign: getCropZonesOnly(fieldsToMassiveAssign)
                })
              }
              selectable={hasCropZones}
              disabled={!hasCropZones}
            >
              <span
                className={`ml-2 ${
                  hasCropZones ? 'hover:font-semibold' : 'pt-1'
                } w-full h-full flex items-center`}
              >
                Delete Crop Zones
              </span>
            </Menu.Item>
          </Menu>
        </Popup>
      </div>
    </div>
  );
};

MassAssignerActions.defaultProps = {
  fieldsToMassiveAssign: []
};

MassAssignerActions.propTypes = {
  fieldsToMassiveAssign: PropTypes.arrayOf(PropTypes.object),
  setOpenAddCropZonesModal: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default MassAssignerActions;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Table, Button, SimpleModal } from '@agconnections/grow-ui';
import FieldIcon from 'screens/Property/components/FieldIcon';
import { mappingShape } from 'screens/Property/helpers/propertyDataHelpers';
import FormattedNumber from 'components/FormattedNumber';
import PropertiesModalBody from '../../../../../components/PropertiesModalBody';

const PropertyTableRow = ({ property, allCropZones }) => {
  const [propertiesModalOpen, setPropertiesModalOpen] = useState(false);

  const boundaryArea =
    Math.round((property?.boundaryArea + Number.EPSILON) * 100) / 100;

  const reportedArea =
    Math.round((property?.reportedArea + Number.EPSILON) * 100) / 100;

  const calculateCoveragePercent = (prop, allCZ) => {
    if (prop && allCZ?.length > 0) {
      const getIndividualCropZone = allCZ?.find(
        item => item?.id === prop?.cropZoneId
      );
      let covPercent = 0;
      if (getIndividualCropZone?.reportedArea === 0) {
        covPercent = Math.round(
          (prop?.areaValue / getIndividualCropZone?.boundaryArea +
            Number.EPSILON) *
            100
        );
      } else {
        covPercent = Math.round(
          (prop?.areaValue / getIndividualCropZone?.reportedArea +
            Number.EPSILON) *
            100
        );
      }

      return covPercent;
    }
    return false;
  };

  const originalAcres =
    property?.areaValue || property?.reportedArea > 0
      ? reportedArea
      : boundaryArea;

  const ft = property?.features?.[0]
    ? [mappingShape(property.features[0])]
    : undefined;

  return (
    <>
      <SimpleModal
        confirmLabel="Ok"
        open={propertiesModalOpen}
        onConfirm={() => {
          setPropertiesModalOpen(false);
        }}
        close={() => {
          setPropertiesModalOpen(false);
        }}
      >
        <Formik>
          <PropertiesModalBody
            readonly
            property={{
              areaType: property?.areaType,
              areaValue: property?.areaValue,
              boundaryArea,
              coveragePct:
                calculateCoveragePercent(property, allCropZones) || '',
              crop: { name: property?.cropName },
              fieldName: property?.fieldName,
              originalAcres,
              reportedArea
            }}
          />
        </Formik>
      </SimpleModal>
      <Table.Row>
        <Table.Cell width="4rem">
          <FieldIcon id="view-rec-card-icon" features={ft} />
        </Table.Cell>
        <Table.Cell>
          <div className="flex flex-col justify-start leading-5 text-left">
            <span className="font-bold text-neutral-1000">
              {property?.fieldName}
            </span>
            <span style={{ color: '#707374' }}>{property?.cropZoneName}</span>
          </div>
        </Table.Cell>
        <Table.Cell>{property?.cropName}</Table.Cell>
        <Table.Cell>
          <FormattedNumber roundedTo="2">{property?.areaValue}</FormattedNumber>{' '}
          {property?.areaUnit}
        </Table.Cell>
        <Table.Cell>
          <div className="mr-6">
            <FormattedNumber roundedTo={2} className="mr-6">
              {calculateCoveragePercent(property, allCropZones) || ''}
            </FormattedNumber>
            {' %'}
          </div>
        </Table.Cell>
        <Table.Cell>
          <div
            data-testid="product-table-view-button"
            className="mr-8 absolute right-0 flex-none w-10"
            data-html2canvas-ignore
          >
            <Button
              type="link-default"
              onClick={() => setPropertiesModalOpen(true)}
            >
              View
            </Button>
          </div>
        </Table.Cell>
      </Table.Row>
    </>
  );
};

PropertyTableRow.propTypes = {
  property: PropTypes.shape({
    areaType: PropTypes.string,
    cropZoneId: PropTypes.string,
    cropZoneName: PropTypes.string,
    cropName: PropTypes.string,
    areaValue: PropTypes.number,
    areaUnit: PropTypes.string,
    fieldName: PropTypes.string,
    boundaryArea: PropTypes.number,
    coveragePercent: PropTypes.number,
    reportedArea: PropTypes.number,
    features: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  allCropZones: PropTypes.arrayOf(PropTypes.object)
};

PropertyTableRow.defaultProps = {
  allCropZones: []
};

export default PropertyTableRow;

import { useContext, useState } from 'react';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import axios from 'axios';
import convertFiltersToParams from '../helpers/convertFiltersToParams';

const useReport = () => {
  const [, dispatch] = useContext(Context);
  const [exportJob, setExportJob] = useState({});
  const [reportToExport, setReportToExport] = useState();
  const [accessToken, setAccessToken] = useState('');

  const pollExportJob = () => {
    if (!exportJob.id) return;

    const exportJobUrl = `https://api.powerbi.com/v1.0/myorg/groups/${reportToExport.reportConfig.groupId}/reports/${reportToExport.reportConfig.reportId}/exports/${exportJob.id}`;
    axios
      .get(exportJobUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then(res => {
        setExportJob(res.data);
      })
      .catch(err => {
        parseServerError(dispatch)(err);
      });
  };

  const exportReportToPDF = (report, filters) => {
    setReportToExport(report);
    const exportUrl = `https://api.powerbi.com/v1.0/myorg/groups/${report.reportConfig.groupId}/reports/${report.reportConfig.reportId}/ExportTo`;
    const parameterValues = convertFiltersToParams(filters);
    const body = {
      format: 'PDF',
      paginatedReportConfiguration: {
        parameterValues,
        formatSettings: {
          AccessiblePDF: 'true',
          PageHeight: '11in',
          PageWidth: '8.5in'
        }
      }
    };

    axios
      .post(exportUrl, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then(res => {
        setExportJob(res.data);
      })
      .catch(err => {
        parseServerError(dispatch)(err);
      });
  };

  const getExportedReport = resourceLocation => {
    axios
      .get(resourceLocation, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        responseType: 'blob'
      })
      .then(response => {
        setExportJob({});
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  };

  return {
    exportReportToPDF,
    pollExportJob,
    getExportedReport,
    exportJob,
    setExportJob,
    accessToken,
    setAccessToken
  };
};

export default useReport;

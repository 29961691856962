import React from 'react';

import SquareButton from '../../../../SquareButton';

const UndoIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.23674 2.23341C3.20341 1.26675 4.53008 0.666748 6.00341 0.666748C8.95008 0.666748 11.3301 3.05341 11.3301 6.00008C11.3301 8.94675 8.95008 11.3334 6.00341 11.3334C3.51674 11.3334 1.44341 9.63341 0.850077 7.33341H2.23674C2.78341 8.88675 4.26341 10.0001 6.00341 10.0001C8.21008 10.0001 10.0034 8.20675 10.0034 6.00008C10.0034 3.79341 8.21008 2.00008 6.00341 2.00008C4.89674 2.00008 3.91008 2.46008 3.19008 3.18675L5.33674 5.33341H0.670077V0.666748L2.23674 2.23341Z"
        fill="#696F88"
      />
    </svg>
  );
};

const RedoIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.76326 2.23341C8.79659 1.26675 7.46992 0.666748 5.99659 0.666748C3.04992 0.666748 0.669922 3.05341 0.669922 6.00008C0.669922 8.94675 3.04992 11.3334 5.99659 11.3334C8.48326 11.3334 10.5566 9.63341 11.1499 7.33341H9.76326C9.21659 8.88675 7.73659 10.0001 5.99659 10.0001C3.78992 10.0001 1.99659 8.20675 1.99659 6.00008C1.99659 3.79341 3.78992 2.00008 5.99659 2.00008C7.10326 2.00008 8.08992 2.46008 8.80992 3.18675L6.66326 5.33341H11.3299V0.666748L9.76326 2.23341Z"
        fill="#696F88"
      />
    </svg>
  );
};

const HistoryButtons = () => {
  return (
    <div className="flex">
      <SquareButton onClick={() => {}}>
        <UndoIcon />
      </SquareButton>

      <SquareButton onClick={() => {}} className="ml-1">
        <RedoIcon />
      </SquareButton>
    </div>
  );
};

export default HistoryButtons;

import React, { useContext, useState, useEffect } from 'react';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Input, Select, DatePicker, Label } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import { tags as tagsApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import useCompanies from 'hooks/useCompanies';
import TagsModal from 'components/Modals/TagsModal';
import TagsButton, {
  extraTagsCalculation,
  TagsPopUpMessage
} from 'components/TagsButton';
import { STORAGE_KEYS } from 'utilities/localStorage';
import ListWithPopup from 'components/ListWithPopup';
import PropertyDetailContext from '../../../../../../Property/components/PropertyDetailPageWrapper/components/PropertyDetailContext';

const InvoiceInfoSection = () => {
  const { companies } = useCompanies();
  const { enableEditing } = useContext(PropertyDetailContext);
  const { values, setFieldValue } = useFormikContext();
  const [orgTags, setOrgTags] = useState(null);
  const [invoiceTagsObj, setInvoiceTagsObj] = useState([]);
  const [openTags, setOpenTags] = useState(false);

  const [displayTagsObject, setDisplayTagsObject] = useState({
    extraTagsCount: 0,
    extraTagsNames: [],
    tagsToDisplay: []
  });

  const companiesList = companies?.map(company => {
    return { value: company.name, key: company.id };
  });

  const tagsReturn = tags => {
    const tagIds = tags?.map(tag => tag.id);

    setFieldValue(
      'flagIds',
      tagIds.length > 0
        ? tagIds?.reduce((Ids, id) => {
            return `${Ids},${id}`;
          })
        : []
    );
    setInvoiceTagsObj(tags);
    setDisplayTagsObject(extraTagsCalculation(tags, 55));
  };

  const buildTags = (flagIds, tags) => {
    const tagObjs =
      flagIds?.length === 0
        ? ''
        : flagIds?.split(',').map(id => {
            const currTag = tags.filter(curr => curr.id === id);
            return {
              name: currTag[0]?.name,
              id,
              color: currTag[0].color
            };
          });

    if (tagObjs?.length > 0) {
      setInvoiceTagsObj(tagObjs);
      setDisplayTagsObject(extraTagsCalculation(tagObjs, 55));
    }
  };

  const getTags = async () => {
    const { promise } = tagsApi.fetch();
    await promise
      .then(response => {
        const tags = response.data.results;
        setOrgTags(tags);
        buildTags(values?.flagIds ? values?.flagIds : [], tags);
      })
      .catch(catchCancel)
      .catch();
  };

  const getFlagValues = flagIds => {
    return flagIds?.length === 0
      ? ''
      : flagIds?.split(',').reduce((ids, id) => {
          return `${ids},${id}`;
        });
  };

  useEffect(() => {
    if (!orgTags) {
      getTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgTags]);

  return (
    <>
      <TagsModal
        open={openTags}
        close={() => setOpenTags(false)}
        name={values?.invoiceNumber}
        selectedTags={getFlagValues(values?.flagIds)}
        returnSelectedTags={tagsReturn}
      />
      <GrowUIFormField
        label="Invoice Number *"
        control={Input}
        name="invoiceNumber"
        data-testid="invoiceNumber"
      />
      <GrowUIFormField
        label="Company"
        control={Select}
        name="vendor"
        items={companiesList}
        data-testid="company"
      />
      <GrowUIFormField
        control={DatePicker}
        data-testid="invoiceDate-test"
        label="Invoice Date *"
        name="invoiceDate"
        dateFormat="M d, Y"
      />
      <GrowUIFormField
        control={DatePicker}
        data-testid="invoiceDueDate-test"
        label="Invoice Due Date"
        name="invoiceDueDate"
        dateFormat="M d, Y"
      />
      <div>
        <Label absolute>Tags</Label>
        {displayTagsObject.extraTagsNames.length > 0 ? (
          <ListWithPopup
            position="botton"
            ariaLabel="Invoice Tags"
            top="1.5rem"
            msg={
              <TagsPopUpMessage
                extraTagsNames={displayTagsObject.extraTagsNames}
              />
            }
            component={
              <TagsButton
                setOpenTags={() => setOpenTags(true)}
                tags={invoiceTagsObj}
                displayTagsObject={displayTagsObject}
                viewKey={STORAGE_KEYS.INVOICES_VIEW}
                disabled={!enableEditing}
              />
            }
          />
        ) : (
          <TagsButton
            setOpenTags={() => setOpenTags(true)}
            tags={invoiceTagsObj}
            displayTagsObject={displayTagsObject}
            viewKey={STORAGE_KEYS.INVOICES_VIEW}
            disabled={!enableEditing}
          />
        )}
      </div>
    </>
  );
};

export default InvoiceInfoSection;

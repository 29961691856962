import { useContext, useEffect, useState } from 'react';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { recommendation as recommendationApi } from 'utilities/api';

function useRecommendationData(id) {
  const [, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [recommendation, setRecommendation] = useState(undefined);

  useEffect(() => {
    setLoading(true);

    const { promise, cancel } = recommendationApi.fetch(id);
    promise
      .then(response => {
        setRecommendation(response.data);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => cancel();
  }, [dispatch, id]);

  return {
    loading,
    recommendation,
    setRecommendation
  };
}

export default useRecommendationData;

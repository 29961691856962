import React, { useContext, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import {
  ArrowDropDown,
  ArrowDropUp,
  VisibilityOutlined,
  VisibilityOffOutlined
} from '@material-ui/icons';
import { Button, Select, Spinner, Tag } from '@agconnections/grow-ui';
import FieldMapView from 'components/FieldMapView';
import { fieldToFeature } from 'screens/Property/components/FieldImporterModal/fieldImporterHelper';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import { PropertiesMatchContext } from '../../context/PropertiesMatchProvider';
import ClickableDiv from '../../../../../components/ClickableDiv';
import MatchArrowIcon from '../FarmList/components/icons/MatchArrowIcon';
import VendorPropertyMapView from './components/VendorPropertyMapView';

const PropertyView = ({ property, parentName, integrationName }) => {
  const {
    addPropertyMatchId,
    fetchVendorProperties,
    fetchMatches,
    isLoading,
    state: { vendorProperties, matches }
  } = useContext(PropertiesMatchContext);

  const [selectedProperty, setSelectedProperty] = useState(0);

  const [selectedVendorPropertyId, setSelectedVendorPropertyId] = useState(
    null
  );

  const [displayVendorBoundary, setDisplayVendorBoundary] = useState(false);

  const isPropertySelected = prpt => {
    if (!selectedProperty) {
      return false;
    }
    return prpt.id === selectedProperty.id;
  };

  const openProperty = () => {
    const currentIsOpen = selectedProperty?.id === property.id;
    setSelectedProperty(currentIsOpen ? null : property);
  };

  const onChangeVendorProperty = e => {
    const { key } = JSON.parse(e.target.value);
    if (key !== selectedVendorPropertyId) {
      setDisplayVendorBoundary(false);
      setSelectedVendorPropertyId(key);
      addPropertyMatchId(selectedProperty.id, key);
    }
  };

  const handleVendorBoundary = () => {
    setDisplayVendorBoundary(prev => !prev);
  };

  useEffect(() => {
    fetchVendorProperties();
    fetchMatches();
    setSelectedVendorPropertyId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const match = matches.find(m => property.id === m.cwfId);
    if (!match) {
      setSelectedVendorPropertyId(null);
    } else if (match && match.vendorId !== selectedVendorPropertyId) {
      setSelectedVendorPropertyId(match.vendorId);
    }
  }, [matches, selectedVendorPropertyId, property?.id]);

  const options = useMemo(() => {
    return vendorProperties?.map(vendorProperty => ({
      key: vendorProperty.id,
      value:
        integrationName === INTEGRATIONS.agrian
          ? vendorProperty.name
          : `${vendorProperty.fieldName} - ${vendorProperty.name}`
    }));
  }, [vendorProperties, integrationName]);

  const feature = fieldToFeature(property);

  return (
    <div
      data-testid="property-collapse"
      className="text-l rounded-lg p-4 shadow-md mt-1 grid-flow-row auto-rows-max max-w-800 mb-5"
    >
      <ClickableDiv
        className="flex bg-transparent text-info-dark max-w-800"
        onClick={openProperty}
      >
        <div className="w-20" data-testid="property-match-status">
          {selectedVendorPropertyId ? (
            <Tag key="matching-state-property" color="#C3EAD1">
              <span className="justify-self-center w-full text-xs mr-5 text-green-900 ml-2">
                Matched
              </span>
            </Tag>
          ) : (
            <Tag key="matching-state-property" color="#FFE4AE">
              <span className="justify-self-center w-full text-xs mr-5 text-yellow-700">
                Unmatched
              </span>
            </Tag>
          )}
        </div>
        <div className="flex justify-start ml-2" data-testid="property-title">
          {parentName && <span>{parentName} /</span>}
          <span className="font-semibold">&nbsp;{property?.name}</span>
        </div>
        <div className="flex justify-end flex-1">
          {isPropertySelected(property) ? (
            <ArrowDropUp fontSize="small" className="text-neutral-600" />
          ) : (
            <ArrowDropDown fontSize="small" className="text-neutral-600" />
          )}
        </div>
      </ClickableDiv>

      {isPropertySelected(property) && (
        <div className="justify-self-start pr-15 col-span-w w-full mt-12">
          {isLoading ? (
            <div className="mb-5">
              <Spinner />
            </div>
          ) : (
            <>
              {displayVendorBoundary && (
                <div className="grid grid-cols-4">
                  <div className="col-span-2 col-start-3">
                    <div className="flex justify-end mb-1 -mt-8">
                      <Button
                        icon={<VisibilityOffOutlined />}
                        iconLeft
                        onClick={handleVendorBoundary}
                        id="hide-vendor-property-boundary-btn"
                        size="small"
                        type="outline"
                      >
                        Hide Boundary
                      </Button>
                    </div>
                    <Select
                      label={`${integrationName} Fields`}
                      onChange={onChangeVendorProperty}
                      value={selectedVendorPropertyId}
                      items={options}
                    />
                  </div>
                </div>
              )}

              <div
                className={`grid items-${
                  displayVendorBoundary
                    ? 'end grid-cols-3'
                    : 'center grid-cols-4'
                }`}
              >
                <div className="col-span-1 justify-self-start w-full">
                  <div className="mt-2 bg-gray-100 rounded-lg overflow-hidden text-left p-4">
                    <FieldMapView
                      key={`property-preview-with-vendor=${displayVendorBoundary}`}
                      className="rounded-lg"
                      feature={feature}
                      area={property?.reportedArea || property?.boundaryArea}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center h-full col-span-1">
                  <span className="mb-1">
                    <MatchArrowIcon />
                  </span>
                </div>

                <div
                  className={`text-sm col-span-${
                    displayVendorBoundary ? '1' : '2'
                  }`}
                  style={{ position: 'relative' }}
                >
                  {!displayVendorBoundary ? (
                    <>
                      <div className="flex justify-end mb-1 -mt-8">
                        <Button
                          icon={<VisibilityOutlined />}
                          iconLeft
                          onClick={handleVendorBoundary}
                          id="display-vendor-property-boundary-btn"
                          size="small"
                          type="outline"
                        >
                          View Boundary
                        </Button>
                      </div>
                      <Select
                        label={`${integrationName} Fields`}
                        onChange={onChangeVendorProperty}
                        value={selectedVendorPropertyId}
                        items={options}
                      />
                    </>
                  ) : (
                    <div className="mt-2 bg-gray-100 rounded-lg overflow-hidden text-left p-4">
                      <VendorPropertyMapView
                        vendorId={selectedVendorPropertyId}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

PropertyView.defaultProps = {
  property: {},
  integrationName: '',
  parentName: ''
};

PropertyView.propTypes = {
  property: PropTypes.objectOf(PropTypes.any),
  integrationName: PropTypes.string,
  parentName: PropTypes.string
};

export default PropertyView;

import React from 'react';
import PropTypes from 'prop-types';

import EmptySummary from './components/EmptySummary';
import SelectedFields from './components/SelectedFields';

const ChangeSummary = ({ massiveChangeSummary }) => {
  const emptySummary = !massiveChangeSummary.fieldsToMassiveAssign?.length;
  return (
    <div className="absolute top-0 right-0 z-4 w-1/3 h-full">
      <div className="p-6 h-full">
        <div className="bg-white px-5 pb-8 rounded max-h-full overflow-y-auto">
          <h4 className="font-semibold font-body z-1 sticky top-0 bg-white pt-3">
            Change Summary
          </h4>
          {emptySummary ? (
            <EmptySummary />
          ) : (
            <SelectedFields massiveChangeSummary={massiveChangeSummary} />
          )}
        </div>
      </div>
    </div>
  );
};

ChangeSummary.defaultProps = {
  massiveChangeSummary: {}
};

ChangeSummary.propTypes = {
  massiveChangeSummary: PropTypes.shape()
};

export default ChangeSummary;

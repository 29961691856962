import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import { Input, Spinner } from '@agconnections/grow-ui';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  createTheme,
  ThemeProvider
} from '@material-ui/core';
import { obtainAllKeys } from 'screens/Reports/helpers/helperFunctions';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {
  ArrowRight,
  ArrowDropDown,
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox
} from '@material-ui/icons';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0071cd'
    }
  }
});

const GroupByProperties = ({
  mappedData,
  disabled = true,
  loading,
  handleCheck,
  checked,
  setChecked
}) => {
  const [expanded, setExpanded] = useState([]);
  const [filterText, setFilterText] = useState();
  const [propertiesNode, setPropertiesNode] = useState([]);
  const [nodesFiltered, setNodesFiltered] = useState(propertiesNode);

  useEffect(() => {
    let mappedFft = [];
    if (loading === false && mappedData) {
      mappedFft = mappedData.properties?.map(farm => ({
        value: farm.id,
        label: farm.name,
        type: 'farm',
        ...(farm.fields?.length > 0
          ? {
              children: farm.fields?.map(field => ({
                value: field.id,
                label: field.name,
                type: 'field',
                ...(field.cropzones?.length > 0
                  ? {
                      children: field.cropzones.map(cropzone => ({
                        value: cropzone.id,
                        label: cropzone.name,
                        type: 'cropzone'
                      }))
                    }
                  : { disabled: false })
              }))
            }
          : { disabled: false })
      }));
    }
    if (mappedData.properties?.length) {
      mappedFft = mappedFft.map(farm => ({
        ...farm,
        children: farm.children?.filter(field => field.children?.length)
      }));
      mappedFft = mappedFft.filter(farm => farm.children?.length);
      setPropertiesNode(mappedFft);
    }
  }, [mappedData, loading]);

  useEffect(() => {
    handleCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertiesNode]);

  const filterNodes = useCallback(
    (filtered, node) => {
      const children = (node.fields || []).reduce(filterNodes, []);
      if (
        node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
          -1 ||
        children.length
      ) {
        filtered.push({ ...node, children });
      }

      return filtered;
    },
    [filterText]
  );

  const filterTree = () => {
    if (!filterText?.length) {
      setNodesFiltered(propertiesNode);
      return;
    }

    setNodesFiltered(nodes => nodes.reduce(filterNodes, []));
  };

  const onFilterChange = e => {
    if (e.target.value.length >= 3) {
      setFilterText(e.target.value);
      filterTree();
    } else {
      setFilterText('');
    }
  };

  useEffect(() => {
    if (!filterText) {
      setNodesFiltered(propertiesNode);
      return;
    }

    setNodesFiltered(prevState => prevState.reduce(filterNodes, []));
  }, [filterText, filterNodes, propertiesNode]);

  useEffect(() => {
    setChecked(obtainAllKeys(propertiesNode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertiesNode]);

  const propertyKeys = obtainAllKeys(propertiesNode);

  const handleSelectAllCheckBox = () => {
    if (checked.length === propertyKeys.length) {
      setChecked([]);
    } else {
      setChecked(propertyKeys);
    }
  };

  return (
    <div className="filter-container">
      {!loading ? (
        <>
          {mappedData.properties?.length > 0 && (
            <>
              <div
                className="my-2"
                data-testid="properties-filter-container"
                style={{ display: 'none' }}
              >
                <Input
                  className="filter-text"
                  placeholder="Search..."
                  type="search"
                  onChange={onFilterChange}
                />
              </div>
              <div className="mt-2 flex">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          style={{ borderColor: '#00000099' }}
                          size="small"
                          color="primary"
                          onChange={handleSelectAllCheckBox}
                          checked={checked?.length === propertyKeys?.length}
                        />
                      </ThemeProvider>
                    }
                    label="Select All"
                  />
                </FormGroup>
              </div>

              <CheckboxTree
                nodes={nodesFiltered}
                checked={checked}
                showNodeIcon={false}
                icons={{
                  halfCheck: <IndeterminateCheckBox fontSize="small" />,
                  uncheck: (
                    <CheckBoxOutlineBlank
                      fontSize="small"
                      className="text-neutral-600"
                    />
                  ),
                  check: <CheckBox fontSize="small" />,
                  expandClose: (
                    <ArrowRight fontSize="small" className="text-neutral-600" />
                  ),
                  expandOpen: (
                    <ArrowDropDown
                      fontSize="small"
                      className="text-neutral-600"
                    />
                  )
                }}
                css="text-lg"
                expanded={expanded}
                onCheck={id => handleCheck(id)}
                onExpand={ex => setExpanded(ex)}
                showNodeTitle
                disabled={disabled}
                checkModel="all"
              />
            </>
          )}
        </>
      ) : (
        <Spinner size="sm" />
      )}
    </div>
  );
};

GroupByProperties.defaultProps = {
  disabled: false,
  mappedData: [],
  loading: false
};
GroupByProperties.propTypes = {
  mappedData: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  handleCheck: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  setChecked: PropTypes.func.isRequired
};

export default GroupByProperties;

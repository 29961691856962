import React from 'react';
import PropTypes from 'prop-types';

import CLUButton from '../CLUButton';
import SatelliteButton from '../SatelliteButton';
import ImportModal from '../../../../ImportModal';
import HistoryButtons from '../HistoryButtons';

const TopLeftControlButtons = ({ overlay, setOverlay }) => {
  const handleToggleCluButton = event => {
    const { checked } = event.target;
    setOverlay(checked ? 'CLU' : null);
  };

  const handleToggleSatelliteButton = event => {
    const { checked } = event.target;
    setOverlay(checked ? 'SAT' : null);
  };
  return (
    <div className="flex items-center space-x-3">
      <CLUButton value={overlay === 'CLU'} onToggle={handleToggleCluButton} />
      <SatelliteButton
        value={overlay === 'SAT'}
        onToggle={handleToggleSatelliteButton}
      />
      <ImportModal />
      <HistoryButtons />
    </div>
  );
};

TopLeftControlButtons.defaultProps = {
  overlay: null
};

TopLeftControlButtons.propTypes = {
  overlay: PropTypes.string,
  setOverlay: PropTypes.func.isRequired
};

export default TopLeftControlButtons;

import PropTypes from 'prop-types';
import React from 'react';

export const StatusItem = ({ title, message, color, icon }) => {
  return (
    <div className={`w-full flex flex-row mb-2 p-4 ${color}`}>
      {icon}
      <div className="flex flex-col">
        <p className="text-base">{title}</p>
        <p className="font-normal text-sm text-gray-600">{message}</p>
      </div>
    </div>
  );
};

StatusItem.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.element
};

StatusItem.defaultProps = {
  icon: null
};

export default StatusItem;

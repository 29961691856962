import React, { useContext, useEffect, useState } from 'react';
import { Context } from 'components/Store';
import PropTypes from 'prop-types';
import ToggleButton from 'components/ToggleButton';
import { Toast } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import Weather from '../Details/Sections/Weather';

const WeatherTab = ({
  fieldsAndAreasCentroidCoordinates,
  fieldsAndAreasGeoJSONCollection
}) => {
  const [context, dispatch] = useContext(Context);
  const { values } = useFormikContext();
  const { weatherAutomaticToggle } = context;
  const classes = {
    sectionHeader: 'flex justify-between mb-6 ',
    sectionHeaderText: 'text-2xl font-semibold mb-6',
    sectionContent: 'grid items-end grid-cols-2 gap-24 mb-12 gap-y-12'
  };
  const [toastRenderContents, setToastRenderContents] = useState(null);
  const [toastHasNotBeenClosed, setToastHasNotBeenClosed] = useState(true);

  const setToastMessage = () => {
    if (
      (values?.properties === undefined || values?.properties.length === 0) &&
      weatherAutomaticToggle &&
      values?.status === 'complete'
    ) {
      setToastRenderContents(
        'Please select a property or properties on the Properties Tab if you are getting weather automatically.'
      );
      setToastHasNotBeenClosed(true);
    }
  };

  useEffect(() => {
    setToastMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weatherAutomaticToggle]);

  return (
    <>
      {toastHasNotBeenClosed ? (
        <Toast
          icon="error"
          onClose={() => {
            setToastHasNotBeenClosed(false);
          }}
        >
          {toastRenderContents}
        </Toast>
      ) : null}
      <div className={classes.sectionHeader}>
        <div className={classes.sectionHeaderText}>Start Weather</div>
        <div className="flex justify-between mt-3">
          <div className="text-xs text-weather_automatic_button">
            GET WEATHER AUTOMATICALLY
          </div>
          <div className="ml-2">
            <ToggleButton
              color="#0071CD"
              defaultChecked={weatherAutomaticToggle}
              onChange={() => dispatch({ type: 'SWITCH_WEATHER_TOGGLE' })}
            />
          </div>
        </div>
      </div>
      <div
        className={classes.sectionContent}
        data-testid="start-weather-content"
        id="start-weather"
      >
        <Weather
          index={0}
          fieldsAndAreasCentroidCoordinates={fieldsAndAreasCentroidCoordinates}
          fieldsAndAreasGeoJSONCollection={fieldsAndAreasGeoJSONCollection}
        />
      </div>
      {values?.status === 'complete' && (
        <>
          <div className={classes.sectionHeader}>
            <div className={classes.sectionHeaderText}>End Weather</div>
          </div>
          <div
            className={classes.sectionContent}
            data-testid="end-weather-content"
            id="end-weather"
          >
            <Weather
              index={1}
              fieldsAndAreasCentroidCoordinates={
                fieldsAndAreasCentroidCoordinates
              }
              fieldsAndAreasGeoJSONCollection={fieldsAndAreasGeoJSONCollection}
            />
          </div>
        </>
      )}
    </>
  );
};

WeatherTab.propTypes = {
  fieldsAndAreasCentroidCoordinates: PropTypes.arrayOf(PropTypes.number),
  // eslint-disable-next-line react/forbid-prop-types
  fieldsAndAreasGeoJSONCollection: PropTypes.object.isRequired
};
WeatherTab.defaultProps = {
  fieldsAndAreasCentroidCoordinates: []
};

export default WeatherTab;

import { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { feature } from '@turf/turf';

import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import {
  field as fieldAPI,
  fieldV2 as fieldAPIV2,
  createChildApi
} from 'utilities/api';

import {
  initialRequestState,
  onRequestEnd,
  onRequestFail,
  onRequestStart,
  onRequestSuccess
} from 'utilities/helpers/requestState';

import { DEFAULT_ZOOM, USA_CENTER_COORDINATES } from '../helpers/constants';
import { coordinatesFormat } from '../helpers/fieldDataHelpers';

const useFieldData = (id, useV2 = false) => {
  const [archive, setArchive] = useState(initialRequestState);
  const [state, setState] = useState();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [field, setField] = useState();
  const [fieldGeoJSONCollection, setFieldGeoJSONCollection] = useState({
    type: 'FeatureCollection',
    features: []
  });
  const [fieldCentroidCoordinates, setFieldCentroidCoordinates] = useState(
    USA_CENTER_COORDINATES
  );
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [cropHistory, setCropHistory] = useState([]);
  const [productsServices, setProductsServices] = useState([]);
  const [variety, setVariety] = useState([]);
  const fieldUpdateObject = useRef({}); // a copy of the field only used for calling the PUT api endpoint for updating
  const savingRef = useRef(false);
  const [, dispatch] = useContext(Context);

  const loadField = useCallback(
    (fieldId = id, redirectOnError) => {
      setField(null);
      setLoading(true);
      const { promise, cancel } = fieldAPI.fetch(fieldId);

      promise
        .then(({ data }) => {
          // Coordinate string fixed
          const dataUpdated = {
            ...data,
            coordinates: coordinatesFormat(data.coordinates || '')
          };

          // update with the value of field object
          fieldUpdateObject.current = dataUpdated;
          setField(dataUpdated);

          setFieldGeoJSONCollection(previousGeoJSONCollection => {
            // eslint-disable-next-line no-param-reassign
            previousGeoJSONCollection.features = [
              feature(dataUpdated.geometry)
            ];

            return previousGeoJSONCollection;
          });
        })
        .catch(catchCancel)
        .catch(parseServerError(dispatch, { showTryAgain: !!redirectOnError }))
        .finally(() => {
          setLoading(false);
        });

      return { promise, cancel };
    },
    [dispatch, id]
  );

  useEffect(() => {
    if (id) {
      loadField();
    }
  }, [id, loadField]);

  const createField = useCallback(
    fieldData => {
      const { promise } = fieldAPI.create(fieldData);
      promise
        .then(({ data }) => {
          setField(data);
        })
        .catch(catchCancel)
        .catch(parseServerError(dispatch));
      return promise;
    },
    [dispatch]
  );

  // not refactored to same approach than unarchiveField cause it is going to envolve refactor also the archived field components
  // but you can do it when have time or if the jira-card is directly related with this flow
  const archiveField = async fieldId => {
    return new Promise((resolve, reject) => {
      const archiveFn = fieldAPI.createChildApi({
        action: `field/${fieldId}/archive`
      });
      const { promise } = archiveFn.post();
      promise
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  };

  const unarchiveField = useCallback(
    async fieldId => {
      setArchive(curr => onRequestStart(curr));
      const unarchiveFn = fieldAPI.createChildApi({
        action: `field/${fieldId}/unarchive`
      });
      const { promise } = unarchiveFn.post();
      promise
        .then(() => {
          setArchive(curr => onRequestSuccess(curr));
        })
        .catch(catchCancel)
        .catch(error => {
          setArchive(curr => onRequestFail(curr, error));
          parseServerError(dispatch);
        })
        .finally(() => {
          setArchive(curr => onRequestEnd(curr));
        });
    },
    [dispatch]
  );

  const editField = useCallback(
    (values, fieldId) => {
      if (savingRef.current) {
        return Promise.reject(new Error('Save is already in progress'));
      }
      setState('');
      setSaving(true);
      savingRef.current = true;
      const { promise } = fieldAPI.update(values.id || fieldId, values);

      promise
        .then(({ data }) => {
          setState('success');
          setField(data);
          return data;
        })
        .catch(catchCancel)
        .catch(setState('error'))
        .catch(parseServerError(dispatch))
        .finally(() => {
          savingRef.current = false;
          setSaving(false);
        });
      return promise;
    },
    [dispatch]
  );

  const updateField = useCallback(
    (values, fieldId) => {
      if (!fieldUpdateObject.current.id && !fieldId) {
        return Promise.reject(new Error('Save is already in progress'));
      }
      const updateObject = {
        ...fieldUpdateObject.current,
        ...values
      };

      fieldUpdateObject.current = null;
      const { promise } = fieldAPI.update(
        updateObject.id || fieldId,
        updateObject
      );

      promise
        .then(({ data }) => {
          setField(data);
          fieldUpdateObject.current = data;
        })
        .catch(catchCancel)
        .catch(parseServerError(dispatch));

      return promise;
    },
    [dispatch]
  );

  const makeRequest = (setStateFn, endpoint, v2PropertiesPostBody) => {
    const createApiV1 = () => {
      const childApi = createChildApi(fieldAPI, endpoint);

      return childApi.fetch();
    };

    const createApiV2 = () => {
      const childApi = createChildApi(fieldAPIV2, endpoint);

      return childApi.create(v2PropertiesPostBody);
    };

    const { promise } = useV2 ? createApiV2() : createApiV1();

    promise
      .then(({ data }) => {
        setStateFn(data.results);
      })
      .catch(catchCancel)
      .catch(parseServerError(dispatch));
  };

  const getFieldData = () => {
    return {
      getAppliedProducts(endpoint, v2PropertiesPostBody = {}) {
        makeRequest(setProductsServices, endpoint, v2PropertiesPostBody);
      },
      getVariety(endpoint, v2PropertiesPostBody = {}) {
        makeRequest(setVariety, endpoint, v2PropertiesPostBody);
      },
      getCrophistory(endpoint, v2PropertiesPostBody = {}) {
        makeRequest(setCropHistory, endpoint, v2PropertiesPostBody);
      }
    };
  };

  return {
    field,
    fieldCentroidCoordinates,
    setFieldCentroidCoordinates,
    fieldGeoJSONCollection,
    loading,
    saving,
    state,
    setField,
    updateField,
    loadField,
    createField,
    editField,
    archive,
    archiveField,
    unarchiveField,
    zoom,
    setZoom,
    getFieldData,
    productsServices,
    cropHistory,
    variety
  };
};

export default useFieldData;

import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import CwFPagination from 'components/CwFPagination';
import { Redirect, useHistory } from 'react-router-dom';
import catchCancel from 'helpers/catchCancel';
import { Context } from 'components/Store';
import TileGroup from 'components/TileGroup';
import TileCard from 'components/TileCard';
import TileCardField from 'components/TileCardField';
import ListWithPopup from 'components/ListWithPopup';
import Paper from 'components/Paper';
import { parseServerError } from 'helpers/errorHelpers';
import ConvertPlanModal from 'screens/Plans/ConvertPlanModal';
import { plan as planApi } from 'utilities/api';
import FormattedNumber from 'components/FormattedNumber';
import TagsModal from 'components/Modals/TagsModal';
import {
  TagsCloudDisplay,
  TagsPopUpMessage,
  extraTagsCalculation
} from 'components/TagsButton';
import { PLANS_LANDING_MENU } from 'utilities/menus';
import { hasFinancialAccess } from 'utilities/access';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { STORAGE_KEYS } from 'utilities/localStorage';
import ConvertPlanToRecModal from 'screens/Plans/ConvertPlanToRecModal';
import usePlanEllipsisOptions from 'screens/Plans/hooks/usePlanEllipsisOptions';
import { paths } from 'routes/paths';
import { groupPlansByCropSeason } from '../../helpers/groupHelpers';
import DeletePlanModal from '../DeletePlanModal';

const PlanTileBoard = ({
  plans,
  loadPlanSummaries,
  setTagsLoading,
  totalPlans,
  planPageNumber,
  handlePageChange
}) => {
  const [redirect] = useState(null);
  const [{ loggedInUserOrgPermission }, dispatch] = useContext(Context);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [planToConvert, setPlanToConvert] = useState();
  const [openConvertPlanModal, setOpenConvertPlanModal] = useState(false);
  const [openConvertPlanToRecModal, setOpenConvertPlanToRecModal] = useState(
    false
  );
  const [gpIndex, setGpIndex] = useState(-1);
  const [tileIndex, setTileIndex] = useState(-1);

  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);
  const closeCovertPlanModal = () => setOpenConvertPlanModal(false);
  const closeCovertPlanToRecModal = () => setOpenConvertPlanToRecModal(false);

  const planGroups = plans?.length ? groupPlansByCropSeason(plans) : [];

  const [planToDelete, setPlanToDelete] = useState();
  const [openTags, setOpenTags] = useState(false);
  const [planName, setPlanName] = useState('');
  const [planNum, setPlanNum] = useState();
  const [allFlags, setAllFlags] = useState([]);
  const [, setError] = useState(null);
  const canViewFinancialData = hasFinancialAccess(
    loggedInUserOrgPermission.financialAccess,
    'READ'
  );

  const history = useHistory();
  const amplitude = useContext(AmplitudeContext);
  const { shareOrPrint, downloadingPDF } = usePlanEllipsisOptions();

  const handleAction = async (action, plan, groupIndex, index, snName) => {
    const currentPlan = plans?.find(p => p.id === plan?.planId);
    setGpIndex(groupIndex);
    setTileIndex(index);

    const actions = {
      view: () => {
        history.push({
          pathname: `${paths.plans}/${plan.planId}/view`,
          state: {
            selectedView: 'edit-fromView',
            seasonName: snName
          }
        });
      },
      edit: () => {
        history.push({
          pathname: `${paths.plans}/${plan.planId}`
        });
      },
      duplicate: () => {
        history.push({
          pathname: `${paths.plans}/${plan.planId}`,
          state: {
            selectedView: 'edit-dup'
          }
        });
      },
      tag: () => {
        setPlanName(plan.name);
        setPlanNum(plan.planId);
        setAllFlags(
          plan.flags?.length > 0 || plan.flags !== undefined ? plan.flags : []
        );
        setOpenTags(true);
      },
      convertTask: () => {
        setPlanToConvert(currentPlan);
        setOpenConvertPlanModal(true);
      },
      convertToRec: () => {
        setPlanToConvert(currentPlan);
        setOpenConvertPlanToRecModal(true);
      },
      shareOrPrint: () => {
        shareOrPrint(plan.planId);
      },
      delete: () => {
        amplitude.sendEventToAmplitude(amplitude.events.epic.Plans.deletePlan);
        setPlanToDelete(plan);
        openDeleteModal();
      }
    };
    actions[action]();
  };

  const getDeleteModal = () => {
    if (planToDelete?.planId?.length > 0) {
      return (
        <DeletePlanModal
          open={deleteModalOpen}
          close={closeDeleteModal}
          planName={planToDelete?.name}
          planId={planToDelete?.planId} // as per planSummaries API, we get planId
        />
      );
    }
    return <></>;
  };

  const getConvertPlanModal = () => {
    if (planToConvert?.id?.length > 0) {
      return (
        <ConvertPlanModal
          open={openConvertPlanModal}
          close={closeCovertPlanModal}
          currentPlan={planToConvert}
        />
      );
    }
    return <></>;
  };

  const getConvertPlanToRecModal = () => {
    if (planToConvert?.id?.length > 0) {
      return (
        <ConvertPlanToRecModal
          open={openConvertPlanToRecModal}
          close={closeCovertPlanToRecModal}
          currentPlan={planToConvert}
        />
      );
    }
    return <></>;
  };

  const handleReturnTags = async e => {
    setTagsLoading(true);
    const filterPlans = plans.filter(eachPlan => eachPlan.id === planNum);
    const body = {
      ...filterPlans[0],
      id: filterPlans[0].planId,
      flagIds: e.map(item => item.id)
    };
    delete body.createdOn;
    delete body.variableCost;
    delete body.projectRevenue;
    delete body.netRevenue;
    delete body.planId;
    delete body.cropSeasons;
    delete body.crop;
    delete body.fixedCost;
    delete body.flags;
    const { promise } = await planApi.update(planNum, body);
    await promise
      .then(() => {
        setAllFlags(e);
        setTagsLoading(false);
        loadPlanSummaries();
      })
      .catch(catchCancel)
      .catch(err => {
        if (err.response?.data?.validationErrors) {
          setError(`Error saving task: ${err.response.data.message}`);
        } else if (err.response?.data?.displayErrorMessage) {
          setError(err.response.data.displayErrorMessage);
        } else {
          parseServerError(dispatch)(err);
        }
        setTagsLoading(false);
      });
  };

  const applyNumberFormatting = (columnValueToFormat, unit, roundingDigit) => (
    <FormattedNumber
      unit={unit === 'USD' ? 'usCurrency' : unit}
      roundedTo={roundingDigit}
    >
      {columnValueToFormat}
    </FormattedNumber>
  );

  const numberFormat = num => applyNumberFormatting(num, 'USD', 2);

  useEffect(() => {
    if (!downloadingPDF) {
      setGpIndex(-1);
      setTileIndex(-1);
    }
  }, [downloadingPDF]);

  return (
    <div
      className="my-4 min-w-content"
      data-testid="plans-tile-board"
      style={{
        minWidth: 'min-content'
      }}
    >
      <Paper>
        {CwFPagination(totalPlans, planPageNumber, handlePageChange)}
        <div className="pb-2"> </div>
        {redirect && <Redirect push to={`${redirect}`} />}
        {getConvertPlanModal()}
        {getConvertPlanToRecModal()}
        {getDeleteModal()}
        <TagsModal
          open={openTags}
          close={() => {
            setOpenTags(false);
          }}
          name={planName}
          selectedTags={allFlags?.reduce((tagIds, tag) => {
            return `${tagIds},${tag.id}`;
          }, '')}
          returnSelectedTags={e => {
            handleReturnTags(e);
          }}
        />
        {planGroups?.length &&
          planGroups.map((items, groupIndex) => {
            return (
              <TileGroup
                key={items.id}
                title={`Crop Season: ${items.name || ''}`}
                totalItems={items.plans?.length}
                testId="plans"
              >
                {items.plans.map((item, index) => {
                  const fullPlan = plans?.find(
                    aPlan => aPlan?.id === item?.planId
                  );
                  const displayTagsObject = extraTagsCalculation(
                    item?.flags,
                    27
                  );
                  return (
                    <TileCard
                      key={item.planId}
                      testId="plan"
                      linkTo={`${paths.plans}/${item.planId}/view`}
                      title={item.name}
                      subtitle={item.crop.name || 'No Crop'}
                      runningProcesses={
                        downloadingPDF &&
                        gpIndex === groupIndex &&
                        tileIndex === index
                      }
                      onAction={action => {
                        handleAction(
                          action,
                          item,
                          groupIndex,
                          index,
                          items.name
                        );
                      }}
                      menuItems={PLANS_LANDING_MENU}
                    >
                      <div
                        data-testid="plan-card-details"
                        className="flex flex-wrap w-full"
                      >
                        {displayTagsObject.extraTagsNames.length > 0 ? (
                          <ListWithPopup
                            position="bottom"
                            ariaLabel="Task Tags"
                            top="1.5rem"
                            msg={
                              <TagsPopUpMessage
                                extraTagsNames={
                                  displayTagsObject.extraTagsNames
                                }
                              />
                            }
                            component={
                              <TagsCloudDisplay
                                displayTagsObject={displayTagsObject}
                                isLandingPage
                                viewKey={STORAGE_KEYS.PLANS_VIEW}
                              />
                            }
                          />
                        ) : (
                          <TagsCloudDisplay
                            displayTagsObject={displayTagsObject}
                            isLandingPage
                            viewKey={STORAGE_KEYS.PLANS_VIEW}
                          />
                        )}
                      </div>
                      <div className="flex flex-wrap w-full">
                        <TileCardField
                          id="plan-planned-area"
                          title="Planned Area"
                          value={item?.estimatedAreaValue.toFixed(2)}
                          testId="plan"
                        />
                        <TileCardField
                          id="plan-created"
                          title="Created"
                          value={item?.createdOn}
                          testId="plan"
                        />
                        <TileCardField
                          id="plan-fixed-cost-per-acre"
                          title="Fixed Cost/Acre"
                          value={numberFormat(
                            item?.fixedCost / item?.estimatedAreaValue
                          )}
                          testId="plan"
                          hideValue={!canViewFinancialData}
                        />
                        <TileCardField
                          id="plan-variable-cost-per-acre"
                          title="Variable Cost/Acre"
                          value={numberFormat(
                            item?.variableCost / item?.estimatedAreaValue
                          )}
                          testId="plan"
                          hideValue={!canViewFinancialData}
                        />
                        <TileCardField
                          id="plan-projected-revenue-per-acre"
                          title="Proj. Rev/Acre"
                          value={numberFormat(
                            (fullPlan?.projectedRevenue?.yieldPerArea *
                              fullPlan?.projectedRevenue?.pricePerUnit *
                              fullPlan?.estimatedAreaValue +
                              fullPlan?.projectedRevenue?.fsaPayment) /
                              item?.estimatedAreaValue
                          )}
                          testId="plan"
                          hideValue={!canViewFinancialData}
                        />
                        <TileCardField
                          id="plan-projected-net-revenue-per-acre"
                          title="Proj. Net Rev/Acre"
                          value={numberFormat(
                            ((fullPlan?.projectedRevenue?.yieldPerArea *
                              fullPlan?.projectedRevenue?.pricePerUnit *
                              fullPlan?.estimatedAreaValue +
                              fullPlan?.projectedRevenue?.fsaPayment) *
                              fullPlan?.projectedRevenue?.cropSharePercent -
                              (item?.fixedCost + item?.variableCost)) /
                              item?.estimatedAreaValue
                          )}
                          hideValue={!canViewFinancialData}
                        />
                      </div>
                    </TileCard>
                  );
                })}
              </TileGroup>
            );
          })}
        {CwFPagination(totalPlans, planPageNumber, handlePageChange)}
        <div className="mb-12"> </div>
      </Paper>
    </div>
  );
};

PlanTileBoard.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.object),
  loadPlanSummaries: PropTypes.func.isRequired,
  setTagsLoading: PropTypes.func.isRequired,
  totalPlans: PropTypes.number.isRequired,
  planPageNumber: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired
};

PlanTileBoard.defaultProps = {
  plans: []
};

export default PlanTileBoard;

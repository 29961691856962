import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from '@agconnections/grow-ui';
import FormattedNumber from 'components/FormattedNumber';
import ProductsModal from '../ProductsModal';

const ProductsTableRow = ({
  product,
  pests,
  applicationOption,
  tankInformation
}) => {
  const [productsModalOpen, setProductsModalOpen] = useState(false);
  const chkApplicationOption = () => {
    if (
      applicationOption?.title === 'Rate/Area' ||
      applicationOption?.title === 'Total Product'
    ) {
      return (
        <>
          <FormattedNumber roundedTo="2">
            {product.productRatePerAreaValue}
          </FormattedNumber>{' '}
          {product.productRatePerAreaUnit}
        </>
      );
    }
    return (
      <>
        <FormattedNumber roundedTo="2">
          {product.productRatePerTankValue}
        </FormattedNumber>{' '}
        {product.productRatePerTankUnit}
      </>
    );
  };

  return (
    <>
      <ProductsModal
        open={productsModalOpen}
        onClose={() => {
          setProductsModalOpen(false);
        }}
        product={product}
        pests={pests}
      />
      <Table.Row>
        <Table.Cell>
          <span className="flex flex-col">
            <span
              className="pb-2 text-sm font-bold text-left truncate"
              data-testid="product-name"
            >
              {product.productName}
            </span>
            <span
              className="text-sm text-left text-gray-600 truncate pb-2"
              data-testid="product-manufacturer"
            >
              {product.custom && <>Custom</>}
              {product.custom && product.manufacturerName && <> • </>}
              {product.manufacturerName}
            </span>
          </span>
        </Table.Cell>
        <Table.Cell>{chkApplicationOption()}</Table.Cell>
        <Table.Cell>
          <FormattedNumber roundedTo="2">
            {product.productAppliedAreaValue}
          </FormattedNumber>{' '}
          {product.productAppliedAreaUnit}
        </Table.Cell>
        <Table.Cell>{product.coveragePercent * 100} %</Table.Cell>
        <Table.Cell>
          {/* <FormattedNumber roundedTo="2">
            {product.totalProductValue}
          </FormattedNumber>{' '}
          {product.totalProductUnit} */}
          {applicationOption?.title === 'Rate/Tank' ? (
            <>
              <FormattedNumber roundedTo="2">
                {tankInformation?.tankCount * product?.productRatePerTankValue}
              </FormattedNumber>{' '}
              {product?.productRatePerTankUnit}
            </>
          ) : (
            <>
              <FormattedNumber roundedTo="2">
                {product.totalProductValue}
              </FormattedNumber>{' '}
              {product.totalProductUnit}
            </>
          )}
        </Table.Cell>
        <Table.Cell>
          <div
            data-testid="product-table-view-button"
            className="mr-8 absolute right-0 flex-none w-10"
            data-html2canvas-ignore
          >
            <Button
              type="link-default"
              onClick={() => setProductsModalOpen(true)}
            >
              View
            </Button>
          </div>
        </Table.Cell>
      </Table.Row>
    </>
  );
};

ProductsTableRow.propTypes = {
  product: PropTypes.shape({
    applicationMethod: PropTypes.string,
    coveragePercent: PropTypes.number,
    custom: PropTypes.bool,
    manufacturerName: PropTypes.string,
    productAppliedAreaUnit: PropTypes.string,
    productAppliedAreaValue: PropTypes.number,
    productId: PropTypes.string,
    productName: PropTypes.string,
    productRatePerAreaUnit: PropTypes.string,
    productRatePerAreaValue: PropTypes.number,
    productRatePerTankUnit: PropTypes.string,
    productRatePerTankValue: PropTypes.number,
    targetPestId: PropTypes.number,
    totalProductUnit: PropTypes.string,
    totalProductValue: PropTypes.number
  }).isRequired,
  pests: PropTypes.arrayOf(
    PropTypes.shape({
      latinnames: PropTypes.string,
      name: PropTypes.string,
      nameid: PropTypes.number,
      pestid: PropTypes.number
    })
  ).isRequired,
  applicationOption: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    unit: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  tankInformation: PropTypes.shape({
    tankCount: PropTypes.number
  }).isRequired
};

export default ProductsTableRow;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { withHoverPopup } from 'components/_HOC_';
import CircleButton from '../../../../CircleButton';
import LandContext from '../../../../../PropertyMapContext';
import ButtonPopupMessage from '../ButtonPopupMessage';

const Fill = '#696F88';
const DisabledFill = '#A0AEC0';

const ScissorIcon = ({ disabled }) => {
  const fill = disabled ? DisabledFill : Fill;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.42668 5.09333C6.58001 4.76 6.66668 4.39333 6.66668 4C6.66668 2.52667 5.47334 1.33333 4.00001 1.33333C2.52668 1.33333 1.33334 2.52667 1.33334 4C1.33334 5.47333 2.52668 6.66667 4.00001 6.66667C4.39334 6.66667 4.76001 6.58 5.09334 6.42667L6.66668 8L5.09334 9.57333C4.76001 9.42 4.39334 9.33333 4.00001 9.33333C2.52668 9.33333 1.33334 10.5267 1.33334 12C1.33334 13.4733 2.52668 14.6667 4.00001 14.6667C5.47334 14.6667 6.66668 13.4733 6.66668 12C6.66668 11.6067 6.58001 11.24 6.42668 10.9067L8.00001 9.33333L12.6667 14H14.6667V13.3333L6.42668 5.09333ZM4.00001 5.33333C3.26668 5.33333 2.66668 4.74 2.66668 4C2.66668 3.26 3.26668 2.66667 4.00001 2.66667C4.73334 2.66667 5.33334 3.26 5.33334 4C5.33334 4.74 4.73334 5.33333 4.00001 5.33333ZM2.66668 12C2.66668 12.74 3.26668 13.3333 4.00001 13.3333C4.73334 13.3333 5.33334 12.74 5.33334 12C5.33334 11.26 4.73334 10.6667 4.00001 10.6667C3.26668 10.6667 2.66668 11.26 2.66668 12ZM8.00001 8.33333C7.81334 8.33333 7.66668 8.18667 7.66668 8C7.66668 7.81333 7.81334 7.66667 8.00001 7.66667C8.18668 7.66667 8.33334 7.81333 8.33334 8C8.33334 8.18667 8.18668 8.33333 8.00001 8.33333ZM8.66668 6L12.6667 2H14.6667V2.66667L10 7.33333L8.66668 6Z"
        fill={fill}
      />
    </svg>
  );
};

ScissorIcon.propTypes = { disabled: PropTypes.bool.isRequired };

const SubtractIcon = ({ disabled }) => {
  const fill = disabled ? DisabledFill : Fill;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.67621 8.09302C6.37981 8.09302 6.13953 8.33386 6.13953 8.63094V9.32255C6.13953 9.61963 6.37981 9.86047 6.67621 9.86047H12.5796C12.876 9.86047 13.1163 9.61963 13.1163 9.32255V8.63094C13.1163 8.33386 12.876 8.09302 12.5796 8.09302H6.67621Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1628 0H2.5L0 15.1628H6.28849C5.99103 15.0005 5.70687 14.8168 5.43807 14.6139C6.60772 15.4847 8.05764 16 9.62791 16C10.8322 16 11.9657 15.6969 12.9563 15.1628H15.1628V13.3006C16.0953 12.1085 16.6512 10.6075 16.6512 8.97674C16.6512 7.34594 16.0953 5.84496 15.1628 4.65291V0ZM9.62791 14.5116C12.6847 14.5116 15.1628 12.0336 15.1628 8.97674C15.1628 5.91991 12.6847 3.44186 9.62791 3.44186C6.57108 3.44186 4.09302 5.91991 4.09302 8.97674C4.09302 12.0336 6.57108 14.5116 9.62791 14.5116Z"
        fill={fill}
      />
    </svg>
  );
};

SubtractIcon.propTypes = { disabled: PropTypes.bool.isRequired };

const DeleteIcon = ({ disabled }) => {
  const fill = disabled ? DisabledFill : Fill;

  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66671 2H6.33337L5.66671 2.66667H3.33337V4H12.6667V2.66667H10.3334L9.66671 2ZM10.6667 6V12.6667H5.33337V6H10.6667ZM4.00004 4.66667H12V12.6667C12 13.4 11.4 14 10.6667 14H5.33337C4.60004 14 4.00004 13.4 4.00004 12.6667V4.66667Z"
        fill={fill}
      />
    </svg>
  );
};

DeleteIcon.propTypes = { disabled: PropTypes.bool.isRequired };

const SplitButton = withHoverPopup(
  ({ onSplit, splitDisabled }) => (
    <div data-testid="split-button-container">
      <CircleButton onClick={onSplit} disabled={splitDisabled}>
        <ScissorIcon disabled={splitDisabled} />
      </CircleButton>
    </div>
  ),
  <ButtonPopupMessage message="Split Tool" hotkey="x" />,
  { position: 'left', ariaLabel: 'Split Tool (s)', top: '-0.25rem' }
);

const SubtractButton = withHoverPopup(
  ({ onSubtract, subtractDisabled }) => (
    <div data-testid="subtract-button-container">
      <CircleButton onClick={onSubtract} disabled={subtractDisabled}>
        <SubtractIcon disabled={subtractDisabled} />
      </CircleButton>
    </div>
  ),
  <ButtonPopupMessage message="Subtract Selection" hotkey="s" />,
  { position: 'left', ariaLabel: 'Subtract Selection (s)', top: '-0.25rem' }
);

const DeleteButton = withHoverPopup(
  ({ onDelete, selectedShapeIds }) => (
    <div data-testid="delete-button-container">
      <CircleButton
        onClick={onDelete}
        disabled={!selectedShapeIds || selectedShapeIds.length === 0}
      >
        <DeleteIcon
          disabled={!selectedShapeIds || selectedShapeIds.length === 0}
        />
      </CircleButton>
    </div>
  ),
  <ButtonPopupMessage message="Delete Shape" hotkey="Del" />,
  { position: 'left', ariaLabel: 'Delete Shape (Del)', top: '-0.25rem' }
);

const EditButtons = ({ onDelete, onSubtract, onSplit }) => {
  const { selectedShapeIds } = useContext(LandContext);
  const splitDisabled = selectedShapeIds?.length !== 1;
  const subtractDisabled = selectedShapeIds?.length !== 2;

  return (
    <div data-testid="edit-buttons" className="flex flex-col">
      <SplitButton onSplit={onSplit} splitDisabled={splitDisabled} />
      <SubtractButton
        onSubtract={onSubtract}
        subtractDisabled={subtractDisabled}
      />

      <DeleteButton onDelete={onDelete} selectedShapeIds={selectedShapeIds} />
    </div>
  );
};

EditButtons.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onSubtract: PropTypes.func.isRequired,
  onSplit: PropTypes.func.isRequired
};

export default EditButtons;

import { get, uniqBy } from 'lodash';

// create a tuple of the form [cropSeason, planItems]
const createCropSeasonGroupTuple = (cropSeason, previousItems, newItem) => [
  cropSeason,
  [...previousItems, newItem]
];

export const addPlanToGroup = (groups, cropSeason, plan) => {
  const groupIndex = groups.findIndex(([name]) => name === cropSeason);

  if (groupIndex === -1) {
    return null;
  }
  const [groupName, groupList] = groups[groupIndex];
  // eslint-disable-next-line no-param-reassign
  groups[groupIndex] = createCropSeasonGroupTuple(groupName, groupList, plan);
  return groups;
};

export const createNewGroup = (groups, cropSeason, plan) => {
  groups.push(createCropSeasonGroupTuple(cropSeason, [], plan));
  return groups;
};

// can't use lodash groupBy because the object keys end up sorted by crop season name
export const groupPlans = plans =>
  plans.reduce((groups, plan) => {
    const cropSeason = get(plan, 'cropSeason.cropSeasonLabel');
    // if the group exists add this plan to that group, otherwise create the group
    return (
      addPlanToGroup(groups, cropSeason, plan) ||
      createNewGroup(groups, cropSeason, plan)
    );
  }, []);

export const groupPlansByCropSeason = plans => {
  const allCropSeasons = [];
  plans.forEach(({ cropSeasons }) =>
    allCropSeasons.push(...(cropSeasons ?? []))
  );
  const uniqueCropSeasons = uniqBy(allCropSeasons, ({ seasonId }) => seasonId);

  const groups = [];
  uniqueCropSeasons.map(cs => {
    const plansWithCropSeasons = [];
    const plansWithNoCropSeasons = [];
    plans.map(data => {
      if (data.cropSeasons?.length > 0) {
        const contains = data.cropSeasons.some(elem => {
          return JSON.stringify(cs) === JSON.stringify(elem);
        });
        if (contains) {
          plansWithCropSeasons.push(data);
        }
      } else if (data.cropSeasons?.length === 0) {
        plansWithNoCropSeasons.push(data);
      }
      return plansWithCropSeasons;
    });
    if (cs.seasonId === '-1') {
      groups.push({
        id: cs.seasonId,
        name: cs.name,
        plans: plansWithNoCropSeasons
      });
    } else {
      groups.push({
        id: cs.seasonId,
        name: cs.name,
        plans: plansWithCropSeasons
      });
    }
    return groups;
  });
  return groups;
};

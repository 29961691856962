import { flatten, intersection } from 'lodash';
import {
  booleanEqual,
  area,
  convertArea,
  featureCollection,
  getCoords,
  getGeom,
  multiPolygon,
  bbox,
  polygon,
  union
} from '@turf/turf';
import geoViewport from '@mapbox/geo-viewport';

/**
 * find the intersection between the ids array and the features array by id
 * @param ids {Array} - array of id strings
 * @param features {Array} - array of feature objects that all have an id property
 * @returns {Array} - array of intersecting ids
 */
const findIdFeatureIntersections = (ids, features) =>
  intersection(
    features.map(({ id }) => id),
    ids
  );

/**
 * determine if the left list ids match the right list ids
 * @param leftList {Array} - left list of items
 * @param rightList {Array} - right list of items
 * @param intersectFunc {Function} - type-appropriate function that can determine the intersection of the left and right lists
 * @returns {boolean} - whether the left list matches the right list
 */
export const idsMatch = (leftList, rightList, intersectFunc = intersection) => {
  if (!leftList && !rightList) {
    return true;
  }
  if (!leftList || !rightList) {
    return false;
  }
  if (leftList.length === 0 && rightList.length === 0) {
    return true;
  }
  if (leftList.length !== rightList.length) {
    return false;
  }
  // if the 2 lists are the same non-zero length, they match if their intersection is also the same length
  const idIntersections = intersectFunc(leftList, rightList);
  return idIntersections.length === leftList.length;
};

/**
 * determine if the features array contains items with ids that match the ids array
 * @param ids {Array} - array of id strings
 * @param features {Array} - array of feature objects that all have an id property
 * @returns {boolean} - whether the ids array matches the features array by id
 */
export const idsMatchFeatures = (ids, features) =>
  idsMatch(ids, features, findIdFeatureIntersections);

const layerPropertyMatches = (aFeature, bFeature) =>
  aFeature?.properties?.$layer === bFeature?.properties?.$layer;

/**
 * calculate whether 2 arrays of features are equal
 * @param aList - an array of features
 * @param bList - an array of features
 * @returns {boolean|*}
 */
export const featuresMatch = (aList, bList) => {
  if (!aList || !bList) {
    return !aList && !bList;
  }
  if (aList.length !== bList.length) {
    return false;
  }
  return aList.reduce(
    (areEqual, aItem, index) =>
      areEqual &&
      booleanEqual(aItem, bList[index]) &&
      layerPropertyMatches(aItem, bList[index]),
    true
  );
};

export const calculateFeaturesTotalAreaInAcres = (features = []) =>
  convertArea(area(featureCollection(features)), 'meters', 'acres');

export const convertFeaturesToBaseGeometry = features => {
  const resultFeature = multiPolygon(features.map(getCoords));
  return getGeom(resultFeature);
};

export const mappingShape = feature =>
  feature.geometry.type === 'MultiPolygon'
    ? {
        ...feature,
        geometry: {
          type: 'Polygon',
          coordinates: flatten(feature.geometry.coordinates)
        }
      }
    : feature;

export const filterCropZonesSelected = (fc, parentId) =>
  fc.features.map(feat => {
    let newFeat = { ...feat };

    if (
      parentId === feat.properties?.id ||
      (parentId === feat.properties?.propertyId &&
        !feat.properties?.$parentDuplicated)
    ) {
      newFeat.properties.$layer = 'selected';
      newFeat.id = parentId;

      newFeat = mappingShape(newFeat);
    } else {
      newFeat.properties.$layer = 'default';
    }

    return newFeat;
  });

export const findGeoJSON = (fieldsAndAreasGeoJSONCollection, fieldOrCropZone) =>
  fieldsAndAreasGeoJSONCollection?.features?.find(
    property => property.properties.id === fieldOrCropZone.id
  );

// return the updated bounding box if it has changed, otherwise return null
export const getUpdatedBoundingBox = (boundingBox, fc, focusedFlag) => {
  if (!focusedFlag && boundingBox) {
    return null;
  }
  const newBounds = bbox(fc);
  const intersect = intersection(boundingBox, newBounds);

  return intersect.length === newBounds?.length ? null : newBounds;
};

export const getZoomByBounds = fitBounds => {
  const { clientWidth, clientHeight } = document.getElementById(
    'map-wrapper-container'
  );
  const { zoom } = geoViewport.viewport(
    fitBounds,
    [clientWidth - 240, clientHeight],
    undefined,
    undefined,
    undefined,
    true
  );

  return zoom;
};

export const handleOverlappedCoordinates = coordinates => {
  const mappedShapes = coordinates.map(coordinate => polygon(coordinate));

  const joinedShapes = union(...mappedShapes);

  return joinedShapes.geometry.type === 'MultiPolygon'
    ? joinedShapes.geometry.coordinates
    : [joinedShapes.geometry.coordinates];
};

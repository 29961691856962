import Yield from 'screens/Yield';

import { YieldIcon } from 'routes/icons';
import { paths } from 'routes/paths';
import YieldParentProvider from 'screens/Yield/components/YieldParentProvider';

const routes = [
  {
    id: 'yield',
    key: 'yield',
    label: 'Yield',
    path: paths.yield,
    exact: true,
    secure: true,
    disabled: false,
    component: Yield,
    icon: YieldIcon,
    nav: 'leftNav'
  },
  {
    id: 'create-yield',
    key: 'create-yield',
    label: '',
    path: `${paths.yield}/create`,
    exact: true,
    secure: true,
    disabled: true,
    component: YieldParentProvider
  },
  {
    id: 'edit-yield',
    key: 'edit-yield',
    label: '',
    path: `${paths.yield}/:id/edit`,
    exact: true,
    secure: true,
    disabled: true,
    component: YieldParentProvider
  }
];

export default routes;

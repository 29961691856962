import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Drawer } from 'syngenta-digital-cropwise-react-ui-kit';
import { Button, Spinner } from '@agconnections/grow-ui';
import usePopulateMapData from 'hooks/usePopulateMapData';
import { FETCH_TYPES } from 'helpers/constants';
import {
  getTotalAreaFromFeatures,
  getTotalAreaNumber
} from 'helpers/getTotalAreaAcres';
import getSeasons from 'screens/Yield/components/YieldActions/helpers/getSeasons';
import SearchInputLandingProperties from 'screens/Property/PropertiesLanding/components/SearchInputLandingProperties';
import { Context } from 'components/Store';
import FieldIcon from 'assets/field_filled.svg';
import Divider from 'assets/divider.svg';
import InfoIcon from 'assets/info.svg';
import CropIconMapping from 'components/CropIconMapping';
import useCropData from 'hooks/useCropData';
import { SEARCH_TEXT_VALUE_FFT } from 'reducers/reducer';
import PropertyMapWrapper from 'screens/Property/PropertiesLanding/PropertyLandingMap/PropertyMapWrapper';
import { LAST_COORDINATES_STORED } from 'screens/Property/PropertiesLanding/helpers/constants';
import { calculateCentroidByEachProperties } from 'screens/Property/helpers/mapApiHelpers';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { getStringParsed } from 'utilities/helpers';
import matchFeaturesAndSelectedCropzones from '../../helpers/matchFeaturesAndSelectedCropzones';
import PropertySelector from '../PropertySelector';
import searchByTextWithinFFT from '../../helpers/searchByTextWithinFFT';
import './index.css';
import { filterPropertiesCropZones } from '../../helpers/filterCropZonesHelpers';

const SelectPropertiesDrawer = ({ open, onClose, type }) => {
  const seasonIds = getSeasons();
  const { setFieldValue, values } = useFormikContext();
  const [{ cropSeasons, searchTextValueFFT }, dispatch] = useContext(Context);
  const [properties, setProperties] = useState([]);
  const [cropZonesChecked, setCropZonesChecked] = useState(
    values?.cropZones || []
  );
  const amplitude = useContext(AmplitudeContext);

  const [manualCoords, setManualCoords] = useState(
    getStringParsed(localStorage.getItem(LAST_COORDINATES_STORED))
  );

  const { crops } = useCropData(values?.cropId);

  const {
    loading,
    dataSorted: propertyLandingPageData,
    fieldsAndAreasGeoJSONCollection,
    setGeoJSONCollectionFeatures,
    zoom,
    setZoom,
    fieldsLoading,
    filterFeatureCollectionByParentId,
    reloadData
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.cropSeasonsMap,
    cropSeasonsId: seasonIds,
    isCacheOn: true
  });

  const geoJSONCentroid = useMemo(() => {
    return JSON.stringify(
      calculateCentroidByEachProperties(
        fieldsAndAreasGeoJSONCollection,
        properties
      )
    );
  }, [fieldsAndAreasGeoJSONCollection, properties]);

  const mapCoordinates = useMemo(
    () => manualCoords || JSON.parse(geoJSONCentroid)?.geometry?.coordinates,
    [geoJSONCentroid, manualCoords]
  );

  useEffect(() => {
    reloadData({ seasonIds });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && !cropZonesChecked?.length) {
      const firstValidProperty = properties?.find(prop =>
        prop?.fields?.some(field => field?.cropzones?.length > 0)
      );
      if (firstValidProperty) {
        setGeoJSONCollectionFeatures(
          filterFeatureCollectionByParentId(
            fieldsAndAreasGeoJSONCollection,
            firstValidProperty.id
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, cropZonesChecked, properties]);

  useEffect(() => {
    const filteredProperties = filterPropertiesCropZones(
      propertyLandingPageData.properties,
      seasonIds,
      values?.cropId
    );

    setProperties(filteredProperties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyLandingPageData, values?.cropId]);

  useEffect(() => {
    if (!loading && cropZonesChecked?.length) {
      setGeoJSONCollectionFeatures(
        filterFeatureCollectionByParentId(
          fieldsAndAreasGeoJSONCollection,
          undefined,
          cropZonesChecked.map(id => ({ id }))
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, cropZonesChecked]);

  const totalArea = useMemo(() => {
    return getTotalAreaNumber(
      matchFeaturesAndSelectedCropzones(
        fieldsAndAreasGeoJSONCollection,
        cropZonesChecked
      )
    );
  }, [cropZonesChecked, fieldsAndAreasGeoJSONCollection]);

  const filteredProperties = useMemo(() => {
    return searchByTextWithinFFT(properties, searchTextValueFFT.valueInput);
  }, [properties, searchTextValueFFT]);

  const toggleCropZonesSelection = (prevSelection, targets) => {
    const ids = targets.map(({ id }) => id);
    const anyNotSelected = ids.some(id => !prevSelection.includes(id));
    if (anyNotSelected) {
      return [...new Set([...prevSelection, ...ids])];
    }
    return prevSelection.filter(id => !ids.includes(id));
  };

  const onSelectFromMap = useCallback(event => {
    (event?.features || []).forEach(clicked => {
      if (clicked?.properties?.$landType === 'field') {
        const targets = JSON.parse(clicked?.properties?.cropzones);
        if (targets?.length > 0) {
          setCropZonesChecked(prev => toggleCropZonesSelection(prev, targets));
        }
      }
    });
  }, []);

  const cropSeasonHandler = () =>
    cropSeasons
      .filter(cropSeason => getSeasons().includes(cropSeason.id))
      .map(season => season.name)
      .join(', ');

  const onSelectionApply = () => {
    setFieldValue('cropZones', cropZonesChecked);
    setFieldValue('totalArea', totalArea);
    const matchedCropzones = matchFeaturesAndSelectedCropzones(
      fieldsAndAreasGeoJSONCollection,
      cropZonesChecked
    );
    const mappedCropZones = matchedCropzones.map(cropzone => ({
      id: cropzone.properties.id,
      area: getTotalAreaFromFeatures([cropzone])
    }));
    setFieldValue('sources', mappedCropZones);
    setFieldValue('sourceType', 'CROP_ZONE');
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.YieldV2.addYield.saveProperties,
      { numberOfCrops: mappedCropZones.length }
    );
    onClose();
  };

  return (
    <Drawer
      width="80%"
      open={open}
      onClose={onClose}
      bodyStyle={{ overflow: 'hidden' }}
      className="select-properties-drawer"
    >
      {loading || fieldsLoading ? (
        <Spinner />
      ) : (
        <div>
          <header className="flex flex-row mb-4">
            <div className="w-1/3 mr-6">
              <h1 className="font-semibold text-xl mb-1">Select Properties</h1>
              <p className="text-left text-sm font-normal leading-5 tracking-tighter text-neutral-60">
                (choose from list or map)
              </p>
            </div>

            <div className="w-2/3">
              <div className="font-normal text-base">
                Crop Season: {cropSeasonHandler()}
              </div>
              <div className="flex flex-row mt-1">
                <div className="mr-1">
                  {`${cropZonesChecked.length} crop zone${
                    cropZonesChecked.length === 1 ? '' : 's'
                  } selected`}
                </div>
                <img className="mx-2" src={Divider} alt="divider icon" />
                <img className="mx-1" src={FieldIcon} alt="field icon" />
                <div>Total area: {totalArea.toFixed(2)} ac</div>
              </div>
            </div>
          </header>

          <div className="flex flex-row">
            <section className="flex flex-col w-1/3 mr-6 tree-left-panel">
              {type === 'Load' && (
                <div className="flex flex-col gap-y-2 mb-4">
                  <div className="text-base flex items-center gap-x-1">
                    Crop: <CropIconMapping cropObject={crops} />
                    {crops?.name}
                  </div>
                  <div className="flex text-xs">
                    <img className="mr-1" src={InfoIcon} alt="info icon" />
                    <span>
                      Only properties matching the selected crop are listed
                    </span>
                  </div>
                </div>
              )}
              <SearchInputLandingProperties
                drawer
                loadDetailsDrawer
                onChange={event => {
                  const valueInput = event.target.value.toLowerCase();
                  dispatch({
                    type: SEARCH_TEXT_VALUE_FFT,
                    payload: { valueInput }
                  });
                }}
                value={searchTextValueFFT?.valueInput}
                placeholder="Search Properties"
              />
              <div className="font-semibold text-base mt-4">PROPERTIES</div>
              <div className="flex-1 overflow-scroll">
                <PropertySelector
                  key={`selector-${JSON.stringify(filteredProperties)}`}
                  properties={filteredProperties}
                  cropZonesChecked={cropZonesChecked}
                  setCropZonesChecked={setCropZonesChecked}
                  setGeoJSONCollectionFeatures={setGeoJSONCollectionFeatures}
                  fieldsAndAreasGeoJSONCollection={
                    fieldsAndAreasGeoJSONCollection
                  }
                  fieldsLoading={fieldsLoading}
                  filterFeatureCollectionByParentId={
                    filterFeatureCollectionByParentId
                  }
                />
              </div>
            </section>

            <section className="w-2/3">
              <PropertyMapWrapper
                key={`map-${JSON.stringify(filteredProperties)}`}
                fieldsLoading={fieldsLoading}
                geoJSONCollection={fieldsAndAreasGeoJSONCollection}
                zoom={zoom}
                setZoom={setZoom}
                currentCoordinates={mapCoordinates}
                setCurrentCoordinates={setManualCoords}
                onClickShape={onSelectFromMap}
                customStyles={cropZonesChecked.length > 0}
                stretch
                liveUpdate
                displayOnly
              />
            </section>

            <footer className="absolute bottom-0 w-full right-0 left-0 flex justify-end items-center border-t border-neutral-20 p-2 gap-3">
              <Button ghost type="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={onSelectionApply}
                disabled={!cropZonesChecked?.length}
              >
                Save Selection
              </Button>
            </footer>
          </div>
        </div>
      )}
    </Drawer>
  );
};

SelectPropertiesDrawer.defaultProps = {
  type: ''
};

SelectPropertiesDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string
};

export default SelectPropertiesDrawer;

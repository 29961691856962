import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Form, Spinner, TextArea } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { labelTitles, LabelInputs } from '../../helpers/labels';
import ColumnWithFieldsLeft from './ColumnWithFieldsLeft';
import ColumnWithFieldsRight from './ColumnWithFieldsRight';
import FooterButtons from './FooterButtons';

const FormStructureSaleLocation = ({
  formInitialValues,
  schemaValidation,
  _handleSubmit,
  handleCancel,
  isLoadingForm,
  labelSavingForm,
  isLoadingCompanies,
  companies
}) => {
  const divider = width => <div className={width} />;
  return (
    <div className="w-98/100 h-96/100 flex flex-col bg-white m-auto rounded-md shadow-card">
      <Formik
        initialValues={formInitialValues}
        validationSchema={schemaValidation}
        onSubmit={_handleSubmit}
      >
        {({ handleSubmit, values }) => (
          <>
            {isLoadingForm ? (
              <div className="h-full w-full flex flex-col justify-center items-center">
                <div>
                  <Spinner size="lg" />
                </div>
                <span className="mt-4 text-base font-semibold">
                  {labelSavingForm}
                </span>
              </div>
            ) : (
              <>
                <div className="flex flex-col p-4 w-full h-full pt-8">
                  <div className="mx-auto w-180">
                    <span className="text-3xl font-semibold">Basic Info</span>
                  </div>
                  <div className="flex flex-row justify-center">
                    <ColumnWithFieldsLeft
                      labelTitles={labelTitles}
                      LabelInputs={LabelInputs}
                      divider={divider}
                    />
                    {divider('w-8')}
                    <ColumnWithFieldsRight
                      labelTitles={labelTitles}
                      LabelInputs={LabelInputs}
                      divider={divider}
                      isLoadingCompanies={isLoadingCompanies}
                      companies={companies}
                    />
                  </div>
                  <div
                    className="w-full h-10 mx-auto"
                    style={{ maxWidth: '96rem' }}
                  >
                    <Form.Field
                      label={labelTitles.notes}
                      labelFor={LabelInputs.notes}
                    >
                      <GrowUIFormField
                        control={TextArea}
                        id={LabelInputs.notes}
                        name={LabelInputs.notes}
                        style={{ height: '100px', width: '100%' }}
                        editable
                      />
                    </Form.Field>
                  </div>
                </div>
                <FooterButtons
                  handleSubmit={handleSubmit}
                  handleCancel={handleCancel}
                  isDisabled={values.name !== ''}
                  divider={divider}
                />
              </>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

FormStructureSaleLocation.propTypes = {
  formInitialValues: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    addressLineOne: PropTypes.string,
    city: PropTypes.string,
    phone: PropTypes.string,
    company: PropTypes.string,
    addressLineTwo: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    notes: PropTypes.string
  }).isRequired,
  schemaValidation: PropTypes.objectOf(PropTypes.object).isRequired,
  _handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isLoadingForm: PropTypes.bool.isRequired,
  isLoadingCompanies: PropTypes.bool.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  labelSavingForm: PropTypes.string.isRequired
};

export default FormStructureSaleLocation;

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Popup, Tag, TagCloud } from '@agconnections/grow-ui';
import Accordion from 'components/Accordion';
import { capitalizeName } from 'helpers/textHelpers';
import { taskListViewFormat } from 'helpers/dateFormat';
import { ReactComponent as ApplicationIcon } from 'assets/Type=application.svg';
import { ReactComponent as HarvestIcon } from 'assets/Type=harvest.svg';
import { ReactComponent as PlantingIcon } from 'assets/Type=planting.svg';
import { ReactComponent as Info } from 'assets/info.svg';
import { ReactComponent as TillageIcon } from 'assets/Type=tillage.svg';
import { ReactComponent as ObservationIcon } from 'assets/Type=observation.svg';
import { ReactComponent as IrrigationIcon } from 'assets/Type=irrigation.svg';
import { ReactComponent as MissingIcon } from 'assets/missing_default.svg';
import { convertM2ToAc } from 'helpers/convertM2ToAc';
import FieldIcon from 'screens/Property/components/FieldIcon';
import { CropZoneMatcherContext } from '../../context/CropZoneMatcherProvider';
import getAllCheckBoxes from '../../helpers/getAllCheckBoxes';

const ALIASES = {
  JOHN_DEERE: 'JOHN DEERE'
};

const SourceOperationListItem = ({
  isCheckedAllInstances,
  operation,
  selectedField
}) => {
  const operationIconTable = {
    HARVEST: <HarvestIcon />,
    PLANTING: <PlantingIcon />,
    APPLICATION: <ApplicationIcon />,
    TILLAGE: <TillageIcon />,
    OBSERVATION: <ObservationIcon />,
    IRRIGATION: <IrrigationIcon />,
    MISSING: <MissingIcon />
  };
  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
  const {
    getOperationId,
    removeOperationId,
    operationReset,
    operationIds,
    fieldsGeometries
  } = useContext(CropZoneMatcherContext);

  const handleUncheck = () => {
    const chBoxes = getAllCheckBoxes();
    chBoxes.forEach(item => {
      if (!item.checked) {
        removeOperationId(item.dataset.operationid);
      }
    });
  };

  const handleCheckOperation = e => {
    e.stopPropagation();
    setIsCheckBoxChecked(prev => !prev);
    getOperationId(operation?.id);
    handleUncheck();
  };

  const selectAllCheckBoxes = () => {
    const chBoxes = getAllCheckBoxes();
    chBoxes.forEach(item => {
      getOperationId(item.dataset.operationid);
    });
  };

  useEffect(() => {
    if (!selectedField) return;
    if (isCheckedAllInstances) {
      setIsCheckBoxChecked(true);
      selectAllCheckBoxes();
      return;
    }
    if (getAllCheckBoxes().length === operationIds.length) {
      setIsCheckBoxChecked(false);
      operationReset();
      return;
    }
    if (
      operationIds.length &&
      selectedField.operations.find(opt => opt.id === operationIds[0])
    ) {
      setIsCheckBoxChecked(true);
      selectAllCheckBoxes();
      return;
    }
    setIsCheckBoxChecked(false);
    operationReset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckedAllInstances]);

  const header = () => {
    const operationType = operation.operation_types[0];
    return (
      <div
        data-testid={`source-operation-${operation.id}`}
        className="h-40px w-670px flex flex-row items-center pl-4"
      >
        <Checkbox
          onClick={handleCheckOperation}
          checked={isCheckBoxChecked}
          id="checked-items"
          data-operationId={operation?.id}
        />
        <div className="ml-4">
          {operationIconTable[operationType]
            ? operationIconTable[operationType]
            : operationIconTable.MISSING}
        </div>
        <span className="ml-4">
          {capitalizeName(operation.operation_types[0])}
        </span>
        <span className={`${isCheckBoxChecked ? 'font-semibold' : ''} ml-4`}>
          {taskListViewFormat(operation.start_date_time)}
        </span>
        {operation?.aliases && (
          <div className="ml-auto">
            <TagCloud>
              {operation.aliases
                ?.filter(
                  alias => alias?.system_type && ALIASES[alias.system_type]
                )
                ?.map(alias => (
                  <Tag key={alias.system_type} color="#696F88">
                    {ALIASES[alias.system_type]}
                  </Tag>
                ))}
            </TagCloud>
          </div>
        )}
      </div>
    );
  };

  const content = () => {
    const acCalc = convertM2ToAc(operation?.task_measures[0]?.value);
    return (
      <div className="flex flex-row" style={{ minHeight: '200px' }}>
        <div style={{ width: '360px' }}>
          <span
            className="h-24px w-82px text-sm rounded text-white font-semibold px-2 py-1 "
            style={{
              backgroundColor: '#868CA2'
            }}
          >
            Boundary
          </span>
          <div className="flex flex-row mt-2">
            <div className="mr-2 pt-6px">
              <svg
                width="12"
                height="16"
                viewBox="0 0 12 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 2C8.5125 2 10.5 3.9275 10.5 6.65C10.5 8.405 9.0375 10.73 6 13.505C2.9625 10.73 1.5 8.405 1.5 6.65C1.5 3.9275 3.4875 2 6 2ZM12 6.65C12 2.915 9.15 0.5 6 0.5C2.85 0.5 0 2.915 0 6.65C0 9.14 2.0025 12.0875 6 15.5C9.9975 12.0875 12 9.14 12 6.65ZM4.5 6.5C4.5 7.325 5.175 8 6 8C6.825 8 7.5 7.325 7.5 6.5C7.5 5.675 6.825 5 6 5C5.175 5 4.5 5.675 4.5 6.5Z"
                  fill="#696F88"
                />
              </svg>
            </div>
            <div>
              <span className="font-normal text-sm ">
                {operation.farms[0].description}
              </span>
              <br />
              <span className="font-normal text-sm">
                {selectedField?.fieldName}
              </span>
            </div>
          </div>
          <div className="flex flex-row mt-4 items-center">
            <div
              className="rounded "
              style={{
                width: '108px',
                height: '58px',
                backgroundColor: '#F3F4F6'
              }}
            >
              {fieldsGeometries?.get(selectedField?.fieldId) && (
                <FieldIcon
                  id={selectedField?.fieldId}
                  features={[
                    {
                      type: 'Feature',
                      geometry: fieldsGeometries?.get(selectedField?.fieldId)
                    }
                  ]}
                  height={58}
                  width={108}
                  color="#A3A9B9"
                  className="flex-shrink-0"
                />
              )}
            </div>
            <p className="ml-6 text-sm">
              <span className="text-neutral-50 font-normal">Total Area</span>
              <br />
              <span className="flex flex-row font-semibold text-base">
                {acCalc.toFixed(2)} ac{' '}
                {acCalc.toFixed(2) <= 0.0 ? (
                  <div className="mt-1">
                    <Popup
                      enableHover
                      position="top"
                      align="center"
                      hasArrow
                      bgColor="#232630"
                      trigger={
                        <div className="pl-1">
                          <Info />
                        </div>
                      }
                    >
                      <div className="text-xs text-white">{acCalc} ac</div>
                    </Popup>
                  </div>
                ) : null}
              </span>
            </p>
          </div>
        </div>
        <div>
          <span
            className="h-24px w-82px text-sm rounded text-white font-semibold px-2 py-1 "
            style={{
              backgroundColor: '#868CA2'
            }}
          >
            Details
          </span>
          <div className="flex flex-col mt-2">
            <div className="flex flex-row">
              <div className="mr-2" style={{ paddingTop: '6px' }}>
                <svg
                  width="16"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 2.25H13.25V0.75H11.75V2.25H4.25V0.75H2.75V2.25H2C1.175 2.25 0.5 2.925 0.5 3.75V15.75C0.5 16.575 1.175 17.25 2 17.25H14C14.825 17.25 15.5 16.575 15.5 15.75V3.75C15.5 2.925 14.825 2.25 14 2.25ZM14 15.75H2V7.5H14V15.75ZM2 6H14V3.75H2V6Z"
                    fill="#696F88"
                  />
                </svg>
              </div>
              <div>
                <p className="font-normal text-sm mt-1">
                  <span className="text-neutral-50 font-normal mr-2">
                    Start:
                  </span>
                  {taskListViewFormat(operation.start_date_time)}
                </p>
                <p className="font-normal text-sm">
                  <span className="text-neutral-50 font-normal mr-2">End:</span>
                  {taskListViewFormat(operation.end_date_time)}
                </p>
              </div>
            </div>
            <div className="flex flex-row mt-4 items-center">
              <svg
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.75 6.55L9 0.25L17.25 6.55L16.3425 7.735L15 6.715V13.75H3V6.715L1.6575 7.7425L0.75 6.55ZM13.5 12.25H12.75V9.25H11.25V6.25H6.75V9.25H5.25V12.25H4.5V5.575L9 2.14L13.5 5.575V12.25ZM6.75 10.75V12.25H8.25V10.75H6.75ZM9.75 10.75V12.25H11.25V10.75H9.75ZM9.75 9.25V7.75H8.25V9.25H9.75Z"
                  fill="#696F88"
                />
              </svg>
              <p className="ml-2">
                <span className="text-neutral-50 font-normal mr-2">
                  Products:
                </span>
                {operation.products.map(product => (
                  <div key={product.id}>
                    <span className="font-normal text-sm" key={product.id}>
                      {product.description}
                    </span>
                  </div>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Accordion header={header()} content={content()} headerHeight="40px" />
  );
};

SourceOperationListItem.propTypes = {
  operation: PropTypes.shape().isRequired,
  selectedField: PropTypes.shape().isRequired,
  isCheckedAllInstances: PropTypes.shape().isRequired
};

export default SourceOperationListItem;

import { createContext } from 'react';

export default createContext({
  propertyType: '',
  name: '',
  productsAndServices: [],
  cropHistory: [],
  revenue: [],
  variety: [],
  actiObject: {},
  id: '',
  enableEditing: 'false'
});

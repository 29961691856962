/* eslint-disable react/forbid-prop-types */
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { Card, Spinner } from '@agconnections/grow-ui';
import { withRouter } from 'react-router-dom';
import { featureCollection } from '@turf/helpers';
// import { MapContext, GeoJSONLayer } from 'react-mapbox-gl';

// import { centroid } from '@turf/turf';

import ReactMapboxGl from 'react-mapbox-gl';
import mapboxgl, { GeolocateControl } from 'mapbox-gl';
import DrawControl from 'react-mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { parseClientError } from 'helpers/errorHelpers';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './map.css';

import DeleteModal from 'components/Modals/DeleteModal';
import { Context } from 'components/Store';
import coordinatesGeocoder from 'screens/Property/PropertiesLanding/PropertyLandingMap/PropertyMapWrapper/PropertyMap/utilities/coordinatesGeocoder';
import useMapMouseListeners from './hooks/useMapMouseListeners';
import useDrawHandlers from './hooks/useDrawHandlers';

import {
  DEFAULT_FLY_TO_OPTIONS,
  DEFAULT_ZOOM,
  UPDATE_TYPES,
  MAP_HISTORY_FEATURE_TYPES,
  MIN_ZOOM,
  MAX_ZOOM,
  USA_CENTER_COORDINATES
} from '../../../helpers/constants';
import drawStyles from '../../../helpers/drawStyles';
import {
  getFeatureCollectionsFromGeoJSONItems,
  handleShapeDelete,
  handleShapeSubtract,
  handleShapeSplit
} from '../../../helpers/mapApiHelpers';
import PropertyContext from '../../PropertyMapContext';
import MapKeyPressTool from '../MapKeyPressTool';
import PropertyFeatureLayer from '../PropertyFeatureLayer';
import GrisLayer, { GRIS_TYPES } from '../GrisLayer';

import DrawTools from './DrawTools';
import ObjectMarkerLabel from './ObjectMarkerLabel';

const AccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = ReactMapboxGl({
  accessToken: AccessToken,
  minZoom: MIN_ZOOM,
  maxZoom: MAX_ZOOM
});

// eslint-disable-next-line react/prop-types
const CardWrapper = ({ noCard, children }) =>
  noCard ? (
    <div className="relative flex-grow w-full">{children}</div>
  ) : (
    <Card>
      <Card.Content>
        <div className="relative w-full h-80">{children}</div>
      </Card.Content>
    </Card>
  );

const filterSelectedFeatures = features =>
  features.filter(
    ({ properties }) =>
      properties?.$layer === 'selected' && !(properties.$noEdit ?? false)
  );
const filterUnselectedFeatures = collection => {
  return featureCollection(
    collection.features.filter(
      ({ properties }) =>
        properties?.$layer !== 'selected' || !!properties.$noEdit
    )
  );
};

const markFeaturesSelected = (features, layerType = 'selected') =>
  features.map(({ properties, ...feature }) => ({
    ...feature,
    properties: { ...properties, $layer: layerType }
  }));

const isADrawingMode = mode => ['draw_polygon', 'drag_circle'].includes(mode);
const isASelectMode = mode => ['select_only', 'simple_select'].includes(mode);

const PropertyMap = ({
  currentCoordinates,
  setCurrentCoordinates,
  displayOnly,
  geoJSONCollection,
  customStyles,
  handleDrawCreate,
  handleDrawUpdate,
  handleDrawDelete,
  liveUpdate,
  // markerOn,
  mode,
  modes,
  setMode,
  modeReset,
  zoom,
  setZoom,
  stretch,
  properties,
  fieldsLoading,
  labelName
}) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [overlay, setOverlay] = useState();
  const { onMapMouseMoved, onMapMouseDown } = useMapMouseListeners(mode);

  const onEditScreen = !displayOnly;
  const geocoder = new MapboxGeocoder({
    accessToken: AccessToken,
    collapsed: true,
    localGeocoder: coordinatesGeocoder,
    placeholder: 'ZIP, Address, Lat/Long',
    mapboxgl
  });
  const geolocateControl = new GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: true
  });

  const [, dispatch] = useContext(Context);
  // const map = useContext(MapContext);

  const {
    selectedShapeIds,
    setSelectedShapeIds,
    newFeatures,
    mapChangeEvent,
    archiveMapHistoryFeature
  } = useContext(PropertyContext);

  const drawControlRef = useRef(null);
  const lastMapChangeEvent = useRef(new Event('init ref event'));
  const featureIdBeingSplit = useRef();
  const deletedFeatureIds = useRef([]);

  const geoJSONFeatureCollections = getFeatureCollectionsFromGeoJSONItems(
    geoJSONCollection.features
  );

  // eslint-disable-next-line no-unused-vars
  const [mapCenterCoordinates, setMapCenterCoordinates] = useState(
    currentCoordinates
  );

  // const filteredGeoJSON = geoJSONCollection.features.filter(eachFeatures => {
  //   return (
  //     Object.prototype.hasOwnProperty.call(
  //       eachFeatures.properties,
  //       'cropzones'
  //     ) &&
  //     eachFeatures.properties.cropzones.length > 0 &&
  //     eachFeatures
  //   );
  // });

  const showDrawControls = !displayOnly || mode === 'select_only';

  const displayOnlyGeoJSONCollection = useMemo(
    () =>
      onEditScreen
        ? filterUnselectedFeatures(geoJSONCollection)
        : featureCollection(geoJSONCollection?.features || []),
    [onEditScreen, geoJSONCollection]
  );

  if (drawControlRef.current && showDrawControls && onEditScreen) {
    geoJSONFeatureCollections.forEach(({ features = [] }) => {
      const filteredFeatures = filterSelectedFeatures(features).map(
        feature => ({
          id: feature.properties.id,
          description: feature.properties.name,
          ...feature
        })
      );
      drawControlRef.current.draw.add(featureCollection(filteredFeatures));
    });
  }

  const inDisplayOnlyJSON = useCallback(
    feature =>
      displayOnlyGeoJSONCollection.features.find(
        ({ id: _id }) => _id === feature.id
      ),
    [displayOnlyGeoJSONCollection]
  );

  const flyToOptions = DEFAULT_FLY_TO_OPTIONS;

  // does the heavy lifting of replacing a new feature with a new instance of the feature with proper id
  const processNewFeature = useCallback(
    (newFeature, callback) => {
      if (newFeature.id && liveUpdate) {
        // Delete the feature with autogenerated id so user can't update wrong feature
        drawControlRef.current.draw.delete(newFeature.id);
      }

      const callbackResults = callback(newFeature);

      if (liveUpdate) drawControlRef.current.draw.add(newFeature); // Insert the feature with the correct id

      return callbackResults;
    },
    [liveUpdate]
  );

  const { onDrawChanged } = useDrawHandlers(
    handleDrawCreate,
    handleDrawUpdate,
    processNewFeature,
    setDeleteModalOpen
  );

  const handleDelete = () => {
    const currentMode = drawControlRef.current.draw.getMode();
    if (currentMode === 'direct_select') {
      drawControlRef.current.draw.trash();
    } else if (currentMode === 'simple_select') {
      setDeleteModalOpen(true);
    }
  };

  const handlePolygonDelete = () => {
    const deletedIds = handleShapeDelete(drawControlRef.current);
    if (!deletedIds) {
      parseClientError(dispatch)('You must select a shape to delete');
    } else {
      handleDrawDelete(deletedIds);
      setSelectedShapeIds([]);
    }
    setDeleteModalOpen(false);
  };

  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleSelectionChange = () =>
    setSelectedShapeIds(drawControlRef.current.draw.getSelectedIds());

  const handleSubtract = async () => {
    const results = handleShapeSubtract(drawControlRef.current);
    if (!results) {
      parseClientError(dispatch)(
        'You must select a shape completely contained by the other selected shape'
      );
    } else {
      const { added, deleted } = results;
      await handleDrawDelete(deleted);
      setSelectedShapeIds([]);
      processNewFeature(added, newFeature => {
        handleDrawCreate([newFeature]);
      });
    }
  };

  const handleSplitStart = () => {
    // eslint-disable-next-line prefer-destructuring
    featureIdBeingSplit.current = selectedShapeIds[0];
    setMode('draw_line_string');
  };

  const handleSplit = async lineFeature => {
    const { added, deleted } = handleShapeSplit(
      drawControlRef.current,
      featureIdBeingSplit.current,
      lineFeature
    );
    featureIdBeingSplit.current = null;
    modeReset();
    if (!added) {
      parseClientError(dispatch)(
        'The split could not be performed as requested.'
      );
    } else {
      deletedFeatureIds.current = deletedFeatureIds.current.concat(deleted);
      const addedFeatures = markFeaturesSelected(added.features);

      handleDrawCreate(addedFeatures, deleted);
    }
  };

  const handleImportShape = useCallback(
    ({ feature }) => {
      // processNewFeature(feature, newFeature => {
      //   handleDrawCreate([newFeature]);
      // });
      //
      drawControlRef.current.draw.add(feature); // Insert the feature with the correct id
      const addedFeatures = markFeaturesSelected([feature], 'focused');
      handleDrawCreate(addedFeatures);
    },
    [handleDrawCreate]
  );

  const handleCreate = draw => {
    const currentMode = drawControlRef.current.draw.getMode();
    if (currentMode === 'draw_line_string') {
      // only using the draw line mode for the split command
      const [lineFeature] = draw.features;
      handleSplit(lineFeature);
    } else {
      const tmpFeatures = onEditScreen
        ? markFeaturesSelected(draw.features)
        : draw.features;
      onDrawChanged(tmpFeatures, UPDATE_TYPES.CREATE);
      modeReset();
    }
  };

  // detects when changes are made to the includeOnMaps property in geoJSONCollection
  useEffect(() => {
    // We should pass only as a geoJSONCollection in the future as a prop to LandMap in this way
    if (
      !displayOnly &&
      geoJSONCollection?.features?.length &&
      drawControlRef.current
    ) {
      const drawnFeatures = drawControlRef.current.draw.getAll();
      if (geoJSONCollection?.features?.length) {
        geoJSONCollection.features.forEach(feature => {
          // do not include features that have been explicitly excluded or permanently deleted
          const includeOnMaps =
            (!feature.properties ||
              feature.properties.includeOnMaps !== false) &&
            !deletedFeatureIds.current.includes(feature.id);
          const drawnFeature = drawnFeatures.features.find(drawn => {
            return drawn.id === feature.id;
          });

          if (includeOnMaps && !drawnFeature && !inDisplayOnlyJSON(feature)) {
            // add feature if not drawn but should be included on map
            drawControlRef.current.draw.add(feature);
          } else if (!includeOnMaps && drawnFeature) {
            // remove feature if drawn but should not be included on map
            drawControlRef.current.draw.delete(drawnFeature.id);
          }
        });
      }
    }
  }, [geoJSONCollection, displayOnly, inDisplayOnlyJSON]);

  useEffect(() => {
    if (isASelectMode(mode) && mapLoaded && drawControlRef.current?.draw) {
      drawControlRef.current.draw.changeMode(mode, {
        featureIds: selectedShapeIds || []
      });
    }
  }, [mode, mapLoaded, selectedShapeIds]);

  useEffect(() => {
    if (
      !displayOnly &&
      mode !== 'select_only' &&
      mapLoaded &&
      drawControlRef.current?.draw &&
      drawControlRef.current.draw.getMode() !== mode
    ) {
      drawControlRef.current.draw.changeMode(mode);
    }
  }, [mode, mapLoaded, drawControlRef, displayOnly]);

  // process any new features added to the mapHistory, then archive it
  // mapChangeEvent only changes when new features are ready to be processed
  useEffect(() => {
    if (
      mapChangeEvent.timeStamp !== lastMapChangeEvent.current.timeStamp &&
      newFeatures.length > 0
    ) {
      lastMapChangeEvent.current = mapChangeEvent;
      newFeatures.forEach(newFeature => {
        if (newFeature.featureType === MAP_HISTORY_FEATURE_TYPES.IMPORT) {
          handleImportShape(newFeature);
          archiveMapHistoryFeature(newFeature);
        }
        if (newFeature.featureType === MAP_HISTORY_FEATURE_TYPES.ADD) {
          const addedFeatures = markFeaturesSelected([newFeature.feature]);
          handleDrawCreate(addedFeatures);
          archiveMapHistoryFeature(newFeature);
        }
        if (newFeature.featureType === MAP_HISTORY_FEATURE_TYPES.REMOVE) {
          handleDrawDelete([newFeature.feature.id]);
          archiveMapHistoryFeature(newFeature);
        }
      });
    }
  }, [
    mapChangeEvent,
    newFeatures,
    handleImportShape,
    handleDrawCreate,
    handleDrawDelete,
    archiveMapHistoryFeature
  ]);

  useEffect(() => {
    setMapCenterCoordinates(currentCoordinates);
  }, [currentCoordinates]);

  // useEffect(() => {
  //   if (currentCoordinates === USA_CENTER_COORDINATES) {
  //     setUSCenter(true);
  //   } else {
  //     setUSCenter(false);
  //   }
  // }, [currentCoordinates, USA_CENTER_COORDINATES]);
  return (
    <div
      data-testid="land-map"
      className={stretch ? 'flex-grow flex-col flex' : ''}
    >
      <CardWrapper noCard={stretch}>
        <DrawTools
          modeSelected={setMode}
          mode={mode}
          onDelete={handleDelete}
          onSubtract={handleSubtract}
          onSplit={handleSplitStart}
          setCurrentCoordinates={setCurrentCoordinates}
          setZoom={setZoom}
          overlay={overlay}
          setOverlay={setOverlay}
        />

        <Map
          // eslint-disable-next-line react/style-prop-object
          style="mapbox://styles/mapbox/satellite-streets-v11"
          zoom={zoom}
          center={USA_CENTER_COORDINATES}
          flyToOptions={flyToOptions}
          containerStyle={{
            height: '100%',
            width: '100%'
          }}
          // fitBounds={mapCenterCoordinates}
          onStyleLoad={mapReference => {
            mapReference.addControl(geocoder, 'bottom-right');
            mapReference.addControl(geolocateControl, 'bottom-right');
            setMapLoaded(true);
          }}
          onMouseMove={(map, event) => {
            if (!displayOnly && drawControlRef.current?.draw) {
              const currentMode = drawControlRef.current.draw.getMode();
              if (isADrawingMode(currentMode))
                onMapMouseMoved(event, drawControlRef);
            }
          }}
          onMouseDown={(map, event) => {
            if (!displayOnly && drawControlRef.current?.draw) {
              const currentMode = drawControlRef.current.draw.getMode();
              if (isADrawingMode(currentMode)) onMapMouseDown(event);
            }
          }}
        >
          <PropertyFeatureLayer
            geoJSON={displayOnlyGeoJSONCollection}
            customStyles={customStyles}
            setZoom={setZoom}
            visible={displayOnly || onEditScreen}
          />
          {overlay === 'CLU' && (
            <GrisLayer
              type={GRIS_TYPES.CLU}
              setZoom={setZoom}
              geoJSONCollection={geoJSONCollection}
              onClose={() => setOverlay(null)}
            />
          )}
          {overlay === 'SAT' && (
            <GrisLayer
              type={GRIS_TYPES.SATELLITE}
              setZoom={setZoom}
              geoJSONCollection={geoJSONCollection}
              onClose={() => setOverlay(null)}
            />
          )}

          {showDrawControls && (
            <DrawControl
              ref={drawControl => {
                drawControlRef.current = drawControl;
              }}
              onDrawCreate={handleCreate}
              onDrawDelete={draw => {
                onDrawChanged(draw.features, UPDATE_TYPES.DELETE);
              }}
              onDrawUpdate={draw => {
                onDrawChanged(draw.features, UPDATE_TYPES.UPDATE);
              }}
              onDrawSelectionChange={handleSelectionChange}
              defaultMode="simple_select"
              displayControlsDefault={false}
              modes={modes}
              styles={drawStyles}
            />
          )}
          {!fieldsLoading ? (
            <ObjectMarkerLabel
              geoCollection={geoJSONCollection}
              activeObjectType={labelName}
              propertyLandingPageData={properties}
            />
          ) : (
            <Spinner />
          )}
          {/* {!fieldsLoading ? (
            filteredGeoJSON.map(eachFeatures => {
              const centroidCalc = centroid(eachFeatures.geometry);
              return (
                <Marker
                  coordinates={centroidCalc.geometry.coordinates}
                  style={{ color: '#ecf1f2', fontWeight: 'bolder' }}
                >
                  {eachFeatures.properties.name}
                </Marker>
              );
            })
          ) : (
            <Spinner />
          )} */}

          <MapKeyPressTool onChange={onDrawChanged} />
        </Map>
        <DeleteModal
          open={deleteModalOpen && !displayOnly}
          itemType="Shape"
          onDelete={handlePolygonDelete}
          onCancel={handleCloseDeleteModal}
        />
      </CardWrapper>
    </div>
  );
};

PropertyMap.defaultProps = {
  currentCoordinates: null,
  setCurrentCoordinates: () => {},
  displayOnly: false,
  geoJSONCollection: null,
  customStyles: null,
  handleDrawCreate: () => {},
  handleDrawUpdate: () => {},
  handleDrawDelete: () => {},
  // markerOn: false,
  liveUpdate: true,
  history: null,
  zoom: DEFAULT_ZOOM,
  setZoom: () => {},
  stretch: false,
  labelName: '',
  properties: {}
};

PropertyMap.propTypes = {
  currentCoordinates: PropTypes.arrayOf(PropTypes.number),
  setCurrentCoordinates: PropTypes.func,
  displayOnly: PropTypes.bool,
  geoJSONCollection: PropTypes.object,
  customStyles: PropTypes.objectOf(
    PropTypes.shape({
      fillColor: PropTypes.string,
      fillOutlineColor: PropTypes.string,
      fillAntialias: PropTypes.bool
    })
  ),
  handleDrawCreate: PropTypes.func,
  handleDrawUpdate: PropTypes.func,
  handleDrawDelete: PropTypes.func,
  mode: PropTypes.string.isRequired,
  modeReset: PropTypes.func.isRequired,
  modes: PropTypes.object.isRequired,
  setMode: PropTypes.func.isRequired,
  liveUpdate: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  zoom: PropTypes.arrayOf(PropTypes.number),
  setZoom: PropTypes.func,
  stretch: PropTypes.bool,
  labelName: PropTypes.string,
  fieldsLoading: PropTypes.bool.isRequired,
  properties: PropTypes.arrayOf(PropTypes.object)
};

export default React.memo(withRouter(PropertyMap));

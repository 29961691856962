import { cloneDeep, orderBy } from 'lodash';

export default (values, field, direction) => {
  if (!direction) return values;

  const _values = values.map(product => cloneDeep(product));
  return orderBy(
    _values,
    [
      product => {
        const val = product?.[field];
        if (typeof val === 'string') return val.trim().toLowerCase();
        return val;
      }
    ],
    direction
  );
};

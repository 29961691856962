/* eslint-disable no-else-return */
import React, { useContext, useEffect, useState, useCallback } from 'react';
import {
  Columns,
  Column,
  Spinner,
  Select,
  TagCloud,
  Tag,
  Table,
  TextArea,
  Toast
} from '@agconnections/grow-ui';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { task as taskApi } from 'utilities/api';
import {
  onlyTimeFormat,
  standardFormat,
  taskDetailViewFormat
} from 'helpers/dateFormat';
import {
  createEventHandlerWithTransform,
  getEventSelectedKey
} from 'utilities/formHelpers';
import PropTypes from 'prop-types';
import ViewOnlyField from 'components/ViewOnlyField';
import WeatherCard from 'components/WeatherCard';
import {
  TagsCloudDisplay,
  extraTagsCalculation,
  TagsPopUpMessage
} from 'components/TagsButton';
import { STORAGE_KEYS } from 'utilities/localStorage';
import usePopulateMapData from 'hooks/usePopulateMapData';
import { FETCH_TYPES } from 'helpers/constants';
import ListWithPopup from 'components/ListWithPopup';
import StatusTag from '../../../../../screens/Tasks/components/StatusTag';
import ProductsTableRow from './components/ProductsTableRow';
import PropertyTableRow from './components/PropertyTableRow';

const windDirections = [
  { key: 'N', value: 0 },
  { key: 'NNE', value: 22.5 },
  { key: 'NE', value: 45 },
  { key: 'ENE', value: 67.5 },
  { key: 'E', value: 90 },
  { key: 'ESE', value: 112.5 },
  { key: 'SE', value: 135 },
  { key: 'SSE', value: 157.5 },
  { key: 'S', value: 180 },
  { key: 'SSW', value: 202.5 },
  { key: 'SW', value: 225 },
  { key: 'WSW', value: 247.5 },
  { key: 'W', value: 270 },
  { key: 'WNW', value: 292.5 },
  { key: 'NW', value: 315 },
  { key: 'NNW', value: 337.5 }
];

const TaskView = ({ location, id }) => {
  const cropSeasonsToInclude = location.cropSeasons.map(
    cropSeason => cropSeason.id
  );
  const {
    dataSorted: propertyDt,
    fieldsAndAreasGeoJSONCollection,
    reloadData
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.cropSeasonsMap,
    cropSeasonsId: cropSeasonsToInclude
  });

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const propertyData = propertyDt;
  const [
    {
      loggedInUserOrgPermission: {
        role: memberRole,
        financialAccess: memberFinancialAccess = 'none'
      }
    },
    dispatch
  ] = useContext(Context);
  const [task, setTask] = useState({});
  const [loading, setLoading] = useState(true);
  const [applicationType, setApplicationType] = useState(null);
  const [updatedTags, setUpdatedTags] = useState([]);
  const [rowsData, setRowsData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [toastRenderContents, setToastRenderContents] = useState(null);
  const [toastHasNotBeenClosed, setToastHasNotBeenClosed] = useState(true);

  const [displayTagsObject, setDisplayTagsObject] = useState({
    extraTagsCount: 0,
    extraTagsNames: [],
    tagsToDisplay: []
  });

  useEffect(() => {
    setDisplayTagsObject(extraTagsCalculation(updatedTags, 20));
  }, [updatedTags]);

  const getApplicationTypeLabel = type => {
    let label = 'Rate/Tank';
    if (type === 'ratePerAreaValue') {
      label = 'Rate/Area';
    } else if (type === 'totalProductValue') {
      label = 'Total Product';
    }
    return label;
  };

  const handleFilterChange = (event, value) => {
    setApplicationType(value);
  };
  const handleSelect = createEventHandlerWithTransform(
    handleFilterChange,
    getEventSelectedKey
  );

  const getDirection = windDirection => {
    const wind = windDirections.find(
      direction => direction.key === windDirection
    );
    return wind;
  };
  const itemsApplicationStrategy = [
    {
      key: 'ratePerAreaValue',
      value: 'By Rate/Area Application',
      display: 'ByRatePerArea'
    },
    {
      key: 'totalProductValue',
      value: 'By Total Product Application',
      display: 'ByTotalProduct'
    },
    {
      key: 'ratePerTankValue',
      value: 'By Rate/Tank Application',
      display: 'ByRatePerTank'
    }
  ];
  const applicationStrategyFilter = strategy => {
    if (strategy === itemsApplicationStrategy[0].display) {
      return itemsApplicationStrategy[0].key;
    } else if (strategy === itemsApplicationStrategy[1].display) {
      return itemsApplicationStrategy[1].key;
    } else if (strategy === itemsApplicationStrategy[2].display) {
      return itemsApplicationStrategy[2].key;
    } else {
      return itemsApplicationStrategy[0].key;
    }
  };

  // Load tasks
  useEffect(() => {
    const { promise } = taskApi.fetch(id);
    promise
      .then(({ data }) => {
        if (data) {
          setTask(data);
          setUpdatedTags(data.flags);
          setApplicationType(
            applicationStrategyFilter(data.applicationStrategy)
          );
        }
        setLoading(false);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const fillRows = useCallback(async () => {
    const rowDataObjects = [];
    /* eslint no-unused-expressions: "off", curly: "error" */
    propertyData?.properties?.forEach(propr => {
      /* eslint no-unused-expressions: "off", curly: "error" */
      propr?.fields?.forEach(field => {
        /* eslint no-unused-expressions: "off", curly: "error" */
        field?.cropzones?.forEach(zone => {
          /* eslint no-unused-expressions: "off", curly: "error" */
          task?.properties?.forEach(tProp => {
            if (zone?.id === tProp?.cropZoneId) {
              const row = {
                farmId: tProp?.farmId,
                cropZoneId: zone?.id,
                fieldName: field?.name,
                cropZoneName: zone?.name,
                cropName: zone?.crop?.name,
                areaValue: tProp?.areaValue,
                areaUnit: tProp?.areaUnit,
                boundaryArea: zone?.boundaryArea,
                reportedArea: zone?.reportedArea,
                coveragePercent: tProp?.coveragePct,
                features: fieldsAndAreasGeoJSONCollection.features?.filter(
                  feature => field.id === feature.properties.id
                )
              };
              rowDataObjects.push(row);
            }
          });
        });
      });
    });
    return rowDataObjects;
  }, [
    propertyData?.properties,
    task?.properties,
    fieldsAndAreasGeoJSONCollection?.features
  ]);
  useEffect(() => {
    if (propertyData?.properties?.length > 0 && task?.taskId?.length > 0) {
      fillRows().then(data => {
        setRowsData(data);
      });
    }
  }, [fillRows, propertyData, task]);

  return (
    <>
      {toastHasNotBeenClosed ? (
        <Toast
          icon="error"
          onClose={() => {
            setToastHasNotBeenClosed(false);
          }}
        >
          {toastRenderContents}
        </Toast>
      ) : null}
      {!loading && rowsData !== undefined ? (
        <>
          <div
            className="min-w-content border rounded"
            data-testid="tasks-table"
            // style={{ minWidth: 'min-content' }}
          >
            <div>
              <div className="m-4">
                <div data-testid="task-details">
                  <div className="flex justify-between pt-4 mb-8">
                    <div className="text-2xl font-bold">Task Details</div>
                  </div>
                  <Columns>
                    <Column>
                      <div className="mb-6">
                        <ViewOnlyField
                          header="Task ID"
                          data={task.name}
                          testId="taskId"
                        />
                        <div className="w-32 text-center">
                          <StatusTag status={task.status} />
                        </div>
                      </div>
                    </Column>
                  </Columns>
                  <Columns>
                    <Column width="w-120">
                      <div className="mb-6">
                        <ViewOnlyField
                          header="Start Date/Time"
                          data={taskDetailViewFormat(task.startDate)}
                          testId="startDateTime"
                        />
                      </div>
                    </Column>
                    <Column width="w-56">
                      <div className="flex items-center w-full h-full">
                        <svg
                          width="53"
                          height="16"
                          viewBox="0 0 53 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M52.7071 8.7071C53.0976 8.31658 53.0976 7.68341 52.7071 7.29289L46.3431 0.928928C45.9526 0.538404 45.3195 0.538404 44.9289 0.928928C44.5384 1.31945 44.5384 1.95262 44.9289 2.34314L50.5858 8L44.9289 13.6569C44.5384 14.0474 44.5384 14.6805 44.9289 15.0711C45.3195 15.4616 45.9526 15.4616 46.3431 15.0711L52.7071 8.7071ZM8.74228e-08 9L52 9L52 7L-8.74228e-08 7L8.74228e-08 9Z"
                            fill="#696F88"
                          />
                        </svg>
                      </div>
                    </Column>
                    <Column width="w-120">
                      <div className="mb-6">
                        <ViewOnlyField
                          header="End Date/Time"
                          data={taskDetailViewFormat(task.dueDate)}
                          testId="endDateTime"
                        />
                      </div>
                    </Column>
                  </Columns>
                  <Columns>
                    <Column width="w-1/3">
                      <div className="mb-6">
                        <ViewOnlyField
                          header="Authorization By"
                          data={task.authorizedBy}
                          testId="authorizedBy"
                        />
                      </div>
                      <div className="mb-6">
                        <ViewOnlyField
                          header="Timing Event"
                          data={task.timingEvent}
                          testId="timingEvent"
                        />
                      </div>
                      <div className="mb-6">
                        <div data-testid="cropSeason">
                          <div className="text-gray-600">Crop Season</div>
                          <div className="text-2xl">
                            <TagCloud>
                              {task.cropSeasonIds.map(cz => (
                                <Tag>{cz.name}</Tag>
                              ))}
                            </TagCloud>
                          </div>
                        </div>
                      </div>
                    </Column>
                    <Column width="w-1/3">
                      <div className="mb-6">
                        <ViewOnlyField
                          header="Authorization Date/Time"
                          data={
                            task.authorizedDate
                              ? taskDetailViewFormat(task.authorizedDate)
                              : '-'
                          }
                          testId="authorizationDateTime"
                        />
                      </div>
                      <div className="mb-6">
                        <ViewOnlyField
                          header="Duration"
                          data={task.duration ? `${task.duration} hours` : ''}
                          testId="duration"
                        />
                      </div>
                      <div className="mb-6">
                        <div data-testid="flags">
                          <div className="text-gray-600">Tags</div>
                          <div className="mt-1">
                            {displayTagsObject.extraTagsNames.length > 0 ? (
                              <ListWithPopup
                                position="bottom"
                                ariaLabel="Task Tags"
                                top="1.5rem"
                                msg={
                                  <TagsPopUpMessage
                                    extraTagsNames={
                                      displayTagsObject.extraTagsNames
                                    }
                                  />
                                }
                                component={
                                  <TagsCloudDisplay
                                    displayTagsObject={displayTagsObject}
                                    isLandingPage="view"
                                    viewKey={STORAGE_KEYS.TASKS_VIEW}
                                  />
                                }
                              />
                            ) : (
                              <TagsCloudDisplay
                                displayTagsObject={displayTagsObject}
                                isLandingPage="view"
                                viewKey={STORAGE_KEYS.TASKS_VIEW}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Column>
                    <Column width="w-1/3">
                      <div className="mb-6">
                        <ViewOnlyField
                          header="Task Type"
                          data={task.type}
                          testId="taskType"
                        />
                      </div>
                      <div className="mb-6">
                        <ViewOnlyField
                          header="Created on"
                          data={taskDetailViewFormat(task.createdAt)}
                          testId="createdOn"
                        />
                      </div>
                      <div className="mb-6">
                        <div data-testid="source">
                          <div className="text-gray-600">Source</div>
                          <div className="text-2xl">
                            {task?.sources && (
                              <TagCloud>
                                {task?.sources.map(tag => (
                                  <Tag>{tag.name}</Tag>
                                ))}
                              </TagCloud>
                            )}
                          </div>
                        </div>
                      </div>
                    </Column>
                  </Columns>
                </div>
                <hr className="my-6" />
                <div data-testid="applicator-section">
                  <div className="flex justify-between pt-4 mb-8">
                    <div className="text-2xl font-bold">Applicator(s)</div>
                  </div>
                  <Columns wrap>
                    {task?.applicators &&
                      task?.applicators?.map((applicator, index) => {
                        return (
                          <Column width={`w-full ${index > 0 ? 'mt-6' : ''}`}>
                            <div className="flex">
                              <div className="w-4/12">
                                <ViewOnlyField data={applicator.personName} />
                                <div className="text-gray-600">
                                  {`${
                                    applicator.licenseNumber
                                      ? applicator.licenseNumber
                                      : ''
                                  } ${
                                    applicator.expiresDate
                                      ? `Exp. ${standardFormat(
                                          applicator.expiresDate
                                        )}`
                                      : ''
                                  } `}
                                </div>
                              </div>
                              <ViewOnlyField
                                header="Company"
                                data={applicator.companyName}
                              />
                            </div>
                          </Column>
                        );
                      })}
                    <div className="w-4/12 mt-6">
                      <ViewOnlyField
                        header="Buffer Zone"
                        data={task.bufferZone ? `${task.bufferZone} FT` : null}
                        testId="bufferZone"
                      />
                    </div>
                    <div className="mt-6">
                      <ViewOnlyField
                        header="Buffer Reason"
                        data={task.bufferReason}
                        testId="pollenDrift"
                      />
                    </div>
                  </Columns>
                </div>
                <hr className="my-6" />
                <div data-testid="properties-section" className="mb-6">
                  <div className="flex justify-between pt-4 mb-8">
                    <div className="text-2xl font-bold">Properties</div>
                  </div>
                  <Table>
                    <Table.Header>
                      <Table.Cell width="10%" />

                      <Table.Cell width="25%">
                        Fields ({task?.properties?.length})
                      </Table.Cell>
                      <Table.Cell>Crops</Table.Cell>
                      <Table.Cell>Area</Table.Cell>
                      <Table.Cell width="15%">Coverage %</Table.Cell>
                    </Table.Header>
                    {rowsData.map(row => (
                      <PropertyTableRow property={row} />
                    ))}
                  </Table>
                </div>
                <div data-testid="products-services-section" className="mb-6">
                  <div className="flex justify-between pt-4 mb-8">
                    <div>
                      <div className="text-2xl font-bold">
                        Products & Services
                      </div>
                      <Select
                        value={applicationType}
                        onChange={handleSelect}
                        items={itemsApplicationStrategy}
                      />
                    </div>
                  </div>
                  <Table>
                    <Table.Header>
                      <Table.Cell>
                        {`Products (${task.products?.length})`}
                      </Table.Cell>
                      <Table.Cell>
                        {getApplicationTypeLabel(applicationType)}
                      </Table.Cell>
                      <Table.Cell>Appl. Area</Table.Cell>
                      <Table.Cell>Appl. Area %</Table.Cell>
                      <Table.Cell>Total Product</Table.Cell>
                      <Table.Cell>Total Cost</Table.Cell>
                    </Table.Header>
                    {task.products.map(product => (
                      <ProductsTableRow
                        product={product}
                        applicationType={applicationType}
                        memberRole={memberRole}
                        memberFinancialAccess={memberFinancialAccess}
                      />
                    ))}
                  </Table>
                </div>
                <div data-testid="weather-section">
                  <div className="flex justify-between pt-4 mb-8">
                    <div className="text-2xl font-bold">Weather</div>
                  </div>
                  <div className="flex">
                    {task?.weather?.map(weather => (
                      <div className="mr-6">
                        <WeatherCard
                          isStart={weather.type === 'START'}
                          date={standardFormat(weather?.date)}
                          time={onlyTimeFormat(weather?.date)}
                          windSpeed={weather?.windSpeed}
                          windDirection={
                            getDirection(weather?.windDirection)?.value
                          }
                          skyCondition={weather?.skyCondition}
                          temperature={weather?.temperature}
                          soilMoisture={weather?.soilMoisture}
                          humidity={weather?.humidity}
                          speedUnit={weather?.windSpeedUnit}
                          tempUnit={weather?.temperatureUnit}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div data-testid="notes-section">
                  <div className="flex justify-between pt-4 mb-8">
                    <div className="text-2xl font-bold">Notes</div>
                  </div>
                  <TextArea
                    data-testid="taskNotes"
                    className="h-64 overscroll-auto"
                    resizeDisabled
                    disabled
                    value={task.notes}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

TaskView.propTypes = {
  location: PropTypes.shape({
    cropSeasons: PropTypes.arrayOf(PropTypes.shape()),
    state: {
      propertyData: PropTypes.object,
      memberRole: PropTypes.string,
      memberFinancialAccess: PropTypes.string
    }
  }),
  id: PropTypes.string.isRequired
};

TaskView.defaultProps = {
  location: null
};

export default TaskView;

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Popup } from '@agconnections/grow-ui';

const ListWithPopup = ({ position, ariaLabel, top, delay, msg, component }) => {
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const onMouseEnter = () =>
    setHoverTimeout(setTimeout(() => setShowPopup(true), delay));

  const onMouseLeave = () => clearTimeout(hoverTimeout) || setShowPopup(false);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="gr-text-left"
    >
      <div className="relative" aria-label={ariaLabel}>
        <div className="absolute" style={{ top }}>
          <Popup
            position={position}
            open={showPopup}
            hasArrow
            // BUG: without a trigger Popup will not render
            trigger={<div />}
          >
            <div className="whitespace-no-wrap">{msg}</div>
          </Popup>
        </div>
      </div>
      <div>{component}</div>
    </div>
  );
};

export default ListWithPopup;

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@agconnections/grow-ui';
import { useLocation, useParams } from 'react-router-dom';
import useInventoryDocumentationData from '../../hooks/useInventoryDocumentationData';
import ProductDetailsTableHeader, {
  PRODUCT_DETAIL_TABLES
} from '../ProductDetailTableHeader';
import ProductDetailDocumentation from '../ProductDetailDocumentation';
import ProductDetailREIPHI from '../ProductDetailREIPHI';

const ProductDetailTabs = ({ product }) => {
  const location = useLocation();
  const { id: productId } = useParams();
  const {
    dataDocumentation,
    isLoading: docsLoading,
    openFile,
    error: docsError
  } = useInventoryDocumentationData(productId);

  const [cropSeasons, setCropSeasons] = useState([]);
  const [dateRange, setDateRange] = useState(null);

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const TableComponent =
    PRODUCT_DETAIL_TABLES[queryParams.get('table')] ??
    PRODUCT_DETAIL_TABLES.invoices;

  const displayDocsTab =
    dataDocumentation?.documentdescriptions?.length &&
    !product.custom &&
    !docsLoading &&
    !docsError;

  return (
    <div className="h-full overflow-hidden px-4 pb-4">
      <Tabs
        pane
        className="flex flex-col h-full w-full"
        contentClassName="h-full overflow-hidden"
      >
        <Tabs.Tab label="Inventory">
          <ProductDetailsTableHeader
            cropSeasons={cropSeasons}
            onChangeCropSeasons={setCropSeasons}
            onChangeDateRange={setDateRange}
            dateRange={dateRange}
          />
          <TableComponent cropSeasons={cropSeasons} dateRange={dateRange} />
        </Tabs.Tab>

        {displayDocsTab ? (
          <Tabs.Tab label="Documentation">
            <ProductDetailDocumentation
              documentation={dataDocumentation}
              openFile={openFile}
            />
          </Tabs.Tab>
        ) : null}
        <Tabs.Tab label="REI/PHI">
          <ProductDetailREIPHI />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
};

ProductDetailTabs.propTypes = {
  product: PropTypes.shape({
    custom: PropTypes.bool
  }).isRequired
};

export default ProductDetailTabs;

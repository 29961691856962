import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Spinner, Tabs } from '@agconnections/grow-ui';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { Context } from 'components/Store';
import Toast from 'components/Toast';
import { FETCH_TYPES } from 'helpers/constants';
import usePopulateMapData from 'hooks/usePopulateMapData';
import useInventoryTasksData from 'screens/ProductList/ProductDetails/hooks/useInventoryTasksData';
import useInventoryDocumentationData from 'screens/ProductList/ProductDetails/hooks/useInventoryDocumentationData';

import { paths } from 'routes/paths';
import useInventoryData from '../helpers/useInventoryData';
import useREIPHIData from '../helpers/useREIPHIData';
import useInventoryInvoiceData from '../helpers/useInventoryInvoiceData';
import Inventory from './Inventory/index';
import Documentation from './Documentation';
import REIPHI from './REIPHI';

const ProductInventoryDetails = () => {
  const { id } = useParams();
  const [{ isGlobalCropSeasonLoading }] = useContext(Context);
  const { products, loading } = useInventoryData();
  const { reiphiData, reiphiLoading, saveREIPHI, deleteREIPHI } = useREIPHIData(
    id
  );
  const [redirect, setRedirect] = useState(null);
  const [error, setError] = useState(null);
  const [localREIPHIData, setLocalREIPHIData] = useState(reiphiData);
  const { tasks, tasksLoading } = useInventoryTasksData(id);
  const { invoices, invsLoading } = useInventoryInvoiceData(id);
  const {
    dataDocumentation,
    isLoading: docsLoading,
    openFile,
    error: docsError
  } = useInventoryDocumentationData(id);
  const { dataSorted: propertyData, reloadData } = usePopulateMapData({
    fetchType: FETCH_TYPES.cropSeasonsMap,
    isCacheOn: true
  });
  const showSpinner = () => {
    return (
      isGlobalCropSeasonLoading ||
      invsLoading ||
      tasksLoading ||
      docsLoading ||
      reiphiLoading ||
      loading
    );
  };
  const selectedProduct = useMemo(() => {
    const prodData = products.find(
      prod => prod.productId === id || prod.id === id
    );

    return {
      ...prodData,
      productName: prodData?.name || prodData?.productName,
      manufacturer: prodData?.manufacturer
    };
  }, [products, id]);

  const updateREIPHIData = updatedREIPHI => {
    // this is to avoid going back to the api to get the current data as that would force the page to load again and move off of the reiphi tab
    if (updatedREIPHI) {
      const clonedData = [...localREIPHIData];
      const elementIndex = localREIPHIData.findIndex(
        reiphi => reiphi.cropId === updatedREIPHI.cropId
      );
      if (elementIndex > -1) {
        // eslint-disable-next-line no-param-reassign
        delete updatedREIPHI.i;
        clonedData[elementIndex] = updatedREIPHI;
      } else {
        clonedData.push(updatedREIPHI);
      }
      setLocalREIPHIData(clonedData);
    }
  };

  useEffect(() => {
    if (reiphiData) setLocalREIPHIData(reiphiData);
  }, [reiphiData]);

  useEffect(() => {
    if (docsError) {
      setError(docsError);
    }
  }, [docsError]);

  const tabs = () => {
    return (
      <Tabs pane>
        <Tabs.Tab label="Inventory">
          <Inventory
            id={id}
            selectedProduct={selectedProduct}
            invoices={invoices}
            tasks={tasks}
            reloadData={reloadData}
            setRedirect={setRedirect}
          />
        </Tabs.Tab>
        <Tabs.Tab label="Documentation">
          {error && (
            <Toast icon="error" onClose={() => setError(null)}>
              {error}
            </Toast>
          )}
          <Documentation
            dataDocumentation={dataDocumentation}
            openFile={openFile}
          />
        </Tabs.Tab>
        <Tabs.Tab label="REI/PHI">
          <REIPHI
            dataREIPHI={localREIPHIData}
            dataDocumentation={dataDocumentation}
            productId={id}
            properties={propertyData.properties}
            saveREIPHI={saveREIPHI}
            deleteREIPHI={deleteREIPHI}
            updateREIPHI={updateREIPHIData}
          />
        </Tabs.Tab>
      </Tabs>
    );
  };
  return (
    <>
      {redirect && <Redirect push to={redirect} />}
      <div className="h-full w-full">
        <Breadcrumb hideCropSeasonDropdown>
          <Breadcrumb.Item title="Inventory" value="All" to={paths.inventory} />
          <Breadcrumb.Item
            title="Product"
            value={selectedProduct?.productName || selectedProduct?.name}
            isLast
          />
        </Breadcrumb>
        <div className="w-full h-full" data-testid="product-detail-page">
          <div className="flex py-4">
            <div>
              <div className="text-sm">Product Name</div>
              <div className="text-3xl">
                {selectedProduct?.productName || selectedProduct?.name}
              </div>
            </div>
            <div className="ml-24 mr-4">
              <div className="text-sm">Manufacturer</div>
              <div className="text-3xl">
                {selectedProduct?.manufacturerName ||
                  selectedProduct?.manufacturer}
              </div>
            </div>
          </div>
          <div className="w-full h-full py-6">
            <div className="w-full h-full">
              {showSpinner() ? <Spinner /> : tabs()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductInventoryDetails;

import React from 'react';
import PropTypes from 'prop-types';
import { Table, Spinner } from '@agconnections/grow-ui';

import FormattedNumber from 'components/FormattedNumber';
import useCropZoneData from 'screens/Property/hooks/useCropZoneData';
import useFieldData from 'screens/Property/hooks/useFieldData';
import FieldIcon from 'screens/Property/components/FieldIcon';

const PropertyTableRow = ({
  property,
  fieldsAndAreasGeoJSONCollection,
  fieldsLoading
}) => {
  const { cropZone } = useCropZoneData(property?.cropzoneId);
  const { field, loading } = useFieldData(cropZone?.fieldId);

  const getFieldIconFeatures = () => {
    if (fieldsAndAreasGeoJSONCollection.features.length > 0) {
      const fieldIconFeatures = [];
      fieldsAndAreasGeoJSONCollection.features.forEach(feature => {
        if (property.cropzoneId === feature.properties.id) {
          fieldIconFeatures.push(feature);
        }
      });
      return fieldIconFeatures;
    }
    return '';
  };

  return !loading && !fieldsLoading ? (
    <Table.Row key={property.cropzoneId}>
      <Table.Cell width="5rem">
        <>
          <FieldIcon
            id={property.cropzoneId}
            features={getFieldIconFeatures()}
          />
        </>
      </Table.Cell>
      <Table.Cell width="20rem">
        <span className="flex flex-col">
          <span
            className="w-64 pb-2 text-sm font-bold text-left truncate"
            data-testid="property-name"
          >
            {field?.name}
          </span>
          <span
            className="w-64 text-sm text-left text-gray-600 truncate"
            data-testid="property-manufacturer"
          >
            {property?.name}
          </span>
        </span>
      </Table.Cell>
      <Table.Cell>
        {property.crop.name}
        {property.crop.name && property.crop.variety && <> - </>}
        {property.crop.variety}
      </Table.Cell>
      <Table.Cell>
        <FormattedNumber roundedTo={2}>{property.area}</FormattedNumber>{' '}
        {property.area === 1 ? property.areaUnit : `${property.areaUnit}s`}
      </Table.Cell>
    </Table.Row>
  ) : (
    <Spinner />
  );
};

PropertyTableRow.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    crop: PropTypes.objectOf(PropTypes.string),
    area: PropTypes.number,
    areaUnit: PropTypes.string,
    cropzoneId: PropTypes.string
  }).isRequired,
  fieldsAndAreasGeoJSONCollection: PropTypes.shape({
    features: PropTypes.arrayOf()
  }),
  fieldsLoading: PropTypes.bool
};

PropertyTableRow.defaultProps = {
  fieldsAndAreasGeoJSONCollection: {
    features: []
  },
  fieldsLoading: true
};
export default PropertyTableRow;

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Select } from '@agconnections/grow-ui';
import { useHistory, useLocation } from 'react-router-dom';
import ProductDetailInvoiceTable from '../ProductDetailInvoiceTable';
import ProductDetailTasksTable from '../ProductDetailTasksTable';
import ProductDetailCropSeasonSelect from '../ProductDetailCropSeasonSelect';

export const PRODUCT_DETAIL_TABLES = {
  invoices: ProductDetailInvoiceTable,
  completedTasks: ProductDetailTasksTable,
  ready_tasks: () => <h1>Ready Tasks</h1>
};

const ProductDetailsTableHeader = ({
  cropSeasons,
  onChangeCropSeasons,
  onChangeDateRange,
  dateRange
}) => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const selectValue = PRODUCT_DETAIL_TABLES[queryParams.get('table')]
    ? queryParams.get('table')
    : 'invoices';

  const onChangeViewMode = useCallback(
    e => {
      const { key } = JSON.parse(e.target.value);
      const params = new URLSearchParams({ table: key });
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    },
    [history, location]
  );

  return (
    <section className="flex justify-between mb-4">
      <div className="flex items-center gap-4">
        <h2 className="text-xl font-semibold ml-4">Production Records</h2>
        <div className="w-52">
          <Select
            name="table"
            value={selectValue}
            items={[
              { key: 'invoices', value: 'Invoices' },
              { key: 'completedTasks', value: 'Completed Tasks' }
            ]}
            onChange={onChangeViewMode}
          />
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div className="w-82">
          <ProductDetailCropSeasonSelect
            onChange={onChangeCropSeasons}
            values={cropSeasons}
          />
        </div>
        <DatePicker
          dates={dateRange ?? undefined}
          onChange={e => onChangeDateRange(e.target.value)}
          isRangePicker
        />
      </div>
    </section>
  );
};

ProductDetailsTableHeader.propTypes = {
  cropSeasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeCropSeasons: PropTypes.func.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.any),
  onChangeDateRange: PropTypes.func.isRequired
};

ProductDetailsTableHeader.defaultProps = {
  dateRange: null
};

export default ProductDetailsTableHeader;

import React, { useContext } from 'react';
import PropTyps from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import { paths } from 'routes/paths';
import EmptyTableState from 'components/EmptyTableState';
import propertiesMap from 'assets/properties-map-icon.svg';
import emptySearch from 'assets/noNotesFound.png';
import { useHistory } from 'react-router-dom';
import { AddRounded } from '@material-ui/icons';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';

const EmptyView = ({
  hasNoCropsinSelectedSeason,
  hasNoLoadsForSelectedCrop
}) => {
  const history = useHistory();
  const amplitude = useContext(AmplitudeContext);
  if (hasNoCropsinSelectedSeason) {
    return (
      <EmptyTableState
        title="No Crop Zones Found"
        message="Create a crop zone to proceed with adding Yield Loads."
        overlayIcon={propertiesMap}
        button={
          <Button type="primary" onClick={() => history.push(paths.properties)}>
            Go To Properties
          </Button>
        }
        dataTestId="empty-view-no-crop-zones"
      />
    );
  }

  if (hasNoLoadsForSelectedCrop) {
    return (
      <EmptyTableState
        title="No Yield Data Found"
        message="Add a new Yield Load or change the selected season."
        overlayIcon={emptySearch}
        button={
          <Button
            icon={<AddRounded />}
            type="primary"
            onClick={() => {
              amplitude.sendEventToAmplitude(
                amplitude.events.epic.YieldV2.addYield.createYield
              );
              history.push(`${paths.yieldV2}/create`);
            }}
          >
            <span className="whitespace-no-wrap">Yield Loads</span>
          </Button>
        }
        dataTestId="empty-view-no-yield-loads"
      />
    );
  }

  return null;
};

EmptyView.propTypes = {
  hasNoCropsinSelectedSeason: PropTyps.bool.isRequired,
  hasNoLoadsForSelectedCrop: PropTyps.bool.isRequired
};

export default EmptyView;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import useCropSeasons from 'hooks/useCropSeasons';

import { Search } from '@agconnections/grow-ui';

import GrowUIFormField from 'components/Forms/GrowUIFormField';
import SeasonCreateModal from 'components/Modals/SeasonCreateModal';

const ADD_CROPSEASON_KEY = '__CREATE__';

const createCropSeasonItem = {
  key: ADD_CROPSEASON_KEY,
  value: '+ Create New Crop Season'
};

const CropZoneCropSeasonSelect = ({ cropSeasonId }) => {
  const { cropSeasons } = useCropSeasons();

  const { setFieldValue } = useFormikContext();

  const [cropSeasonModal, setCropSeasonModal] = useState(false);
  const [newCropSeason, setNewCropSeason] = useState(null);

  const cropSeasonItems = useMemo(() => {
    return [
      createCropSeasonItem,
      ...(cropSeasons?.map(cropSeason => ({
        key: cropSeason.id,
        value: cropSeason.name
      })) || [])
    ];
  }, [cropSeasons]);

  useEffect(() => {
    setFieldValue('cropSeasonId', cropSeasonId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropSeasonId]);

  useEffect(() => {
    const res = cropSeasonItems.find(item => item.key === newCropSeason?.id);

    if (res) {
      setFieldValue('cropSeasonId', res.key);
    }
  }, [newCropSeason, cropSeasonItems, setFieldValue]);

  const openCropSeasonModal = () => {
    setCropSeasonModal(true);
  };

  const closeCropSeasonModal = () => {
    setCropSeasonModal(false);
  };

  return (
    <>
      <SeasonCreateModal
        open={cropSeasonModal}
        onClose={closeCropSeasonModal}
        onSuccess={cropSeason => {
          setNewCropSeason(cropSeason);
        }}
      />
      <div className="-mt-8">
        <label className="text-neutral-600 text-sm inline-block mb-1 ">
          Crop Season*
        </label>
        <GrowUIFormField
          name="cropSeasonId"
          items={cropSeasonItems}
          control={Search}
          placeholder="Select Season"
          onSelect={cropSeason => {
            if (cropSeason.key === ADD_CROPSEASON_KEY) {
              setFieldValue('cropSeasonId', null);
              openCropSeasonModal();
            } else {
              setFieldValue('cropSeasonId', cropSeason.key);
            }
          }}
        />
      </div>
    </>
  );
};

CropZoneCropSeasonSelect.propTypes = {
  cropSeasonId: PropTypes.string
};

CropZoneCropSeasonSelect.defaultProps = {
  cropSeasonId: ''
};

export default CropZoneCropSeasonSelect;

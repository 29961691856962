import { useCallback, useContext, useState } from 'react';
import axios from 'axios';
import catchCancel from 'helpers/catchCancel';
import { Context } from 'components/Store';
import { parseServerError } from 'helpers/errorHelpers';
import {
  initialRequestState,
  onRequestEnd,
  onRequestFail,
  onRequestStart,
  onRequestSuccess
} from 'utilities/helpers/requestState';
import { fieldV2, cropZoneV2 } from 'utilities/api';
import { CROPWISE_PROXY_V2_URL } from 'utilities/apiConstants';
import { getAccessToken } from 'utilities/base-auth';
import {
  PROPERTY_TYPE_FIELD,
  PROPERTY_TYPE_CROP_ZONE
} from '../../helpers/constants';

const useCostAnalysis = () => {
  const [{ organization }, dispatch] = useContext(Context);
  const [contributionMargin, setContributionMargin] = useState(
    initialRequestState
  );

  const getContributionMargin = useCallback(
    async (params = {}) => {
      const {
        resourceId,
        level = PROPERTY_TYPE_CROP_ZONE,
        seasonIds,
        cropId = null
      } = params;
      setContributionMargin(curr => onRequestStart(curr));
      const isField = level === PROPERTY_TYPE_FIELD;

      // COST
      const costCropIdParam = cropId ? `?cropId=${cropId}` : '';
      const { promise: costPromise } = isField
        ? fieldV2
            .createChildApi({
              action: `field/${resourceId}/cost${costCropIdParam}`
            })
            .post({ seasonIds })
        : cropZoneV2
            .createChildApi({
              action: `cropzone/${resourceId}/cost`
            })
            .fetch();

      // REVENUE
      const cropSeasonIds = seasonIds
        .map(id => `cropSeasonIds[]=${id}`)
        .join('&');
      const revenueCropIdParam = cropId ? `&cropId=${cropId}` : '';
      const queryParams = isField
        ? `?${cropSeasonIds}${revenueCropIdParam}`
        : '';
      const revenuePromise = axios.get(
        `${CROPWISE_PROXY_V2_URL}/yield/summary/${level.toLowerCase()}/${resourceId}/revenue${queryParams}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'cwf-context': JSON.stringify({
              organization_id: organization?.id
            }),
            Authorization: `Bearer ${getAccessToken()}`
          }
        }
      );

      // CONTRIBUTION MARGIN
      Promise.allSettled([costPromise, revenuePromise])
        .then(([costResponse, revenueResponse]) => {
          if (
            costResponse.status === 'rejected' &&
            revenueResponse.status === 'rejected'
          ) {
            throw new Error('Unable to obtain contribution margin.');
          }

          const cost =
            costResponse.status === 'fulfilled' &&
            'totalCost' in costResponse.value.data
              ? costResponse.value.data
              : { totalCost: 0, costPerAcre: 0 };

          const revenue =
            revenueResponse.status === 'fulfilled' &&
            'totalRevenue' in revenueResponse.value.data
              ? revenueResponse.value.data
              : { totalRevenue: 0, avgRevenuePerAcre: 0 };

          setContributionMargin(curr =>
            onRequestSuccess(curr, { cost, revenue })
          );
        })
        .catch(catchCancel)
        .catch(error => {
          setContributionMargin(curr => onRequestFail(curr, error));
          parseServerError(dispatch);
        })
        .finally(() => {
          setContributionMargin(curr => onRequestEnd(curr));
        });
    },
    [dispatch, organization?.id]
  );

  return {
    contributionMargin,
    getContributionMargin
  };
};

export default useCostAnalysis;

import React from 'react';
import PropTypes from 'prop-types';

import PortalModal from 'components/Modals/PortalModal';

const ConfirmModal = ({ open, onCancel, onConfirm }) => {
  return (
    <PortalModal
      cancelLabel="No"
      close={onCancel}
      confirmLabel="Yes"
      icon={null}
      isSaving={false}
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={open}
      title="Confirm CLU Field"
      type="confirmation"
    >
      Do you want to create your field using the selected CLU shapes?
    </PortalModal>
  );
};

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default ConfirmModal;

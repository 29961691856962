import React, { useContext } from 'react';
import { Button, Table } from '@agconnections/grow-ui';
import { mappingShape } from 'screens/Property/helpers/propertyDataHelpers';

import FieldIcon from 'screens/Property/components/FieldIcon';
import DeleteIcon from 'components/Icons/components/DeleteIcon';
import MapIllustration from 'assets/properties-map-icon.svg';
import FormattedNumber from 'components/FormattedNumber';
import { IntegrationsContext } from 'screens/Integrations/context/IntegrationsProvider';

const PropertyListView = () => {
  const {
    state: { selectedProperties },
    updateSelectedProperties
  } = useContext(IntegrationsContext);

  const handleDeleteProperty = propertyId => {
    const selectedPropertiesLeft = selectedProperties.filter(
      property => property.properties.id !== propertyId
    );

    updateSelectedProperties(selectedPropertiesLeft);
  };

  return (
    <div className="h-full w-3/4" data-testid="property-list-view">
      <div className="flex flex-col w-full h-30 font-body">
        <Table>
          <Table.Header className="font-body">
            <Table.Cell width="30%">
              Fields ({selectedProperties.length})
            </Table.Cell>
            <Table.Cell>Crops</Table.Cell>
            <Table.Cell>Area</Table.Cell>
            <Table.Cell />
          </Table.Header>
          {selectedProperties.map(property => {
            const ft = [mappingShape(property)];

            return (
              <Table.Row key={property.properties.id}>
                <Table.Cell>
                  <div className="flex items-center">
                    <FieldIcon id="field-card-icon" features={ft} />
                    <div className="flex flex-col justify-start leading-5 text-left ml-3">
                      <span className="font-bold text-neutral-1000">
                        {property.properties.fieldName}
                      </span>
                      <span style={{ color: '#707374' }}>
                        {property.properties.name}
                      </span>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell>{property.properties.crop.name}</Table.Cell>
                <Table.Cell>
                  <FormattedNumber roundedTo={2}>
                    {property.properties.reportedArea ||
                      property.properties.boundaryArea}
                  </FormattedNumber>{' '}
                  ac
                </Table.Cell>
                <Table.Cell>
                  <div className="flex justify-end w-full pr-5">
                    <Button
                      type="text"
                      icon={
                        <DeleteIcon
                          color="#696F88"
                          width="24"
                          height="24"
                          widthBox="5"
                          heightBox="6"
                        />
                      }
                      onClick={() =>
                        handleDeleteProperty(property.properties.id)
                      }
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table>
        {!selectedProperties.length && (
          <div
            className="flex flex-col items-center self-stretch justify-center h-full py-32 border border-t-0"
            data-testid="empty-body-table"
          >
            <img src={MapIllustration} alt="No properties illustration" />
            <p>
              Select one or more fields from the list to add it to this Plan
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyListView;

import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import FieldIcon from 'screens/Property/components/FieldIcon';
import { mappingShape } from 'screens/Property/helpers/propertyDataHelpers';
import FormattedNumber from 'components/FormattedNumber';

const PropertyTableRow = ({ property }) => {
  const ft = property?.features?.[0]
    ? [mappingShape(property.features[0])]
    : undefined;

  return (
    <>
      <Table.Row>
        <Table.Cell>
          <FieldIcon id="view-rec-card-icon" features={ft} />
        </Table.Cell>
        <Table.Cell width="20rem">
          <div className="flex flex-col justify-start leading-5 text-left">
            <span className="font-bold text-neutral-1000">
              {property?.fieldName}
            </span>
            <span style={{ color: '#707374' }}>{property?.cropZoneName}</span>
          </div>
        </Table.Cell>
        <Table.Cell>{property?.cropName}</Table.Cell>
        <Table.Cell>
          <FormattedNumber roundedTo="2">{property?.areaValue}</FormattedNumber>{' '}
          {property?.areaUnit}
        </Table.Cell>
        <Table.Cell>
          <div className="mr-6">{property?.coveragePercent * 100} %</div>
        </Table.Cell>
      </Table.Row>
    </>
  );
};

PropertyTableRow.propTypes = {
  property: PropTypes.shape({
    areaType: PropTypes.string,
    cropZoneId: PropTypes.string,
    cropZoneName: PropTypes.string,
    cropName: PropTypes.string,
    areaValue: PropTypes.number,
    areaUnit: PropTypes.string,
    fieldName: PropTypes.string,
    boundaryArea: PropTypes.number,
    coveragePercent: PropTypes.number,
    reportedArea: PropTypes.number,
    features: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
};

export default PropertyTableRow;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { customDateFormat } from 'helpers/dateFormat';
import { OpenInNewIcon } from 'components/Icons';
import { useHistory } from 'react-router-dom';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import FormattedNumber from '../../../../../../../components/FormattedNumber';
import FieldIcon from '../../../../../components/FieldIcon';

const FieldAppliedProductRow = ({ product }) => {
  const history = useHistory();
  const [isViewButtonHovered, setIsViewButtonHovered] = useState(false);

  return (
    <Table.Row>
      <Table.Cell>
        <div>
          <div>{product?.productName}</div>
          <div className="text-xs  text-neutral-60">
            {product?.productManufacturerName}
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>
        {customDateFormat(product?.dateApplied, 'MMM dd, yyyy')}
      </Table.Cell>
      <Table.Cell>
        <div>
          {product?.appliedCropZones?.map((cropZone, index) =>
            index <= 2 ? (
              <div key={cropZone?.productId} className="flex gap-1 items-start">
                <FieldIcon
                  id="field-card-icon"
                  width={18}
                  height={18}
                  features={[{ geometry: cropZone.geometry, type: 'Feature' }]}
                />
                <div>{cropZone.name}</div>
              </div>
            ) : (
              <div key={cropZone?.productId}>
                + {product?.appliedCropZones?.length - 3} more crop zones
              </div>
            )
          )}
          <div className="text-xs text-neutral-60 text-left">
            {product?.totalAppliedArea ?? 0} ac
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex w-full justify-end">
          <FormattedNumber roundedTo={2}>
            {product?.applicationRate ?? 0}
          </FormattedNumber>{' '}
          {getAbbreviatedUnit(product?.applicationRateUnit)} / ac
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex w-full justify-end">
          <FormattedNumber unit="usCurrency" roundedTo={2}>
            {product?.costPerAcre ?? 0}
          </FormattedNumber>{' '}
          / ac
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex w-full justify-end">
          <FormattedNumber unit="usCurrency" roundedTo={2}>
            {product?.totalCost ?? 0}
          </FormattedNumber>{' '}
          total
        </div>
      </Table.Cell>
      <Table.Cell>
        <button
          type="button"
          className="flex items-center gap-1 px-2 py-2 rounded cursor-pointer hover:bg-blue-10 hover:text-blue-90"
          onClick={() => history.push(`/app/tasks/${product?.taskId}`)}
          onMouseEnter={event => {
            event.stopPropagation();
            setIsViewButtonHovered(true);
          }}
          onMouseLeave={() => setIsViewButtonHovered(false)}
        >
          <OpenInNewIcon fill={isViewButtonHovered ? '#0092E4' : '#14151C'} />{' '}
          View
        </button>
      </Table.Cell>
    </Table.Row>
  );
};

FieldAppliedProductRow.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    totalAppliedArea: PropTypes.number,
    productName: PropTypes.string,
    productManufacturerName: PropTypes.string,
    dateApplied: PropTypes.string,
    appliedCropZones: PropTypes.string,
    applicationRate: PropTypes.string,
    applicationRateUnit: PropTypes.string,
    costPerAcre: PropTypes.string,
    totalCost: PropTypes.string,
    taskId: PropTypes.string
  }).isRequired
};

export default FieldAppliedProductRow;

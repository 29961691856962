import React from 'react';
import PropTypes from 'prop-types';
import PortalModal from '../PortalModal';

const DeleteModal = ({ open, itemType, onCancel, onDelete, type }) => {
  const result = itemType === 'Location?';
  const deleteTitle = `Delete ${itemType}${itemType === 'Load' ? '?' : ''}`;
  const deleteMessage = result
    ? 'Are you sure you want to delete this location'
    : `Are you sure you want to delete this ${itemType.toLowerCase()}? All of the data
  associated with this ${itemType.toLowerCase()} will be lost.`;

  return (
    <PortalModal
      open={open}
      title={deleteTitle}
      close={onCancel}
      cancelLabel="Cancel"
      confirmLabel="Delete"
      onCancel={onCancel}
      type={type}
      onConfirm={onDelete}
    >
      <div className="pr-6">{deleteMessage}</div>
    </PortalModal>
  );
};

DeleteModal.defaultProps = {
  open: false,
  itemType: 'Item',
  type: 'confirmation'
};

DeleteModal.propTypes = {
  open: PropTypes.bool,
  itemType: PropTypes.oneOf([
    'Person',
    'Company',
    'Property',
    'Equipment',
    'Item',
    'Shape',
    'Member',
    'Plan',
    'Recommendation',
    'Task',
    'Load'
  ]), // more can be added here as needed
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  type: PropTypes.string
};

export default DeleteModal;

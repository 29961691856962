import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ArrowBackIosRounded } from '@material-ui/icons';
import { Button } from '@agconnections/grow-ui';
import { Context } from 'components/Store';

const Title = ({ title, showGoBack, className }) => {
  const [{ isMassAssignerActive }, dispatch] = useContext(Context);

  const backHome = () => {
    dispatch({
      type: 'IS_FARM_EDITED',
      payload: true
    });
  };

  return isMassAssignerActive ? null : (
    <div className="flex flex-wrap justify-start ml-1">
      {showGoBack && (
        <Button
          style={{ marginRight: '0.5rem' }}
          type="text"
          icon={<ArrowBackIosRounded />}
          onClick={backHome}
        />
      )}
      <h4 className={`text-3xl font-semibold leading-none ${className}`}>
        {title}
      </h4>
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  showGoBack: PropTypes.bool.isRequired,
  className: PropTypes.string
};

Title.defaultProps = {
  className: ''
};

export default Title;

import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapContext, GeoJSONLayer } from 'react-mapbox-gl';
import { circle, featureCollection, feature } from '@turf/turf';
import { negate, noop, lowerCase, isNil } from 'lodash';
import axios from 'axios';

import { useSharedState } from '../MapEventContext';
import LandContext from '../../PropertyMapContext';
import { MAP_HISTORY_FEATURE_TYPES } from '../../../helpers/constants';
import {
  getEventClientCoordinates,
  getTargetMapFeature
} from '../../../helpers/mapEventHelpers';
import ConfirmModal from './ConfirmModal';

const MIN_ZOOM = 12.25;
const FETCH_RADIUS = 1;

export const GRIS_TYPES = {
  CLU: {
    providerName: 'Common Land Unit (CLU)',
    cropSeason: 'Summer',
    cropYear: 2008
  },
  SATELLITE: {
    providerName: 'Cropio'
  }
};

const matchingFeaturePredicate = matchItem => item =>
  matchItem.properties.grisId === item.properties.grisId;

// create a circle at the current map center
const getSearchPolygon = map => {
  const { lng, lat } = map.getCenter();
  return [`${lng}-${lat}`, circle([lng, lat], FETCH_RADIUS)];
};

const activeFetches = {};

const fetchBoundaries = ([centerCoordinates, polygon], grisTypeOptions) => {
  if (activeFetches[centerCoordinates]) {
    return Promise.resolve({});
  }
  const inputGeometry = {
    type: 'MultiPolygon',
    coordinates: [polygon.geometry.coordinates]
  };
  const promise = axios.post(
    'https://gris.syngentadigitalapps.com/fbm/v1/boundaries?authkey=6c66fce4-f0b0-4f63-9eb5-e71eceaa93dd',
    {
      ...grisTypeOptions,
      inputGeometry
    }
  );
  activeFetches[centerCoordinates] = promise;
  promise.then(() => {
    activeFetches[centerCoordinates] = null;
  });
  return promise;
};

const getItemId = item =>
  isNil(item.id)
    ? `${item.xMin}-${item.yMin}-${item.xMax}-${item.yMax}`
    : item.id;

// concat the newly fetched features to the current collection if they aren't already there,
// excluding the specified features that have already been added to the property
const parseFetchedFeatures = (
  currentCollection,
  excludedFeatures,
  newFeatures
) => {
  const existingIds = currentCollection.features.map(
    item => item.properties.grisId
  );
  const excludedIds = excludedFeatures.map(item => item.id).concat(existingIds);

  return currentCollection.features.concat(
    newFeatures
      .filter(item => !excludedIds.includes(getItemId(item)))
      .map(item =>
        feature(item.boundaryData, {
          grisId: getItemId(item),
          crops: item.crops?.map(crop => lowerCase(crop.description)).join(', ')
        })
      )
  );
};

const GrisLayer = ({ setZoom, geoJSONCollection, type, onClose }) => {
  const [excludedFeatures] = useState(geoJSONCollection?.features ?? []);
  const [availableFeatureCollection, setAvailableFeatureCollection] = useState(
    featureCollection([])
  );
  const [selectedFeatureCollection, setSelectedFeatureCollection] = useState(
    featureCollection([])
  );
  const [modalOpen, setModalOpen] = useState(false);
  const { addNewMapFeatures } = useContext(LandContext);
  const map = useContext(MapContext);
  const [, setState] = useSharedState();

  useEffect(() => {
    if (map) {
      const zoom = map.getZoom();
      if (zoom < MIN_ZOOM) {
        setZoom([MIN_ZOOM]);
      }
    }
  }, [map, setZoom]);

  useEffect(() => {
    let mounted = true;
    const initFetch = () => {
      if (mounted && map) {
        const searchPolygon = getSearchPolygon(map);
        fetchBoundaries(searchPolygon, type)
          .then(results => {
            if (results.data?.data) {
              setAvailableFeatureCollection(previousCollection => {
                const availableFeatures = parseFetchedFeatures(
                  previousCollection,
                  excludedFeatures,
                  results.data.data
                );
                return featureCollection(availableFeatures);
              });
            }
          })
          .catch(() => {}); // console.log('GRIS ERROR!', err));
      }
    };
    if (map) {
      map.on('moveend', initFetch);
      initFetch();
    }

    return () => {
      mounted = false;
      if (map) {
        map.off('moveend', initFetch);
      }
    };
  }, [map, excludedFeatures, type]);

  useEffect(() => {}, []);

  const handleSelect = event => {
    const selectedFeature = getTargetMapFeature(map, event.lngLat);
    const { features } = selectedFeatureCollection;
    const newCollection = featureCollection([]);
    if (features.find(matchingFeaturePredicate(selectedFeature))) {
      newCollection.features = features.filter(
        negate(matchingFeaturePredicate(selectedFeature))
      );
    } else {
      newCollection.features = features.concat(selectedFeature);
    }

    setSelectedFeatureCollection(newCollection);
    setModalOpen(newCollection.features.length > 0);
  };

  const handleMove = event => {
    const hoverFeature = getTargetMapFeature(map, event.lngLat);
    setState(previousState => {
      if (
        previousState?.feature?.properties?.grisId ===
        hoverFeature.properties.grisId
      ) {
        return previousState;
      }
      const coordinates = getEventClientCoordinates(event.originalEvent);
      return {
        ...previousState,
        coordinates,
        feature: hoverFeature
      };
    });
  };

  const handleLeave = () => {
    setState(previousState => ({
      ...previousState,
      coordinates: null,
      feature: null
    }));
  };

  const handleCreateConfirm = () => {
    handleLeave();
    setModalOpen(false);
    const mapHistoryFeatures = selectedFeatureCollection.features.map(feat => {
      const result = feature(feat.geometry, {
        internalId: feat.properties.grisId
      });
      result.id = feat.properties.grisId;
      return { feature: result };
    });
    addNewMapFeatures(MAP_HISTORY_FEATURE_TYPES.ADD, mapHistoryFeatures);
    onClose();
  };

  const handleCreateCancel = () => {
    setModalOpen(false);
  };

  return (
    <>
      <ConfirmModal
        onConfirm={handleCreateConfirm}
        onCancel={handleCreateCancel}
        open={modalOpen}
      />
      <GeoJSONLayer
        data={availableFeatureCollection}
        fillPaint={{
          'fill-color': 'rgba(0, 113, 205, 0.7)',
          'fill-outline-color': 'rgba(130, 207, 255, 1)',
          'fill-antialias': true
        }}
        fillOnClick={handleSelect}
        fillOnMouseMove={handleMove}
        fillOnMouseLeave={handleLeave}
      />
      <GeoJSONLayer
        data={selectedFeatureCollection}
        fillPaint={{
          'fill-color': 'rgba(25, 160, 75, 0.7)',
          'fill-outline-color': 'rgba(195, 234, 209, 1)',
          'fill-antialias': true
        }}
      />
    </>
  );
};

GrisLayer.propTypes = {
  setZoom: PropTypes.func,
  geoJSONCollection: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.object)
  }),
  type: PropTypes.shape({
    providerId: PropTypes.string
  }),
  onClose: PropTypes.func.isRequired
};

GrisLayer.defaultProps = {
  setZoom: noop,
  geoJSONCollection: null,
  type: {}
};

export default GrisLayer;

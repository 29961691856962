import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback
} from 'react';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';

import useCropSeasons from 'hooks/useCropSeasons';

import { Context } from 'components/Store';
import { CROP_SEASONS } from 'reducers/reducer';
import useCropsWithLoads from './hooks/useCropsWithLoads';
import SummaryHeader from './components/SummaryHeader';
import TotalYield from './components/TotalYield';
import GrossRevenue from './components/GrossRevenue';
import SaleLocations from './components/SaleLocations';
import ConnectedStorageLocationsWidget from './components/ConnectedStorageLocationsWidget';
import EmptyView from '../components/EmptyView';

const CommoditySummary = () => {
  const [{ isSidebarOpen }, dispatch] = useContext(Context);

  const { cropSeasons } = useCropSeasons();
  const selectedCropSeasons = JSON.parse(
    localStorage.getItem('selectedCropSeasons')
  );
  const firstSeasonId = selectedCropSeasons?.[0];

  useEffect(() => {
    // AC: If multiple seasons are selected when they enter the flow,
    // we should update the selection to whichever season appears first in their list.
    if (selectedCropSeasons?.length > 1 && cropSeasons !== undefined) {
      const activeSeasons = cropSeasons.filter(season =>
        selectedCropSeasons.includes(season.id)
      );
      const firstActiveSeasonInTheListId = activeSeasons?.[0]?.id;
      localStorage.setItem(
        'selectedCropSeasons',
        JSON.stringify([firstActiveSeasonInTheListId])
      );
      const seasonsWithOnlyOneActive = cropSeasons.map(season => {
        if (season.id === firstActiveSeasonInTheListId) {
          return { ...season, active: true };
        }
        return { ...season, active: false };
      });
      dispatch({
        type: CROP_SEASONS,
        payload: seasonsWithOnlyOneActive
      });
    }
  }, [selectedCropSeasons, firstSeasonId, cropSeasons, dispatch]);

  const selectedSeason = useMemo(() => {
    const firstSeason = cropSeasons?.find(
      cropSeason => cropSeason?.id === firstSeasonId
    );
    return firstSeason || cropSeasons?.[0];
  }, [cropSeasons, firstSeasonId]);

  const { crops, isLoading } = useCropsWithLoads(selectedSeason?.id);

  const [selectedCropId, setSelectedCropId] = useState();
  const setCropId = useCallback(cropId => setSelectedCropId(cropId), []);
  const selectedCropName = useMemo(
    () =>
      crops?.find(crop => {
        return crop.id === selectedCropId;
      })?.name,
    [selectedCropId, crops]
  );
  const [selectedUOM, setSelectedUOM] = useState();
  const setUOM = useCallback(uom => setSelectedUOM(uom), []);

  return (
    <>
      <Breadcrumb hideCropSeasonDropdown={false}>
        <Breadcrumb.Item title="Yield" value="Commodity Summary" isLast />
      </Breadcrumb>
      <div className="h-full xl:max-h-full flex flex-col">
        {crops?.length ? (
          <>
            <SummaryHeader
              selectedUOM={selectedUOM}
              setUOM={setUOM}
              selectedCropId={selectedCropId}
              crops={crops}
              setCropId={setCropId}
              selectedCropName={selectedCropName}
              isLoading={isLoading}
            />
            <div
              className={`flex flex-col gap-4 ${
                isSidebarOpen ? 'p-4' : 'px-14 py-4'
              }`}
            >
              <div className="flex flex-col gap-4 xl:flex-row">
                <GrossRevenue
                  selectedUOM={selectedUOM}
                  className="min-w-360px w-2/5 h-195px"
                  selectedCropName={selectedCropName}
                  cropSeasonId={selectedSeason?.id}
                  cropId={selectedCropId}
                />
                <TotalYield
                  className="min-w-592px w-3/5 h-195px"
                  selectedUOM={selectedUOM}
                  selectedCropName={selectedCropName}
                  cropSeasonId={selectedSeason?.id}
                  cropId={selectedCropId}
                />
              </div>
              <div className="flex flex-col gap-4 xl:flex-row">
                <ConnectedStorageLocationsWidget
                  className="min-w-372px w-1/3 h-330px"
                  cropId={selectedCropId}
                  cropName={selectedCropName}
                  seasonId={selectedSeason?.id}
                  unit={selectedUOM}
                />
                <SaleLocations
                  cropSeasonId={firstSeasonId}
                  cropId={selectedCropId}
                  className="min-w-580px w-2/3 h-330px"
                  selectedUOM={selectedUOM}
                  selectedCropName={selectedCropName}
                  selectedCropId={selectedCropId}
                />
              </div>
            </div>
          </>
        ) : (
          <div
            data-testid="empty-summary"
            className="my-6 py-20 bg-white rounded-lg shadow-sm-card border-1 border-neutral-30"
          >
            <EmptyView
              hasNoCropsinSelectedSeason={
                selectedSeason?.cropZoneIds?.length === 0
              }
              hasNoLoadsForSelectedCrop={crops?.length === 0}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CommoditySummary;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Spinner } from '@agconnections/grow-ui';
import FormattedNumber from 'components/FormattedNumber';
import usePopulateMapData from 'hooks/usePopulateMapData';
import FieldIcon from 'screens/Property/components/FieldIcon';
import { mappingShape } from 'screens/Property/helpers/propertyDataHelpers';
import useCropZoneData from 'screens/Property/hooks/useCropZoneData';
import useFieldData from 'screens/Property/hooks/useFieldData';
import CropField from 'screens/Plans/components/PlanView/components/CropField';
import { FETCH_TYPES } from 'helpers/constants';

const PropertyTableRow = ({ property, coveragePercent }) => {
  const {
    fieldsAndAreasGeoJSONCollection,
    fieldsLoading,
    reloadData
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.cropSeasonsMap,
    cropSeasonsId: property?.cropSeasonId
  });

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const [propertiesModalOpen, setPropertiesModalOpen] = useState(false);
  const [zoneID, setZoneID] = useState();
  const [fieldID, setField] = useState();
  // eslint-disable-next-line no-unused-vars
  const [crop, setCrop] = useState();
  const { cropZone } = useCropZoneData(zoneID);

  const { field } = useFieldData(fieldID);
  const getFieldIconFeatures = () => {
    if (fieldsAndAreasGeoJSONCollection.features.length > 0) {
      const fieldIconFeatures = [];
      fieldsAndAreasGeoJSONCollection.features.forEach(feature => {
        if (property.cropZoneIdValue === feature.properties.id) {
          fieldIconFeatures.push(mappingShape(feature));
        }
      });
      return fieldIconFeatures;
    }
    return '';
  };
  useEffect(() => {
    setZoneID(property?.cropZoneIdValue);
  }, [property]);

  useEffect(() => {
    if (!field) setField(cropZone?.fieldId);
  }, [field, cropZone]);

  return field && cropZone && !fieldsLoading ? (
    <>
      <Table.Row key={property?.cropZoneIdValue}>
        <Table.Cell>
          <FieldIcon
            id="view-plan-card-icon"
            features={getFieldIconFeatures()}
          />
        </Table.Cell>
        <Table.Cell width="20rem">
          <div className="flex flex-col justify-start leading-5 text-left">
            <span className="font-bold text-neutral-1000">{field?.name}</span>
            <span style={{ color: '#707374' }}>{cropZone?.name}</span>
          </div>
        </Table.Cell>
        <Table.Cell>
          <CropField cropId={cropZone.cropId} setCrop={setCrop} />
        </Table.Cell>
        <Table.Cell>
          <FormattedNumber roundedTo="2">{property?.areaValue}</FormattedNumber>
          {' ac'}
        </Table.Cell>
        <Table.Cell width="20rem">
          <div className="mr-6">{coveragePercent * 100 || ''} %</div>
        </Table.Cell>
      </Table.Row>
    </>
  ) : (
    <Spinner />
  );
};

PropertyTableRow.propTypes = {
  property: PropTypes.shape({
    cropSeasonId: PropTypes.string,
    cropZoneIdValue: PropTypes.string,
    cropZoneName: PropTypes.string,
    cropName: PropTypes.string,
    areaValue: PropTypes.number,
    areaUnit: PropTypes.string,
    fieldName: PropTypes.string,
    boundaryArea: PropTypes.number,
    coveragePercent: PropTypes.number,
    reportedArea: PropTypes.number
  }).isRequired,
  coveragePercent: PropTypes.number
};

PropertyTableRow.defaultProps = {
  coveragePercent: ''
};

export default PropertyTableRow;

import React from 'react';
import PropTypes from 'prop-types';

// import focusIcon from 'assets/icon-only-circle.svg';
// import locationIcon from 'assets/icon-only-circle2.svg';
import ZoomButtons from '../ZoomButtons';
// import CircleButton from '../../../../CircleButton';

const ControlButtons = ({ setZoom }) => {
  return (
    <div className="absolute flex flex-col z-10 right-0 bottom-0">
      {/* <CircleButton
        onClick={() => {}}
        selected
        disabled={false}
        className="mt-2 mb-1"
      >
        <img src={focusIcon} alt="" />
      </CircleButton>
      <CircleButton
        onClick={() => {}}
        selected
        disabled={false}
        className="mt-2 mb-1"
      >
        <img src={locationIcon} alt="" />
      </CircleButton> */}
      <ZoomButtons setZoom={setZoom} />
    </div>
  );
};

ControlButtons.propTypes = {
  setZoom: PropTypes.func.isRequired
};

export default ControlButtons;

import { CHECKSTATE, isChecked } from 'helpers/checkboxHelpers';
import { cropZone as cropZoneApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { filterCropSeason } from 'screens/CropSeasons/helpers/dataHelpers';

const createCropZoneItem = selectedProperties => ({ _id, name }) => ({
  id: _id,
  name,
  $checked: selectedProperties.includes(_id)
    ? CHECKSTATE.checked
    : CHECKSTATE.unchecked
});

const createRegionItem = ({ _id, name, areas = [] }, selectedProperties) => ({
  id: _id,
  name,
  $checked: selectedProperties.includes(_id)
    ? CHECKSTATE.checked
    : CHECKSTATE.unchecked,
  $items: areas.map(createCropZoneItem(selectedProperties))
});

const createPropertyItem = (field, selectedProperties) => ({
  id: field.farm?._id || '_defaultProp',
  name: field.farm?.tagValue || 'Default Property',
  $items: [createRegionItem(field, selectedProperties)]
});

export const translateFieldsToProperties = (
  fields = [],
  selectedProperties = []
) =>
  fields.reduce((props, field) => {
    const propIndex = props.findIndex(
      prop => prop.name === field.farm?.tagValue
    );
    if (propIndex === -1) {
      const newProp = createPropertyItem(field, selectedProperties);
      props.push(newProp);
      return props;
    }
    const farmProp = props[propIndex];
    farmProp.$items.push(createRegionItem(field, selectedProperties));
    return props;
  }, []);

const updateSelectedPropertyItems = (items, selectedProperties = []) =>
  items
    ? items.map(item => ({
        ...item,
        $checked: selectedProperties.includes(item.id)
          ? CHECKSTATE.checked
          : CHECKSTATE.unchecked,
        $items: updateSelectedPropertyItems(item.$items, selectedProperties)
      }))
    : items;

export const updateSelectedProperties = (properties, newSelections) =>
  updateSelectedPropertyItems(properties, newSelections);

// parse changes to selected properties coming from the map component
export const updateSelectedPropertiesFromMap = (
  properties,
  collection,
  selectedFeatureIds
) => {
  const selected = collection.features
    .filter(feature => selectedFeatureIds.includes(feature?.id))
    .map(feature => feature?.properties?.parentId);
  return updateSelectedProperties(properties, selected);
};

// parse changes to selected properties coming from the form component
export const updateSelectedPropertiesFromForm = (properties, newSelections) => {
  const selected = Object.entries(newSelections)
    .filter(([, value]) => value === 'checked')
    .map(([key]) => key);
  return updateSelectedProperties(properties, selected);
};

// create a flat array of property items with key/value fields
export const flattenProperties = properties => {
  properties.flatMap(({ id, name, $checked, $items }) => {
    const resultItem = { key: id, value: name, checked: isChecked($checked) };
    if ($items && $items.length > 0) {
      return flattenProperties($items).concat(resultItem);
    }
    return resultItem;
  });
};

// create a flat array of property items with key/value fields
export const flattenAndDepthFilterProperties = (
  properties,
  level,
  $depth = 0
) =>
  properties.reduce((acc, { value, label, children }) => {
    const resultItem = { key: value, value: label };
    const result = $depth >= level ? [resultItem] : [];
    if (children)
      return [
        ...acc,
        ...result,
        ...flattenAndDepthFilterProperties(children, level, $depth + 1)
      ];
    return [...acc, ...result];
  }, []);

// get a flat array of all property items that are selected
const extractSelectedPropertyIds = properties => {
  const flatProperties = flattenProperties(properties);
  return flatProperties.filter(({ checked }) => checked).map(({ key }) => key);
};

// is this feature one of the selected property ids
export const isGeoJsonFeatureSelected = selectedPropertyIds => feature =>
  selectedPropertyIds.includes(feature.properties?.parentId);

// get all of the feature id's from this feature collection marked as checked in the properties structure
export const getSelectedGeoJsonFeatureIds = (collection, properties) => {
  const ids = extractSelectedPropertyIds(properties);
  return collection.features
    .filter(isGeoJsonFeatureSelected(ids))
    .map(feature => feature.id);
};

export const getCropzonesCropArea = cropzones => {
  const allPromises = cropzones.map(zone => {
    const { promise } = cropZoneApi.fetch(zone);
    return promise;
  });
  return Promise.all(allPromises)
    .catch(catchCancel)
    .then(results =>
      results.flatMap(({ data }) => {
        return {
          cropId: data.cropId,
          reportedArea: data.reportedArea,
          boundaryArea: data.boundaryArea
        };
      })
    );
};

export const traverseFields = (selectedCropSeason, cropZones = []) => {
  if (selectedCropSeason) {
    let fieldCount = 0;
    cropZones.forEach(zone => {
      fieldCount += filterCropSeason(selectedCropSeason, zone.cropSeasons);
    });
    return fieldCount;
  }
  if (
    (selectedCropSeason === null || selectedCropSeason === undefined) &&
    cropZones.length > 0
  ) {
    return 1;
  }
  return 0;
};

export const traverseFarms = (selectedCropSeason, fields = []) => {
  if (selectedCropSeason) {
    let farmCount = 0;
    fields.forEach(field => {
      farmCount += traverseFields(selectedCropSeason, field.cropzones);
    });
    return farmCount;
  }
  if (selectedCropSeason === null || selectedCropSeason === undefined) {
    return 1;
  }
  return 0;
};

export const selectedCropzones = (mappedData, selectedCropzoneData) => {
  const newSelected = [...selectedCropzoneData];
  if (mappedData?.length > 0) {
    mappedData.forEach(property => {
      if (property?.children?.length > 0 && property?.disabled === false) {
        property.children.forEach(field => {
          if (field?.children?.length > 0 && field?.disabled === false) {
            field.children.forEach(cz => {
              if (cz?.disabled === false) {
                newSelected.push(cz.value);
              }
            });
          }
        });
      }
    });
  }
  return newSelected;
};

export const getFarmIds = (fft, checkedIds = []) => {
  const flattenTree = array => {
    const thing = array.flatMap(item => {
      if (item.children?.length > 0) {
        return flattenTree(item.children);
      }
      return item;
    });
    return thing;
  };

  const farmIds = [];
  checkedIds.map(checkedId => {
    const allCropzones = flattenTree(fft);
    return allCropzones.find(cz => {
      if (cz.value === checkedId && !farmIds.includes(cz.farmId)) {
        farmIds.push(cz.farmId);
      }
      return false;
    });
  });
  return farmIds;
};

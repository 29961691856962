import { fieldBodyGenerator } from 'components/PDF/helpers/tableGenerator';
import { convertNumberToDesiredFormat } from 'helpers/transformHelpers';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';

const PRODUCT_WIDTH = 18;
const REG_WIDTH = 11;
const PEST_WIDTH = 11;
const REI_WIDTH = 6;
const PHI_WIDTH = 6;
const APP_METHOD_WIDTH = 12;
const APP_AREA_WIDTH = 12;
const RATE_WIDTH = 10;
const TOTAL_WIDTH = 14;

const PRODUCT_HEADER_ITEMS = {
  english: [
    { label: 'Name/Active', width: PRODUCT_WIDTH },
    { label: 'Reg #', width: REG_WIDTH },
    { label: 'Pest', width: PEST_WIDTH },
    { label: 'REI', width: REI_WIDTH },
    { label: 'PHI', width: PHI_WIDTH },
    { label: 'App Method', width: APP_METHOD_WIDTH },
    { label: 'App Area', width: APP_AREA_WIDTH },
    {
      label: '$RATE$',
      labelRateArea: 'Rate/Area',
      labelRateTank: 'Rate/Tank',
      width: RATE_WIDTH
    },
    { label: 'Total', width: TOTAL_WIDTH }
  ],
  LATAMSpanish: [
    { label: 'Nombre/activo', width: PRODUCT_WIDTH },
    { label: 'N.° de reg.', width: REG_WIDTH },
    { label: 'Peste', width: PEST_WIDTH },
    { label: 'IER', width: REI_WIDTH },
    { label: 'PHI', width: PHI_WIDTH },
    { label: 'Método de aplicación', width: APP_METHOD_WIDTH },
    { label: 'Área de aplicación', width: RATE_WIDTH },
    {
      label: '$RATE$',
      labelRateArea: 'Tasa aplic./cult.',
      labelRateTank: 'Tasa/tanque',
      width: RATE_WIDTH
    },
    { label: 'Total', width: TOTAL_WIDTH }
  ]
};

export function getProductHeaderItems({ language, applicationStrategy }) {
  const applicationTypeLabel = (() => {
    if (applicationStrategy === 'ByRatePerTank') {
      return 'labelRateTank';
    }
    return 'labelRateArea';
  })();

  const result = PRODUCT_HEADER_ITEMS[language].map(entry =>
    entry.label === '$RATE$'
      ? {
          ...entry,
          label: entry[applicationTypeLabel]
        }
      : entry
  );

  return result;
}

const FIELD_NAMES_WIDTH = 18;
const LAT_LONG_WIDTH = 19;
const FIELD_AREA_WIDTH = 9;

export const FIELDS_HEADER_ITEMS = {
  english: [
    { label: 'Farm', width: FIELD_NAMES_WIDTH },
    { label: 'Field', width: FIELD_NAMES_WIDTH },
    { label: 'Crop Zone', width: FIELD_NAMES_WIDTH },
    { label: 'Crop', width: FIELD_NAMES_WIDTH },
    { label: 'Center Lat/Long', width: LAT_LONG_WIDTH },
    { label: 'App Area', width: FIELD_AREA_WIDTH }
  ],
  LATAMSpanish: [
    { label: 'Granja', width: FIELD_NAMES_WIDTH },
    { label: 'Campos', width: FIELD_NAMES_WIDTH },
    { label: 'Zona de Cultivo', width: FIELD_NAMES_WIDTH },
    { label: 'Cultivo', width: FIELD_NAMES_WIDTH },
    { label: 'Lat./long. del centro', width: LAT_LONG_WIDTH },
    { label: 'Área de aplicación', width: FIELD_AREA_WIDTH }
  ]
};

export const mapWorkerProtectionToTable = products =>
  products.map(product => product.workerProtection);

export const mapProductsToTable = ({
  products,
  applicationStrategy,
  cropNames
}) =>
  products.map(product => {
    const activeIngredients = product.activeIngredients.map(
      activeIngredient => ({
        label: activeIngredient.name,
        styles: {
          fontSize: 8,
          fontFamily: 'Noto Sans',
          fontWeight: 'light',
          fontStyle: 'italic',
          marginTop: 3
        }
      })
    );

    let reiText = '-';
    let phiText = '-';

    const uniqueCropNames = [...new Set(cropNames?.map(corp => corp.cropName))];

    if (product.reiphisData?.length) {
      const matchingData = uniqueCropNames.find(cropName => {
        return product.reiphisData.some(data =>
          data.cropName?.toLowerCase().includes(cropName.toLowerCase())
        );
      });

      if (matchingData) {
        const res = product.reiphisData.find(data =>
          data.cropName?.toLowerCase().includes(matchingData.toLowerCase())
        );
        reiText = `${res?.rei}`;
        phiText = `${res?.phi}`;
      } else {
        const { phi, rei } = product.reiphisData[0];
        reiText = `${rei}`;
        phiText = `${phi}`;
      }
    }

    const productName = fieldBodyGenerator(
      product.productName,
      {
        width: `${PRODUCT_WIDTH}%`,
        marginBottom: 3
      },
      activeIngredients
    );
    const reg = fieldBodyGenerator(product.registrationnumber, {
      width: `${REG_WIDTH}%`
    });
    const pest = fieldBodyGenerator(product.targetPest?.name || '-', {
      width: `${PEST_WIDTH}%`
    });
    const REI = fieldBodyGenerator(reiText, {
      width: `${REI_WIDTH}%`
    });
    const PHI = fieldBodyGenerator(phiText, {
      width: `${PHI_WIDTH}%`
    });
    const appMethod = fieldBodyGenerator(product.applicationMethod || '-', {
      width: `${APP_METHOD_WIDTH}%`
    });
    const appArea = fieldBodyGenerator(
      `${convertNumberToDesiredFormat(
        product.appliedAreaValue,
        'fixed'
      )} ${getAbbreviatedUnit(product.appliedAreaUnit)}`,
      {
        width: `${APP_AREA_WIDTH}%`
      }
    );
    const productRateField = {
      ByRatePerTank: 'ratePerTankValue',
      ByRatePerArea: 'ratePerAreaValue',
      ByTotalProduct: 'ratePerAreaValue'
    }[applicationStrategy];
    const productUnitField = {
      ByRatePerTank: 'ratePerTankUnit',
      ByRatePerArea: 'ratePerAreaUnit',
      ByTotalProduct: 'ratePerAreaUnit'
    }[applicationStrategy];
    const rate = fieldBodyGenerator(
      `${convertNumberToDesiredFormat(
        product[productRateField],
        'fixed'
      )} ${getAbbreviatedUnit(product[productUnitField])}`,
      {
        width: `${RATE_WIDTH}%`
      }
    );
    const total = fieldBodyGenerator(
      `${convertNumberToDesiredFormat(
        product.totalProductValue,
        'fixed'
      )} ${getAbbreviatedUnit(product.totalProductUnit)}`,
      {
        width: `${TOTAL_WIDTH}%`
      }
    );
    return [productName, reg, pest, REI, PHI, appMethod, appArea, rate, total];
  });

export const fieldsToTable = fields =>
  fields.map(field => {
    const farmName = fieldBodyGenerator(field.farmName, {
      width: `${FIELD_NAMES_WIDTH}%`
    });
    const fieldName = fieldBodyGenerator(field.fieldName, {
      width: `${FIELD_NAMES_WIDTH}%`
    });
    const cropZoneName = fieldBodyGenerator(field.cropZoneName, {
      width: `${FIELD_NAMES_WIDTH}%`
    });
    const cropName = fieldBodyGenerator(field.cropName, {
      width: `${FIELD_NAMES_WIDTH}%`
    });
    const latlong = fieldBodyGenerator(
      `${field.latlong[1]}, ${field.latlong[0]}`,
      {
        width: `${LAT_LONG_WIDTH}%`
      }
    );
    const area = fieldBodyGenerator(
      convertNumberToDesiredFormat(field.areaValue, 'fixed'),
      {
        width: `${FIELD_AREA_WIDTH}%`
      }
    );

    return [farmName, fieldName, cropZoneName, cropName, latlong, area];
  });

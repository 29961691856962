import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useQuery from 'helpers/useQuery';

const useIntegrationsSubmission = () => {
  const query = useQuery();
  const history = useHistory();

  const submissionId = query.get('submission');
  const vendor = query.get('vendor');

  const redirectToSubmission = useCallback(() => {
    history.goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isComingFromIntegrations: submissionId && vendor,
    redirectToSubmission
  };
};

export default useIntegrationsSubmission;

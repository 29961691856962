import React from 'react';
import BaseIntegration from 'screens/Integrations/components/BaseIntegration';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import StepSelector, { PROPERTIES_SELECTION_STEP } from '../StepSelector';

const progressBarSteps = [
  {
    caption: 'Sign in'
  },
  {
    caption: 'Select Organization'
  },
  {
    caption: 'Select Crop Seasons'
  },
  {
    caption: 'Select Properties'
  },
  {
    caption: 'Match Crop Zones'
  },
  {
    caption: 'Review and Submit'
  }
];

const BaseSimplotIntegration = () => {
  return (
    <BaseIntegration
      integrationType={INTEGRATIONS.simplot}
      progressBarSteps={progressBarSteps}
      selectPropertiesStep={PROPERTIES_SELECTION_STEP}
      StepSelector={StepSelector}
    />
  );
};

export default BaseSimplotIntegration;

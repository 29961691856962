/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import _ from 'lodash';

import { FIELDS_TO_MASSIVE_ASSIGN } from 'reducers/reducer';

const useMassAssignerTools = (properties, fieldsToMassiveAssign, dispatch) => {
  const [selectedAllProperties, setSelectedAllProperties] = useState(false);
  const [localProperties, setLocalProperties] = useState(
    _.cloneDeep(properties)
  );
  const [totalFields, setTotalFields] = useState(0);
  useEffect(() => {
    const countFields = localProperties.reduce((prev, curr) => {
      prev += curr.fields.length;

      return prev;
    }, 0);
    setTotalFields(countFields);
  }, [localProperties]);

  useEffect(() => {
    if (fieldsToMassiveAssign.length > 0) {
      setSelectedAllProperties(totalFields === fieldsToMassiveAssign.length);
    }
  }, [fieldsToMassiveAssign, totalFields]);
  const handleSelectedFarm = farm => {
    farm.selected = !farm.selected;
    let filteredFields = [];

    if (farm.selected) {
      const filterFields = _.cloneDeep(farm.fields);
      const fields = filterFields.map(field => ({
        ...field,
        farmName: farm.name,
        farmId: farm.id
      }));

      _.pullAllWith(fieldsToMassiveAssign, fields, _.isEqual);
      filteredFields = [...fieldsToMassiveAssign, ...fields];
    } else {
      filteredFields = fieldsToMassiveAssign.filter(field => {
        const fieldToFind = { ...field };
        delete fieldToFind.farmName;
        delete fieldToFind.farmId;

        return _.findIndex(farm.fields, fieldToFind) < 0;
      });
    }

    dispatch({
      type: FIELDS_TO_MASSIVE_ASSIGN,
      payload: filteredFields
    });
  };

  const handleSelectedField = (farm, field) => {
    const fieldToAdd = _.cloneDeep({
      ...field,
      farmName: farm.name,
      farmId: farm.id
    });
    const isFullSelected = _.findIndex(fieldsToMassiveAssign, fieldToAdd) >= 0;
    const isSelected = fieldsToMassiveAssign.some(
      ({ id }) => fieldToAdd.id === id
    );
    let filteredFields = [];
    if (isFullSelected) {
      filteredFields = fieldsToMassiveAssign.filter(fd => fd.id !== field.id);
      setSelectedAllProperties(false);
    } else if (isSelected) {
      filteredFields = fieldsToMassiveAssign.map(fd => {
        if (fd.id !== field.id) {
          return fd;
        }
        return fieldToAdd;
      });
    } else {
      filteredFields = [...fieldsToMassiveAssign, fieldToAdd];
    }
    const areAllSelected = farm.fields.every(
      fd => _.findIndex(filteredFields, fd) >= 0
    );

    farm.selected = areAllSelected;

    dispatch({
      type: FIELDS_TO_MASSIVE_ASSIGN,
      payload: filteredFields
    });
  };

  const isCropzoneSelected = cropzone =>
    fieldsToMassiveAssign.some(field =>
      field.cropzones.some(cz => cz.id === cropzone.id)
    );

  const handleSelectedCropzone = (farm, field, cropzone) => {
    const isFieldCurrentlyAdded = fieldsToMassiveAssign.some(
      fd => fd.id === field.id
    );
    const isCropZoneSelected = fieldsToMassiveAssign.some(
      fd => _.findIndex(fd.cropzones, cropzone) >= 0
    );

    let filteredFields = [];
    if (isCropZoneSelected) {
      filteredFields = fieldsToMassiveAssign.map(fd => {
        if (field.id === fd.id) {
          fd.cropzones = _.pullAllWith(fd.cropzones, [cropzone], _.isEqual);
        }
        return fd;
      });
      filteredFields = filteredFields.filter(
        fd => fd.id !== field.id || fd.cropzones?.length > 0
      );
      setSelectedAllProperties(false);
    } else if (isFieldCurrentlyAdded) {
      filteredFields = fieldsToMassiveAssign.map(fd => {
        if (field.id === fd.id) {
          fd.cropzones.push(cropzone);
        }
        return fd;
      });
    } else {
      const fieldToAdd = {
        ...field,
        farmName: farm.name,
        farmId: farm.id,
        cropzones: [cropzone]
      };
      filteredFields = [...fieldsToMassiveAssign, fieldToAdd];
    }

    const areAllSelected = farm.fields.every(
      fd => _.findIndex(filteredFields, fd) >= 0
    );

    farm.selected = areAllSelected;

    dispatch({
      type: FIELDS_TO_MASSIVE_ASSIGN,
      payload: filteredFields
    });
  };

  const handleSelectAllProperties = () => {
    const newSelectedValue = !selectedAllProperties;

    setSelectedAllProperties(newSelectedValue);

    let filteredFarms = [];

    if (newSelectedValue) {
      setLocalProperties(
        localProperties.map(property => {
          property.selected = true;

          return _.cloneDeep(property);
        })
      );

      const fields = _.flatten(
        localProperties.map(property =>
          property.fields.map(field => ({
            ...field,
            farmName: property.name,
            farmId: property.id
          }))
        )
      );
      filteredFarms = fields;
    } else {
      setLocalProperties(
        localProperties.map(property => {
          property.selected = false;

          return _.cloneDeep(property);
        })
      );

      filteredFarms = [];
    }

    dispatch({
      type: FIELDS_TO_MASSIVE_ASSIGN,
      payload: filteredFarms
    });
  };

  return {
    handleSelectedFarm,
    handleSelectedField,
    isCropzoneSelected,
    handleSelectedCropzone,
    handleSelectAllProperties,
    selectedAllProperties,
    localProperties
  };
};

export default useMassAssignerTools;

import React, { useContext } from 'react';
import { Radio } from '@agconnections/grow-ui';
import { PropTypes } from 'prop-types';
import ClickableDiv from 'components/ClickableDiv';
import FieldIcon from 'screens/Property/components/FieldIcon';
import { CropZoneMatcherContext } from '../../context/CropZoneMatcherProvider';

const SourceFieldListItem = ({
  sourceField,
  selectedSourceField,
  onChange
}) => {
  const { fieldsGeometries } = useContext(CropZoneMatcherContext);

  const isChecked = sourceField.fieldId === selectedSourceField?.fieldId;
  const handleChange = event => {
    if (!event.target.checked) return;
    onChange(sourceField);
  };

  return (
    <ClickableDiv
      className="rounded-xl px-16px py-8px"
      style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
      onClick={() => {
        if (!isChecked) {
          onChange(sourceField);
        }
      }}
    >
      <div className="flex flex-row items-center">
        <Radio checked={isChecked} onChange={handleChange} />
        <div className="w-full">
          <span className="pl-8px inline-block w-full">
            {sourceField.farmName} / {sourceField.fieldName}
          </span>
          <div className="flex flex-row justify-between pl-8px mt-1">
            <div
              className="rounded"
              style={{
                width: '108px',
                height: '48px',
                backgroundColor: '#F3F4F6',
                border: '1px solid #A3A9B9'
              }}
            >
              {fieldsGeometries?.get(sourceField.fieldId) && (
                <FieldIcon
                  id={sourceField.fieldId}
                  features={[
                    {
                      type: 'Feature',
                      geometry: fieldsGeometries?.get(sourceField.fieldId)
                    }
                  ]}
                  height={45}
                  width={105}
                  color="#A3A9B9"
                  className="flex-shrink-0"
                />
              )}
            </div>
            <span className="text-neutral-600 text-sm">
              {sourceField.operations.length} operation
              {sourceField.operations.length === 1 ? '' : 's'}
            </span>
          </div>
        </div>
      </div>
    </ClickableDiv>
  );
};

SourceFieldListItem.propTypes = {
  sourceField: PropTypes.shape().isRequired,
  selectedSourceField: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired
};

export default SourceFieldListItem;

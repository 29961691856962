import * as Yup from 'yup';

// eslint-disable-next-line no-useless-escape
const phoneRegExp = /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4,7})$/g;

export default Yup.object().shape({
  name: Yup.string()
    .trim()
    .required(),
  primaryContactEmail: Yup.string().email('Primary Contact Email is not valid'),
  primaryContactPhoneNumber: Yup.string().matches(
    phoneRegExp,
    'Primary Contact phone number is not valid'
  )
});

import React from 'react';
import PropTypes from 'prop-types';

const SquareButton = ({ buttonClassName, onClick, children, className }) => {
  const buttonClasses = buttonClassName || 'w-6 h-6';
  return (
    <div className={className}>
      <button
        type="button"
        className={`flex justify-center items-center bg-white shadow-lg rounded ${buttonClasses}`}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
};

SquareButton.propTypes = {
  buttonClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

SquareButton.defaultProps = {
  buttonClassName: null,
  className: ''
};

export default SquareButton;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import FormattedNumber from 'components/FormattedNumber';

import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import AddProductRecModal from 'components/ProductSideBar/components/AddProductRecModal';
import { TAB_OPTIONS } from 'utilities/menus';
import EllipseMenu from 'components/EllipseMenu';
import EmptyProductsDisplay from '../EmptyProductsDisplay';

import { APPLICATION_OPTIONS } from '../../../../helpers/applicationOptions';

const applicationOptions = Object.values(APPLICATION_OPTIONS);

const ProductTable = ({
  editProduct,
  removeProduct,
  setCustomProductCreated,
  customProductCreated
}) => {
  // eslint-disable-next-line no-unused-vars
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const closeProductModal = () => {
    setProductModalOpen(false);
    editProduct(selectedProduct);
  };
  const [modalType, setModalType] = useState(APPLICATION_OPTIONS.byRatePerArea);

  const { values, setFieldTouched } = useFormikContext();

  useEffect(() => {
    const determineModalType =
      values?.applicationStrategy === APPLICATION_OPTIONS.byRatePerTank.key
        ? APPLICATION_OPTIONS.byRatePerTank.key
        : APPLICATION_OPTIONS.byRatePerArea.key;

    setModalType(
      applicationOptions.find(option => option.key === determineModalType)
    );
  }, [values.applicationStrategy]);

  const handleContextMenuAction = (action, product, index) => {
    if (action === 'edit') {
      setSelectedProduct({
        ...product,
        name: product.productName,
        manufacturer: product.manufacturerName,
        id: product.productId
      });
      setSelectedIndex(index);
      setProductModalOpen(true);
    }
    if (action === 'remove') {
      setFieldTouched('Removed Product');
      removeProduct(product);
    }
  };

  useEffect(() => {
    if (values.products?.length > 0) {
      const lastIndex = values.products.length - 1;
      const lastProductAdded = values.products[lastIndex];

      if (lastProductAdded.custom && customProductCreated) {
        setSelectedProduct({
          ...lastProductAdded,
          name: lastProductAdded.productName,
          manufacturer: lastProductAdded.manufacturerName,
          id: lastProductAdded.productId
        });
        setSelectedIndex(lastIndex);
        setProductModalOpen(true);
        setCustomProductCreated(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.products, customProductCreated]);

  return (
    <div className="mt-8">
      {selectedProduct && (
        <AddProductRecModal
          open={productModalOpen}
          product={selectedProduct}
          onClose={closeProductModal}
          isEdit
          prodIndex={selectedIndex}
        />
      )}
      <Table>
        <Table.Header>
          <Table.Cell>{`Products (${values.products.length})`}</Table.Cell>
          <Table.Cell>{modalType?.title}</Table.Cell>
          <Table.Cell>Applied Area</Table.Cell>
          <Table.Cell>Applied Area %</Table.Cell>
          <Table.Cell>Total Product</Table.Cell>
          <Table.Cell />
        </Table.Header>
        {values.products?.map((product, index) => {
          return (
            <Table.Row>
              <Table.Cell>
                <div className="text-left">
                  <div className="font-bold">{product.productName}</div>
                  <div className="mt-2 text-neutral-300">
                    {product.manufacturerName}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell>{`${product[modalType.value]} ${getAbbreviatedUnit(
                product[modalType.unit]
              )}`}</Table.Cell>
              <Table.Cell>{`${product.appliedAreaValue} ac`}</Table.Cell>
              <Table.Cell>{`${product.coveragePercent}%`}</Table.Cell>
              <Table.Cell>
                {modalType?.title === 'Rate/Tank' ? (
                  <>
                    <FormattedNumber roundedTo="2">
                      {values?.tankInformation?.tankCount *
                        product?.ratePerTankValue}
                    </FormattedNumber>{' '}
                    {product?.productRatePerTankUnit}
                  </>
                ) : (
                  <>
                    <FormattedNumber roundedTo="2">
                      {product.totalProductValue}
                    </FormattedNumber>{' '}
                    {getAbbreviatedUnit(product.totalProductUnit)}
                  </>
                )}
              </Table.Cell>

              <Table.Cell>
                <div className="pr-2 ">
                  <EllipseMenu
                    onAction={action =>
                      handleContextMenuAction(action, product, index)
                    }
                    options={TAB_OPTIONS}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table>
      {!values.products?.length && <EmptyProductsDisplay />}
    </div>
  );
};

ProductTable.propTypes = {
  editProduct: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
  setCustomProductCreated: PropTypes.func.isRequired,
  customProductCreated: PropTypes.bool.isRequired
};

export default ProductTable;

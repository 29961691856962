import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

const ColumnWithFieldsLeft = ({ labelTitles, LabelInputs, divider }) => (
  <div className="flex flex-col w-126">
    <div className="flex flex-row">
      <Form.Field
        label={
          <div className="flex flex-row">
            {labelTitles.name}
            <div className="text-remove">*</div>
          </div>
        }
        labelFor={LabelInputs.name}
      >
        <GrowUIFormField
          control={Input}
          id={LabelInputs.name}
          name={LabelInputs.name}
        />
      </Form.Field>
      {divider('w-6')}
      <Form.Field label={labelTitles.email} labelFor={LabelInputs.email}>
        <GrowUIFormField
          control={Input}
          id={LabelInputs.email}
          name={LabelInputs.email}
        />
      </Form.Field>
    </div>
    <Form.Field
      label={labelTitles.addressLineOne}
      labelFor={LabelInputs.addressLineOne}
    >
      <GrowUIFormField
        control={Input}
        id={LabelInputs.addressLineOne}
        name={LabelInputs.addressLineOne}
      />
    </Form.Field>
    <Form.Field label={labelTitles.city} labelFor={LabelInputs.city}>
      <GrowUIFormField
        control={Input}
        id={LabelInputs.city}
        name={LabelInputs.city}
      />
    </Form.Field>
  </div>
);

ColumnWithFieldsLeft.propTypes = {
  labelTitles: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    addressLineOne: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired
  }).isRequired,
  LabelInputs: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    addressLineOne: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired
  }).isRequired,
  divider: PropTypes.func.isRequired
};

export default ColumnWithFieldsLeft;

/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';

import Column from './Column';

import createColumns from './helpers/createColumns';
import onDragEnd from './helpers/onDragEnd';

const TaskBoard = ({
  tasks,
  updateTask,
  memberRole,
  memberFinancialAccess,
  updateTaskWeather,
  handleAction
}) => {
  const defaultSortsState = {
    ready: { sort: 'desc' },
    inprogress: { sort: 'desc' },
    complete: { sort: 'desc' }
  };
  const [sorts, setSorts] = useState(defaultSortsState);
  const initialColumns = createColumns(tasks, sorts);

  const [columns, setColumns] = useState(initialColumns);
  const [draggingOverTask, setDraggingOverTask] = useState();

  useEffect(() => {
    setColumns(createColumns(tasks, sorts));
  }, [sorts, tasks]);

  const handleSortChange = title => {
    const currentSort = sorts[title].sort;

    if (!currentSort) {
      // setSort('asc');
      setSorts(previousSorts => {
        const previousSortsCopy = cloneDeep(previousSorts);
        previousSortsCopy[title].sort = 'asc';
        return previousSortsCopy;
      });
    } else if (currentSort === 'asc') {
      // setSort('desc');
      setSorts(previousSorts => {
        const previousSortsCopy = cloneDeep(previousSorts);
        previousSortsCopy[title].sort = 'desc';
        return previousSortsCopy;
      });
    } else {
      // setSort(null);
      setSorts(previousSorts => {
        const previousSortsCopy = cloneDeep(previousSorts);
        previousSortsCopy[title].sort = null;
        return previousSortsCopy;
      });
    }
  };

  return (
    <div className="my-4" data-testid="tasks-baord">
      <DragDropContext
        onDragEnd={result => {
          const selectedTask = tasks.filter(
            task => task.id === draggingOverTask.id
          );
          onDragEnd(
            result,
            columns,
            setColumns,
            draggingOverTask,
            updateTask,
            updateTaskWeather,
            selectedTask[0]
          );

          setDraggingOverTask(null);
        }}
      >
        <div className="flex justify-between">
          {columns.map((column, index) => {
            const mappedTasks = column.tasks.map(taskId =>
              tasks.find(taskObject => taskObject.id === taskId)
            );

            return (
              <Column
                key={column.title}
                column={column}
                tasks={mappedTasks}
                columnIndex={index}
                onAction={handleAction}
                setDraggingOverTask={setDraggingOverTask}
                handleSortChange={handleSortChange}
                sort={sorts[column.title].sort}
                memberRole={memberRole}
                memberFinancialAccess={memberFinancialAccess}
              />
            );
          })}
        </div>
      </DragDropContext>
    </div>
  );
};

TaskBoard.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateTask: PropTypes.func.isRequired,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired,
  updateTaskWeather: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired
};

export default TaskBoard;

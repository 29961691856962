import React from 'react';
import { Table, Button } from '@agconnections/grow-ui';
import FormattedNumber from 'components/FormattedNumber';

const getProductsTable = ({
  products,
  displayFinancials,
  handleProductModal
}) => {
  const handleSort = () => {};
  const sort = {};
  return (
    <>
      <Table>
        <Table.Header>
          <Table.Cell sort={sort} onClick={handleSort}>
            {`Products (${products.length})`}
          </Table.Cell>
          <Table.Cell sort={sort} onClick={handleSort}>
            Rate/Area
          </Table.Cell>
          <Table.Cell sort={sort} onClick={handleSort}>
            Applied Area
          </Table.Cell>
          <Table.Cell sort={sort} onClick={handleSort}>
            Applied Area %
          </Table.Cell>
          <Table.Cell sort={sort} onClick={handleSort}>
            Total Product
          </Table.Cell>
          <Table.Cell sort={sort} onClick={handleSort}>
            Total Cost
          </Table.Cell>
        </Table.Header>
        {products.map((product, index) => {
          return (
            <Table.Row key={product?.id ?? `product-row-${index}`}>
              <Table.Cell>
                <div className="text-left">
                  <div className="font-bold">
                    {product?.productName || 'No Products Selected'}
                  </div>
                  <div className="mt-2 text-neutral-300">
                    {product?.manufacturerName || '--'}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell>
                {product?.productRateValue} {product?.productRateUnit}
              </Table.Cell>
              <Table.Cell>
                {product?.areaValue || '0'}
                {' ac'}
                {/* {product?.areaValue || '0 '} {`${product?.areaUnit}s`} */}
              </Table.Cell>
              <Table.Cell>{product?.coveragePercent * 100}%</Table.Cell>
              <Table.Cell>
                {product?.totalProductValue?.toFixed(2)}{' '}
                {product?.specificCostUnit
                  ? `${product?.specificCostUnit}s`
                  : ''}
              </Table.Cell>
              <Table.Cell>
                {displayFinancials && product?.totalProductCost ? (
                  <FormattedNumber unit="usCurrency" roundedTo={2}>
                    {product?.totalProductCost}
                  </FormattedNumber>
                ) : (
                  ''
                )}
                <div
                  data-testid="product-table-view-button"
                  className="mr-8 absolute right-0 flex-none w-10"
                  data-html2canvas-ignore
                >
                  <Button
                    type="link-default"
                    onClick={() => {
                      handleProductModal(product);
                    }}
                  >
                    View
                  </Button>
                </div>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table>
    </>
  );
};

export default getProductsTable;
